import React, { useRef, useEffect, useState, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'clsx'
import gsap from 'gsap'
import { useSelector } from 'react-redux'
import ResponsiveImage from '../../ResponsiveImage'
import { getCurrentBreakpoint } from '../../../redux/slices/layout'
import ScrollTrigger from 'gsap/ScrollTrigger'
import theme from '../../../style/theme'
import { useScrollReadyListener } from '../../useSmoothScrollbar'

const ASPECT_LANDSCAPE = 'landscape'
const ASPECT_PORTRAIT = 'portrait'
const ASPECT_SQUARE = 'square'

const POSITION_RANDOM = 'random'
const TOP_LEFT = 'top-left'
const TOP_CENTER = 'top-center'
const TOP_RIGHT = 'top-right'
const CENTER_LEFT = 'center-left'
const CENTER_CENTER = 'center-center'
const CENTER_RIGHT = 'center-right'
const BOTTOM_LEFT = 'bottom-left'
const BOTTOM_CENTER = 'bottom-center'
const BOTTOM_RIGHT = 'bottom-right'

const OFFSET_RANGE = 10

const getRandomOffset = () => {
  const offset = Math.floor(Math.random() * OFFSET_RANGE) // this will get a number between 1 and 99;
  return offset * (Math.floor(Math.random() * 2) === 1 ? 1 : -1) // this will add minus sign in 50% of cases
}

const BlockImage = (props) => {
  const {
    photo,
    image_size: imageSize = 'small',
    image_position: imagePosition = POSITION_RANDOM,
    skew = 0,
    zIndex = 1,
    blend = false,
    parentRef,
    isFullWidth
  } = props
  const currentBreakpoint = useSelector(getCurrentBreakpoint)
  const { aspect } = photo
  const getAspectRatio = useCallback(() => {
    if (aspect === 1) {
      return ASPECT_SQUARE
    } else if (aspect > 1) {
      return ASPECT_LANDSCAPE
    } else if (aspect < 1) {
      return ASPECT_PORTRAIT
    }
  }, [aspect])
  const aspectRatio = getAspectRatio()
  const imageRef = useRef()
  const [xPos, setXPos] = useState(0)
  const [yPos, setYPos] = useState(0)
  const [xOffset, setXOffset] = useState(0)
  const [yOffset, setYOffset] = useState(0)

  useEffect(() => {
    // Setting the image position
    if (parentRef.current && imageRef.current) {
      const xMax = parentRef.current.clientWidth - imageRef.current.clientWidth
      const yMax = parentRef.current.clientHeight - imageRef.current.clientHeight
      const xCenter = (parentRef.current.clientWidth / 2) - (imageRef.current.clientWidth / 2)
      const yCenter = (parentRef.current.clientHeight / 2) - (imageRef.current.clientHeight / 2)
      const offsetX = getRandomOffset()
      const offsetY = getRandomOffset()
      let x = 0
      let y = 0
      switch (imagePosition) {
        case POSITION_RANDOM:
          x = Math.random() * xMax
          y = Math.random() * yMax
          break
        case TOP_LEFT:
          x = 0
          y = 0
          break
        case TOP_CENTER:
          x = xCenter
          y = 0
          break
        case TOP_RIGHT:
          x = xMax
          y = 0
          break
        case CENTER_LEFT:
          x = 0
          y = yCenter
          break
        case CENTER_CENTER:
          x = xCenter
          y = yCenter
          break
        case CENTER_RIGHT:
          x = xMax
          y = yCenter
          break
        case BOTTOM_LEFT:
          x = 0
          y = yMax
          break
        case BOTTOM_CENTER:
          x = xCenter
          y = yMax
          break
        case BOTTOM_RIGHT:
          x = xMax
          y = yMax
          break
        default:
          x = Math.random() * xMax
          y = Math.random() * yMax
          break
      }
      setXPos(x)
      setYPos(y)
      setXOffset(offsetX)
      setYOffset(offsetY)
      // Once the image position is set, reveal
      gsap.to(imageRef.current, { opacity: 1, duration: 0.6, ease: 'expo.out' })
    }
  }, [currentBreakpoint, imagePosition, parentRef])

  useScrollReadyListener(useCallback(({ scrollElement }) => {
    // Create parallax effect
    if (imageRef.current) {
      const tl = gsap.timeline()
      const scrollTrigger = ScrollTrigger.create({
        scroller: scrollElement,
        trigger: parentRef.current,
        pin: false,
        start: 'top bottom',
        end: 'bottom top',
        scrub: 1,
        animation: tl
      })
      // Determine rate of change based on zIndex
      const rateOfChange = 40 * zIndex
      tl.to([imageRef.current], { y: `-${rateOfChange}%`, ease: 'none' }, 0)
      return () => {
        tl.kill()
        scrollTrigger.kill()
      }
    }
  }, [parentRef, zIndex]))

  const classes = useStyles({ skew, zIndex, blend, aspect, isFullWidth })
  return (
    <ResponsiveImage
      ref={imageRef}
      className={cn(classes.image, imageSize, aspectRatio)}
      style={{
        top: yPos,
        left: xPos,
        marginLeft: `${xOffset}%`,
        marginBottom: `${yOffset}%`
      }}
      {...photo}
    />
  )
}

const useStyles = createUseStyles({
  image: {
    position: 'absolute',
    opacity: 0,
    zIndex: ({ zIndex }) => zIndex,
    transform: ({ skew }) => `rotate(${skew}deg)`,
    '&.portrait': {
      '&.small': {
        maxWidth: '36%',
        [theme.breakpoints.up('xs')]: {
          maxWidth: ({ isFullWidth }) => isFullWidth ? '18%' : '36%'
        }
      },
      '&.medium': {
        maxWidth: '45%',
        [theme.breakpoints.up('xs')]: {
          maxWidth: ({ isFullWidth }) => isFullWidth ? '22%' : '45%'
        }
      },
      '&.large': {
        maxWidth: '56%',
        [theme.breakpoints.up('xs')]: {
          maxWidth: ({ isFullWidth }) => isFullWidth ? '28%' : '56%'
        }
      }
    },
    '&.landscape': {
      '&.small': {
        maxWidth: '45%',
        [theme.breakpoints.up('xs')]: {
          maxWidth: ({ isFullWidth }) => isFullWidth ? '22%' : '45%'
        }
      },
      '&.medium': {
        maxWidth: '56%',
        [theme.breakpoints.up('xs')]: {
          maxWidth: ({ isFullWidth }) => isFullWidth ? '28%' : '56%'
        }
      },
      '&.large': {
        maxWidth: '80%',
        [theme.breakpoints.up('xs')]: {
          maxWidth: ({ isFullWidth }) => isFullWidth ? '40%' : '80%'
        }
      },
      [theme.breakpoints.up('xs')]: {
        mixBlendMode: ({ blend }) => blend ? 'hard-light' : 'none'
      }
    },
    '& img': {
      objectFit: 'contain',
      fontFamily: '"object-fit: contain;"'
    }
  }
}, { name: 'BlockImage' })

export default BlockImage
