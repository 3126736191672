import React, { forwardRef, useCallback, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'clsx'
import CloseIcon from '../../images/CloseIcon'
import BackIcon from '../../images/BackIcon'
import theme from '../../style/theme'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import ChevronDown from '../../images/ChevronDown'
import composeRefs from '../../helpers/composeRefs'
import useEscKey from '../../hooks/useEscKey'

const DialogToggleButton = forwardRef(({
  className, onClose, onOpen, open, text,
  ariaLabel, overlayIsDark = false, children,
  closeOnOutsideClick = true, chevron = false, back,
  ignoredQuery,
  role
}, ref) => {
  const classes = useStyles({ open, overlayIsDark })

  const toggleMenu = useCallback(() => {
    if (open) {
      onClose()
    } else {
      onOpen()
    }
  }, [open])

  const containerRef = useRef()
  useOnClickOutside(containerRef, useCallback(() => {
    if (closeOnOutsideClick && open) {
      onClose()
    }
  }, [open, closeOnOutsideClick]), open && closeOnOutsideClick, ignoredQuery)

  useEscKey(useCallback(() => {
    if (!open) return
    onClose()
  }, [open, onClose]))

  return (
    <div ref={composeRefs(ref, containerRef)}>
      <button
        className={cn(classes.button, className, { open })}
        onClick={toggleMenu}
        aria-label={ariaLabel}
        role={role}
      >
        {!chevron && !back && <CloseIcon className={cn(classes.icon, { open })} />}
        {!chevron && back && <BackIcon className={cn(classes.icon, { open })} />}
        <span className={classes.buttonText} aria-hidden>{text}</span>
        {chevron && <ChevronDown className={cn(classes.chevronIcon, { open })} />}
      </button>
      {children}
    </div>
  )
})

const useStyles = createUseStyles({
  button: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    pointerEvents: 'all',
    color: ({ overlayIsDark, open }) => !overlayIsDark && open ? theme.colors.text : 'currentColor',
    extend: theme.textStyles.bodySmall,
    '&.open': {
      zIndex: 2
    }
  },
  icon: {
    position: 'absolute',
    top: '50%',
    left: -8,
    transform: 'translateY(-50%) translateX(-100%)',
    display: 'block',
    width: 8,
    marginRight: 8,
    opacity: 0,
    '&.open': {
      opacity: 1
    }
  },
  chevronIcon: {
    position: 'absolute',
    top: '50%',
    right: -8,
    transform: 'translateY(-50%) translateX(100%) rotate(0deg)',
    transformOrigin: 'center center',
    display: 'block',
    width: 8,
    marginLeft: 8,
    transition: 'transform 0.15s ease-in-out',
    '&.open': {
      transform: 'translateY(-50%) translateX(100%) rotate(180deg)'
    }
  }
}, { name: 'DialogToggleButton' })

export default DialogToggleButton
