import React, { useCallback, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import RichText from '../RichText'
import ResponsiveImage from '../ResponsiveImage'
import theme from '../../style/theme'
import useStickyElement from './useStickyElement'
import Section from '../Section'
import { useScrollReadyListener } from '../useSmoothScrollbar'
import useFadeInAnimation from '../../animations/useFadeInAnimation'
import composeRefs from '../../helpers/composeRefs'

const TextWithParallaxImages = ({ className, slice }) => {
  const classes = useStyles()
  const leftImageRef = useRef()
  const rightImageRef = useRef()
  const sectionRef = useRef()
  const contentRef = useRef()

  const {
    title,
    // category,
    copy,
    left_image: leftImage,
    right_image: rightImage
  } = slice

  useScrollReadyListener(useCallback(({ scrollElement }) => {
    if (leftImageRef.current) {
      const tl = gsap.timeline()
      const scrollTrigger = ScrollTrigger.create({
        scroller: scrollElement,
        trigger: sectionRef.current,
        pin: false,
        start: 'top bottom',
        end: 'bottom top',
        scrub: 1,
        animation: tl
      })
      tl.to([leftImageRef.current], { y: '25%', ease: 'none' }, 0)
      tl.to([rightImageRef.current], { y: '-25%', ease: 'none' }, 0)
      return () => {
        tl.kill()
        scrollTrigger.kill()
      }
    }
  }, []))

  useStickyElement(sectionRef, contentRef)
  const [inViewRef] = useFadeInAnimation(useCallback(() => {
    return contentRef.current.children
  }, []))

  return (
    <Section grid ref={sectionRef}>
      <div className={classes.textCol}>
        <div className={classes.textWrapper} ref={composeRefs(contentRef, inViewRef)}>
          {title && <h4>{title}</h4>}
          {copy && <RichText content={copy.text} />}
        </div>
      </div>
      <div className={classes.imageCol}>
        <div className={classes.imageWrapper}>
          <ResponsiveImage {...leftImage} className={classes.leftImage} ref={leftImageRef} />
          <ResponsiveImage {...rightImage} className={classes.rightImage} ref={rightImageRef} />
        </div>
      </div>
    </Section>
  )
}

const useStyles = createUseStyles({
  imageCol: {
    gridRow: 2,
    gridColumn: '1 / span 2',
    [theme.breakpoints.up('xs')]: {
      gridColumn: '2 / span 6',
      gridRow: 1
    }
  },
  textCol: {
    marginBottom: theme.spacing(4),
    gridColumn: '1 / span 2',
    gridRow: 1,
    [theme.breakpoints.up('xs')]: {
      gridColumn: '9 / span 3',
      marginBottom: 0
    }
  },
  textWrapper: {
    '& > *': {
      opacity: 0
    }
  },
  category: {
    textTransform: 'uppercase'
  },
  imageWrapper: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  leftImage: {
    width: '50%',
    marginRight: 8,
    [theme.breakpoints.up('xs')]: {
      marginRight: 16
    }
  },
  rightImage: {
    width: '50%',
    marginTop: '50%',
    marginLeft: 8,
    [theme.breakpoints.up('xs')]: {
      marginTop: '75%',
      marginLeft: 16
    }
  }
}, { name: 'TextWithParallaxImages' })

export default TextWithParallaxImages
