import mapValues from 'lodash/mapValues'
import processArchitecture from './process-architecture.json'
import processDesign from './process-design.json'
import processEngineering from './process-engineering.json'
import processImmersion from './process-immersion.json'

const animations = {
  processArchitecture,
  processDesign,
  processEngineering,
  processImmersion
}

export const aspectRatios = mapValues(animations, ({ w, h }) => w / h)

export default animations
