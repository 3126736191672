import inDOM from 'dom-helpers/canUseDOM'
import { createSlice } from '@reduxjs/toolkit'
import { rehydrated, routeContent } from '../actions'
import { current, keys } from '../../style/breakpoints'
import { ActionType } from 'redux-promise-middleware'
import { pageContentActionCreator as pageAction } from '../slices/content/page'

const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    breakpoint: current(),
    breakpointSet: inDOM,
    headerHeight: 0,
    menuOpen: false,
    overlayOpen: false,
    caseStudiesOpen: false,
    signUpDialogOpen: {
      mobile: false,
      desktop: false
    },
    showreelDialogOpen: false,
    filterDropdownOpen: false,
    showHeaderCta: false,
    inViewSliceColor: {
      color: null,
      transparent: false
    }
  },
  reducers: {
    breakpointChanged: (state, action) => {
      state.breakpoint = action.payload
      state.breakpointSet = true
    },
    openMenu: (state, action) => {
      state.menuOpen = true
      state.overlayOpen = false
    },
    closeMenu: (state, action) => {
      state.menuOpen = false
      state.showreelDialogOpen = false
      state.signUpDialogOpen.mobile = false
    },
    openOverlay: (state, action) => {
      state.overlayOpen = true
    },
    closeOverlay: (state, action) => {
      state.overlayOpen = false
    },
    showHeaderCta: (state, action) => {
      state.showHeaderCta = true
    },
    hideHeaderCta: (state, action) => {
      state.showHeaderCta = false
    },
    setHeaderHeight: (state, action) => {
      state.headerHeight = action.payload
    },
    openSignUpDialog: (state, { payload }) => {
      state.signUpDialogOpen[payload.mobile ? 'mobile' : 'desktop'] = true
    },
    closeSignUpDialog: (state, { payload }) => {
      state.signUpDialogOpen[payload.mobile ? 'mobile' : 'desktop'] = false
    },
    setInViewSliceColor: (state, { payload }) => {
      state.inViewSliceColor = payload
    },
    clearInViewSliceColor: (state, { payload }) => {
      if (payload.id === state.inViewSliceColor.id) {
        state.inViewSliceColor = {}
      }
    },
    openShowreelDialog: (state, { payload }) => {
      state.showreelDialogOpen = true
    },
    closeShowreelDialog: (state, { payload }) => {
      state.showreelDialogOpen = false
    },
    openFilterDropdown: (state, { payload }) => {
      state.filterDropdownOpen = true
    },
    closeFilterDropdown: (state, { payload }) => {
      state.filterDropdownOpen = false
    },
    openCaseStudies: (state, { payload }) => {
      state.caseStudiesOpen = true
    },
    closeCaseStudies: (state, { payload }) => {
      state.caseStudiesOpen = false
    }
  },
  extraReducers: {
    [rehydrated]: (state, action) => {
      state.breakpointSet = true
    },
    [routeContent]: (state, action) => {
      state.menuOpen = false
      state.caseStudiesOpen = false
      state.filterDropdownOpen = false
      state.showreelDialogOpen = false
      state.signUpDialogOpen.mobile = false
      state.signUpDialogOpen.desktop = false
      state.overlayOpen = false
    },
    [`${pageAction}_${ActionType.Fulfilled}`]: (state) => {
      state.inViewSliceColor = {}
    }
  }
})

export const {
  breakpointChanged,
  openOverlay,
  closeOverlay,
  openMenu,
  closeMenu,
  showHeaderCta,
  hideHeaderCta,
  setHeaderHeight,
  openSignUpDialog,
  closeSignUpDialog,
  setInViewSliceColor,
  clearInViewSliceColor,
  openShowreelDialog,
  closeShowreelDialog,
  openFilterDropdown,
  closeFilterDropdown,
  openCaseStudies,
  closeCaseStudies
} = layoutSlice.actions

export const getCurrentBreakpoint = state => state.layout.breakpoint
export const isCurrentBreakpointAtLeast = (state, breakpoint) => (
  keys.indexOf(getCurrentBreakpoint(state)) >= keys.indexOf(breakpoint)
)
export const isBreakpointSet = state => state.layout.breakpointSet
export const isCurrentBreakpointMobile = state => !isCurrentBreakpointAtLeast(state, 'md')
export const isLargeDesktop = state => isCurrentBreakpointAtLeast(state, 'xxl')

export const isMenuOpen = state => state.layout.menuOpen

export const isOverlayOpen = state => state.layout.overlayOpen

export const isSignUpDialogOpen = state => state.layout.signUpDialogOpen

export const isShowreelDialogOpen = state => state.layout.showreelDialogOpen

export const isFilterDropdownOpen = state => state.layout.filterDropdownOpen

export const isCaseStudiesOpen = state => state.layout.caseStudiesOpen

export const getInViewSliceColor = state => state.layout.inViewSliceColor.color
export const getInViewSliceColorTransparency = state => state.layout.inViewSliceColor.transparent
export const getInViewSliceForegroundColor = state => state.layout.inViewSliceColor.foregroundColor

export default layoutSlice.reducer
