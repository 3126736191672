import React, { useEffect, useRef } from 'react'
import { format, utcToZonedTime } from 'date-fns-tz'
import { createUseStyles } from 'react-jss'
import theme from '../style/theme'
import cn from 'clsx'

const getTime = () => {
  const now = new Date()
  const sydneyTimezone = 'Australia/Sydney' // NOTE: See https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
  const sydneyDateTime = utcToZonedTime(now, sydneyTimezone)
  return format(sydneyDateTime, 'HH:mm:ss', { timeZone: sydneyTimezone })
}

const Clock = ({ className }) => {
  const classes = useStyles()
  const timeRef = useRef()
  // We cannot set the time on the server as the page renders will be cached
  useEffect(() => {
    const loop = () => {
      timeRef.current.innerText = `SYD ${getTime()}`
    }
    const id = setInterval(loop, 1000)
    loop()
    return () => clearInterval(id)
  }, [])
  return (
    <span className={cn(className, classes.clock)}>
      <span ref={timeRef}>SYD 00:00:00</span>
    </span>
  )
}

const useStyles = createUseStyles({
  clock: {
    ...theme.textStyles.baseMonoStyles,
    display: 'block',
    lineHeight: 1,
    whiteSpace: 'pre-wrap',
    height: '2em'
  }
}, { name: 'Clock' })

export default Clock
