import React, { useCallback, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'clsx'
import get from 'lodash/get'
import map from 'lodash/map'
import remove from 'lodash/remove'
import compact from 'lodash/compact'

import theme from '../../style/theme'
import Section from '../Section'
import StyledButton from '../StyledButton'
import RichText from '../RichText'
import SplitWordsAnimation from '../SplitWordsAnimation'
import composeRefs from '../../helpers/composeRefs'
import useFadeInAnimation from '../../animations/useFadeInAnimation'

const SliceHeader = ({
  className,
  classNames = {},
  titleTag = 'h2',
  slice,
  children,
  fullWidth = true,
  noBottomMargin,
  bottomBorder
}) => {
  const classes = useStyles()
  const { title, button, left_copy: leftCopy, right_copy: rightCopy } = slice
  const link = get(button, ['0'], button)
  const ref = useRef()
  const linkRef = useRef()

  const TitleTag = titleTag

  const [inViewRef] = useFadeInAnimation(
    useCallback(() => {
      var children = map(ref.current.children, x => x)
      if (linkRef.current) {
        remove(children, x => x === linkRef.current)
        children = compact([children[0], linkRef.current, children.slice(1)])
      }
      return children
    }, [])
  )

  const noMargin = !title && !link

  return (
    <Section
      grid
      tag='div'
      fullWidth={fullWidth}
      noBottomMargin={noMargin || noBottomMargin}
      ref={composeRefs(inViewRef, ref)}
      className={cn(classes.section, className)}
      bottomBorder={bottomBorder}
    >
      {title && (
        <SplitWordsAnimation>
          <TitleTag className={classes.title}>{title}</TitleTag>
        </SplitWordsAnimation>
      )}
      {leftCopy && (
        <RichText
          content={leftCopy.text}
          className={cn(classes.left, { noMargin })}
        />
      )}
      {rightCopy && (
        <RichText
          content={rightCopy.text}
          className={cn(classes.right, { noMargin })}
        />
      )}
      {link && (
        <div className={cn(classes.link, classNames.link)} ref={linkRef}>
          <StyledButton link={link} />
        </div>
      )}
      {children}
    </Section>
  )
}

const useStyles = createUseStyles(
  {
    section: {
      '& > *': {
        opacity: '0'
      }
    },
    title: {
      extend: [theme.textStyles.h1],
      margin: 0,
      gridColumn: '1 / span 2',
      lineHeight: 1,
      letterSpacing: '-0.01em',
      [theme.breakpoints.up('xs')]: {
        gridColumn: '1 / span 6'
      }
    },
    left: {
      gridColumn: '1 / span 2',
      marginTop: theme.spacing(3), // 3 because of the grid gap
      [theme.breakpoints.up('xs')]: {
        marginTop: theme.spacing(5), // 5 because of the grid gap
        gridColumn: '1 / span 4'
      },
      '&.noMargin': {
        marginTop: theme.spacing(0)
      }
    },
    right: {
      gridColumn: '1 / span 2',
      marginTop: theme.spacing(3),
      [theme.breakpoints.up('xs')]: {
        marginTop: theme.spacing(5),
        gridColumn: '7 / span 5'
      },
      '&.noMargin': {
        marginTop: theme.spacing(0)
      }
    },
    link: {
      display: 'flex',
      alignItems: 'flex-end',
      gridColumn: '1 / span 2',
      gridRow: 3,
      marginTop: theme.spacing(1),
      [theme.breakpoints.up('xs')]: {
        gridRow: 1,
        marginTop: 0,
        gridColumn: '7 / span 6'
      }
    }
  },
  { name: 'SliceHeader' }
)

export default SliceHeader
