import { createSelector, createSlice } from '@reduxjs/toolkit'
import { routeContent } from '../actions'
import get from 'lodash/get'
import filter from 'lodash/filter'
// import map from 'lodash/map'
import find from 'lodash/find'
import reduce from 'lodash/reduce'
import inDom from 'dom-helpers/canUseDOM'
// import { faces } from '../../style/fonts'

export const slicesRequiringPreload = (page) => {
  const slices = get(page, ['slices'], [])
  const slicesRequiringPreload = filter(slices, slice => slice.type === 'project_hero')
  return reduce(slicesRequiringPreload, (result, value) => {
    result[value.id] = true
    return result
  }, {})
}

const preloadSlice = createSlice({
  name: 'preload',
  initialState: {
    pageLoading: true,
    slicesLoading: {}
    // fontsLoading: map(faces, ({ fontFamily, fontWeight }) => ({
    //   fontFamily: fontFamily.replace('"', ''),
    //   fontWeight,
    //   loading: true
    // }))
  },
  reducers: {
    pagePreloaded: (state, action) => {
      state.pageLoading = false
    },
    slicePreloaded: (state, action) => {
      state.slicesLoading[action.payload.slice.id] = false
    }
    // fontPreloaded: (state, { payload }) => {
    //   var font = state.fontsLoading.find(x => x.fontFamily === payload.fontFamily && x.fontWeight === payload.fontWeight)
    //   font.loading = false
    // }
  },
  extraReducers: {
    [routeContent]: (state, action) => {
      state.pageLoading = true
    },
    'page/content_FULFILLED': (state, action) => {
      state.slicesLoading = slicesRequiringPreload(action.payload)
      state.pageLoading = !inDom
    }
  }
})

export const {
  pagePreloaded,
  slicePreloaded
  // fontPreloaded
} = preloadSlice.actions

export const getFontsLoading = state => state.preload.fontsLoading

export const isPreloading = createSelector(
  state => state.preload.pageLoading,
  state => state.preload.slicesLoading,
  getFontsLoading,
  (pageLoading, slicesLoading, fontsLoading) => {
    return pageLoading || !!find(slicesLoading, x => x)
  }
)

export default preloadSlice.reducer
