import React, { useCallback, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import useFadeInAnimation from '../../animations/useFadeInAnimation'
import composeRefs from '../../helpers/composeRefs'
// import { getFontScaling } from '../../style/textStyles'
// import theme from '../../style/theme'
import Section from '../Section'
import { SignupForm } from '../Header/NewsletterSignup/SignupModal'
import theme from '../../style/theme'
import BackgroundVideo from './BackgroundVideo'
import { useSliceColor } from '../useSliceColor'

export default function Newsletter ({ className, slice }) {
  const { title, subheading, joinText, video } = slice
  const classes = useStyles()
  const ref = useRef()

  useFadeInAnimation(useCallback(() => ref.current.children, []))
  const colourRef = useSliceColor(slice._key, true, true)
  const refs = composeRefs(ref, colourRef)

  return (
    <Section grid ref={refs} className={classes.section} fullWidth noBottomMargin>
      <div className={classes.column}>
        <h1>{title}</h1>
        <h2>{subheading}</h2>
        <SignupForm joinText={joinText} className={classes.form} />
      </div>
      <div className={classes.video}>
        {video?.url && <BackgroundVideo desktopVideo={video} cover />}
      </div>
    </Section>
  )
}

const useStyles = createUseStyles({
  section: {
    minHeight: '90vh',
    [theme.breakpoints.down('md')]: {
      minHeight: 'unset'
    }
  },
  column: {
    gridColumn: 'span 5',
    paddingLeft: theme.grid.margin,
    paddingTop: theme.spacing(6),
    '& h1': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3)
    },
    '& h2': {
      marginBottom: theme.spacing(4)
    },
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1',
      paddingRight: theme.grid.margin
    }
  },
  form: {
    '& > * > *': {
      color: 'currentColor'
    }
  },
  video: {
    gridColumn: '7 / -1',
    height: '100%',
    marginLeft: -theme.grid.margin,
    background: 'transparent',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1',
      height: '110vw',
      marginLeft: 0,
      marginTop: theme.spacing(1)
    },
    '& video': {
      left: '0 !important',
      width: '100% !important',
      height: '100% important',
      transform: 'translateY(-50%) !important'
    }
  }
}, { name: 'Newsletter' })
