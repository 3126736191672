import { useRef, useState, useEffect } from 'react'

export default (enabled) => {
  const ref = useRef()
  const [hovering, setHovering] = useState(false)
  useEffect(() => {
    if (enabled && ref.current) {
      const mouseEnter = () => { setHovering(true) }
      const mouseLeave = () => { setHovering(false) }
      ref.current.addEventListener('mouseenter', mouseEnter)
      ref.current.addEventListener('mouseleave', mouseLeave)
      return () => {
        if (ref.current) {
          ref.current.removeEventListener('mouseenter', mouseEnter)
          ref.current.removeEventListener('mouseleave', mouseLeave)
        }
      }
    }
  }, [enabled])
  return { hovering, ref }
}
