import { createAction, createSlice } from '@reduxjs/toolkit'
import { ActionType } from 'redux-promise-middleware'

export const requestSubscribeActionCreator = createAction('newsletter/request')
export const subscribeActionCreator = createAction('newsletter/subscribe')

const newsletterSlice = createSlice({
  name: 'newsletter',
  initialState: {
    busy: false,
    error: null,
    data: null,
    isSubscribed: false
  },
  extraReducers: {
    [requestSubscribeActionCreator.toString()]: (state, action) => {
      state.data = action.payload
    },
    [`${subscribeActionCreator}_${ActionType.Pending}`]: (state, action) => {
      state.error = null
      state.busy = true
    },
    [`${subscribeActionCreator}_${ActionType.Fulfilled}`]: (state, action) => {
      state.error = null
      state.busy = false
      state.isSubscribed = true
    },
    [`${subscribeActionCreator}_${ActionType.Rejected}`]: (state, action) => {
      state.busy = false
      state.error = action.payload
    }
  }
})

export const getNewsletterBusy = state => state.newsletter.busy
export const getNewsletterError = state => state.newsletter.error
export const getNewsletterIsSubscribed = state => state.newsletter.isSubscribed
export const getNewsletterData = state => state.newsletter.data

export default newsletterSlice.reducer
