import React from 'react'
import { createUseStyles } from 'react-jss'
import Section from '../../Section'
import ApproachItem from './ApproachItem'
import theme from '../../../style/theme'
import SliceHeader from '../SliceHeader'

const ApproachSlice = ({ className, slice, last, ...props }) => {
  const classes = useStyles()
  const { items } = slice

  return (
    <Section className={classes.section} bottomBorder={!last} noBottomMargin={last}>
      <SliceHeader slice={slice} noBottomMargin>
        <div className={classes.items}>
          {items.map((item, index) => (
            <ApproachItem
              index={index}
              key={item.id}
              {...item}
            />
          ))}
        </div>
      </SliceHeader>
    </Section>
  )
}

const useStyles = createUseStyles({
  items: {
    gridColumn: 'span 2',
    display: 'grid',
    columnGap: theme.grid.gutter,
    rowGap: `${theme.spacing(4)}px`,
    gridTemplateColumns: '1fr 1fr',
    '& > *': {
      gridColumn: 'span 2'
    },
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('xs')]: {
      gridColumn: '5 / span 8',
      rowGap: `${theme.spacing(8)}px`,
      gridTemplateColumns: 'repeat(8, 1fr)',
      '& > *': {
        gridColumn: 'span 4'
      }
    }
  }
}, { name: 'ApproachSlice' })

export default ApproachSlice
