import React from 'react'
import cn from 'clsx'
import { createUseStyles } from 'react-jss'
import ResponsiveImage from '../ResponsiveImage'
import theme from '../../style/theme'
import Section from '../Section'

const ImageGridSlice = ({ className, slice, last }) => {
  const {
    images,
    span,
    mobileSpan,
    narrowMarginBottom
  } = slice
  const classes = useStyles({ span, mobileSpan })
  return (
    <Section
      className={cn(
        classes.section,
        className,
        { narrowMarginBottom }
      )}
      noBottomMargin={last || narrowMarginBottom}
      grid
    >
      {images && images.map((image, i) => (
        <div key={i} className={classes.gridImage}>
          <ResponsiveImage {...image} aspect={692 / 496} />
        </div>
      ))}
    </Section>
  )
}

const useStyles = createUseStyles({
  section: {
    overflowX: 'auto',
    '&.narrowMarginBottom': {
      marginBottom: theme.spacing(1)
    }
  },
  gridImage: {
    gridColumn: ({ mobileSpan = 1 }) => `span ${mobileSpan}`,
    [theme.breakpoints.up('xs')]: {
      gridColumn: ({ span = 3 }) => `span ${span}`
    }
  }
}, { name: 'ImageGridSlice' })

export default ImageGridSlice
