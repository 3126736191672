import map from 'lodash/map'
import keyBy from 'lodash/keyBy'
import { setNextPages } from '../slices/nextPages'
import { ActionType } from 'redux-promise-middleware'
import { postsContentActionCreator as postsAction } from '../slices/content/posts'
import { workContentActionCreator as workAction } from '../slices/content/work'
import { getPosts, getWork } from '../slices/content'
import { resolveInternalLinkUrl } from '../../helpers/resolveLink'

const onlyUniquePages = (value, index, self) => {
  return self.indexOf(value) === index
}

const getNextArticles = (startIndex, pageSlug, posts) => {
  const relatedPosts = []
  for (let i = 0; i <= 4; i++) {
    relatedPosts.push(posts[(startIndex + i) % posts.length])
  }
  return relatedPosts.filter(onlyUniquePages).filter(page => page.slug !== pageSlug)
}

export default store => next => action => {
  const ret = next(action)
  if (action.type === `${postsAction}_${ActionType.Fulfilled}`) {
    const posts = getPosts(store.getState())
    const nextPages = keyBy(map(posts, (item, i) => {
      const nextArticles = getNextArticles(i, item.slug, posts)
      return {
        slug: item.slug,
        url: resolveInternalLinkUrl(item),
        type: 'post',
        nextPages: nextArticles.map(post => ({
          ...post,
          url: resolveInternalLinkUrl(post)
        }))
      }
    }), x => x.slug)
    store.dispatch(setNextPages(nextPages))
  }
  if (action.type === `${workAction}_${ActionType.Fulfilled}`) {
    const work = getWork(store.getState())
    const nextPages = keyBy(map(work, (item, i) => {
      const nextArticles = getNextArticles(i, item.slug, work)
      return {
        slug: item.slug,
        url: resolveInternalLinkUrl(item),
        type: 'work',
        nextPages: nextArticles.map(caseStudy => ({
          ...caseStudy,
          url: resolveInternalLinkUrl(caseStudy)
        }))
      }
    }), x => x.slug)
    store.dispatch(setNextPages(nextPages))
  }
  return ret
}
