import React, { useState, useEffect, useRef, useMemo } from 'react'
import gsap from 'gsap'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { getActiveFilterTag } from '../../../redux/slices/content'
import Section from '../../Section'
import SliceHeader from '../SliceHeader'
import ArticleGridTile from '../ArticleGridTile'
import { isLargeDesktop } from '../../../redux/slices/layout'
import theme from '../../../style/theme'
import { getCurrentRoutePath } from '../../../redux/slices/location'

const animateInVals = {
  opacity: 1,
  yPercent: 0,
  duration: 0.6,
  stagger: 0.05,
  ease: 'expo.out'
}
const animateOutVals = {
  opacity: 0,
  yPercent: 10,
  duration: 0.3,
  stagger: 0.05,
  ease: 'expo.out'
}

const TileSizes = [
  { aspect: '10:7', span: 8 },
  { aspect: '10:11', span: 4 },

  { aspect: '10:6', span: 12 },

  { aspect: '10:7', span: 8 },
  { aspect: '10:11', span: 4 },

  { aspect: '10:11', span: 6 },
  { aspect: '10:9', span: 6 },

  { aspect: '10:7', span: 8 },
  { aspect: '10:11', span: 4 },

  { aspect: '10:9', span: 4 },
  { aspect: '10:13', span: 4 },
  { aspect: '10:6', span: 4 }
]

const LargeTileSizes = [
  { aspect: '10:7', span: 6 },
  { aspect: '10:11', span: 3 },
  { aspect: '10:7', span: 3 },

  { aspect: '10:7', span: 8 },
  { aspect: '10:11', span: 4 },

  { aspect: '10:7', span: 5 },
  { aspect: '10:11', span: 4 },
  { aspect: '10:9', span: 3 },

  { aspect: '10:6', span: 6 },
  { aspect: '10:9', span: 3 },
  { aspect: '10:6', span: 3 },

  { aspect: '10:8', span: 3 },
  { aspect: '10:7', span: 6 },
  { aspect: '10:9', span: 3 }
]

const WorkListSlice = ({ className, slice, first }) => {
  const { tiles } = slice

  const classes = useStyles()

  const path = useSelector(getCurrentRoutePath)
  const activeFilter = useSelector(getActiveFilterTag)
  const [currentFilter, setCurrentFilter] = useState(activeFilter)
  const [currentPath] = useState(path)
  const largeDesktop = useSelector(isLargeDesktop)

  const gridRef = useRef()

  useEffect(() => {
    if (gridRef.current) {
      gsap.to(gridRef.current.children, {
        opacity: 1
      })
    }
  }, [])

  useEffect(() => {
    // If the path has changed then we are moving to another page, so don's change the filter
    if (gridRef.current && activeFilter !== currentFilter && currentPath === path) {
      gsap.to(gridRef.current.children, {
        ...animateOutVals,
        onComplete: () => {
          setCurrentFilter(activeFilter)
          setTimeout(() => {
            gsap.fromTo(gridRef.current.children, { opacity: 0, yPercent: 10 }, animateInVals)
          }, 300)
        }
      })
      return () => {

      }
    }
  }, [activeFilter, currentFilter, tiles])

  const filteredAspect = '10:9'
  const filteredSpan = largeDesktop ? 4 : 6

  const workToShow = useMemo(() => {
    return activeFilter ? tiles.filter(({ article }) => article.tag.slug === currentFilter) : tiles
  }, [currentFilter])

  return (
    <>
      <Section tag='div' noBottomMargin>
        <SliceHeader slice={slice} titleTag={first ? 'h1' : 'h2'} />
      </Section>
      <Section grid tag='div' className={classes.grid} ref={gridRef}>
        {workToShow && workToShow.map(({ article }, i) => {
          const { aspect, span } = TileSizes[i % TileSizes.length]
          const { aspect: largeAspect, span: largeSpan } = LargeTileSizes[i % LargeTileSizes.length]
          return (
            <ArticleGridTile
              key={article.id}
              aspect={currentFilter ? filteredAspect : aspect}
              span={currentFilter ? filteredSpan : span}
              largeAspect={currentFilter ? filteredAspect : largeAspect}
              largeSpan={currentFilter ? filteredSpan : largeSpan}
              article={article}
              preload={i < 3}
            />
          )
        })}
      </Section>
    </>
  )
}

const useStyles = createUseStyles({
  grid: {
    '& > *': {
      opacity: 0
    },
    rowGap: `${theme.spacing(3)}px`,
    [theme.breakpoints.up('md')]: {
      rowGap: `${theme.spacing(6)}px`
    }
  }
}, { name: 'WorkListSlice' })

export default WorkListSlice
