import React, { useMemo, useRef, useEffect, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import gsap from 'gsap'
import theme from '../../../style/theme'
import Section from '../../Section'
import Tile from './Tile'
import Slider, { defaultOptions } from '../../Slider'
import { useSelector } from 'react-redux'
import { isCurrentBreakpointMobile } from '../../../redux/slices/layout'
import useDebouncedCallback from '../../../hooks/useDebouncedCallback'
import useWindowResize from '../../../hooks/useWindowResize'
import SliceHeader from '../SliceHeader'
import composeRefs from '../../../helpers/composeRefs'
import useFadeInAnimation from '../../../animations/useFadeInAnimation'
import StyledButton from '../../StyledButton'

const ArticleTiles = ({ slice }) => {
  const { articles = [] } = slice
  const classes = useStyles()
  const sliderRef = useRef()

  const isMobile = useSelector(isCurrentBreakpointMobile)

  const options = useMemo(() => ({
    ...defaultOptions,
    draggable: isMobile
  }), [isMobile])

  const windowWidthRef = useRef(0)
  const flickityRef = useRef()

  useWindowResize(useDebouncedCallback((props) => {
    if (flickityRef.current && windowWidthRef.current !== window.innerWidth) {
      windowWidthRef.current = window.innerWidth
      const articleContainers = flickityRef.current.element.querySelectorAll('.' + classes.articleContainer)
      gsap.set(articleContainers, { minHeight: 'auto' })
      flickityRef.current.resize()
      gsap.set(articleContainers, { minHeight: '100%' })
    }
  }, 250))

  useEffect(() => {
    windowWidthRef.current = window.innerWidth
  }, [])

  const [inViewRef] = useFadeInAnimation(
    useCallback(() => document.querySelectorAll(`.${classes.articleContainer}`), []),
    { stagger: 0.05 }
  )

  const { button: link } = slice

  return (
    <>
      <SliceHeader slice={slice} fullWidth={false} classNames={{ link: classes.desktopLink }} />
      <Section className={classes.section} fullWidth>
        <Slider className={classes.container} options={options} flickityRef={flickityRef} ref={composeRefs(inViewRef, sliderRef)}>
          {articles.map(article => (
            <div key={article.id} className={classes.articleContainer}>
              <Tile className={classes.article} classNames={{ progressLine: classes.progressLine }} {...article} />
            </div>
          ))}
        </Slider>
      </Section>
      <Section className={classes.mobileButton}>
        <StyledButton link={link} />
      </Section>
    </>
  )
}

const useStyles = createUseStyles({
  section: {
    position: 'relative',
    overflow: 'hidden'
  },
  container: {
    padding: [0, theme.grid.margin, theme.grid.margin],
    margin: [0, -theme.grid.gutter / 2, 0]
  },
  articleContainer: {
    minHeight: '100%',
    display: 'flex',
    width: '80%',
    opacity: 0,
    [theme.breakpoints.up('xs')]: {
      width: '40%'
    },
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% / 3)'
    },
    [theme.breakpoints.up('xl')]: {
      width: 'calc(100% / 4)'
    },
    [theme.breakpoints.down('xl')]: {
      '& > *:nth-of-type(1n+4)': {
        display: 'none'
      }
    }
  },
  article: {
    width: '100%',
    padding: [0, theme.grid.gutter / 2]
  },
  progressLine: {
    left: theme.spacing(0.5),
    right: theme.spacing(0.5)
  },
  mobileButton: {
    display: 'block',
    marginTop: theme.spacing(-2),
    [theme.breakpoints.up('xs')]: {
      display: 'none'
    }
  },
  desktopLink: {
    display: 'none',
    [theme.breakpoints.up('xs')]: {
      display: 'flex'
    }
  }
}, { name: 'ArticleTiles' })

export default ArticleTiles
