import React, { useCallback, useRef, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch } from 'react-redux'
import cn from 'clsx'
import isEmpty from 'lodash/isEmpty'
import compact from 'lodash/compact'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

import { slicePreloaded } from '../../redux/slices/preload'
import { useScrollReadyListener } from '../useSmoothScrollbar'
import theme from '../../style/theme'
import { getFontScaling } from '../../style/textStyles'
import SplitWordsAnimation from '../SplitWordsAnimation'
import ResponsiveImage from '../ResponsiveImage'
import Section from '../Section'
import BackgroundVideo from './BackgroundVideo'
import { useSliceColor } from '../useSliceColor'

// this is a simple parralax image that will only work when the slice is at the top of the page
const useParallaxImage = () => {
  const ref = useRef()

  useScrollReadyListener(
    useCallback(({ scrollElement }) => {
      const tl = gsap.timeline()
      const scrollTrigger = ScrollTrigger.create({
        scroller: scrollElement,
        trigger: ref.current,
        pin: false,
        start: 'top top',
        end: 'bottom top',
        scrub: 0,
        animation: tl
      })
      tl.to(
        ref.current.querySelector('img'),
        { y: window.innerHeight * 0.25, ease: 'none' },
        0
      )
      return () => {
        tl.kill()
        scrollTrigger.kill()
      }
    }, [])
  )

  return ref
}

const Quotes = ({ classes, quotes }) => {
  const getStyle = index => {
    return {
      gridColumn: index % 3 === 0 ? '1 / span 2' : null,
      justifySelf: index % 3 === 1 ? 'end' : null
    }
  }
  return (
    <div className={classes.quotes}>
      {quotes.map((quote, index) => (
        <span className={classes.quote} key={quote} style={getStyle(index)}>
          {quote}
        </span>
      ))}
    </div>
  )
}

const ProjectHero = ({ className, slice, tag = 'div', serializers }) => {
  const {
    id,
    title,
    subtitle,
    image,
    quotes,
    desktop_video: desktopVideo,
    mobile_video: mobileVideo
  } = slice
  const classes = useStyles()
  const hasQuotes = !isEmpty(quotes)
  const sliceColorRef = useSliceColor(id, image, true)
  const dispatch = useDispatch()

  const onImageLoaded = useCallback(() => {
    dispatch(slicePreloaded({ slice }))
  }, [dispatch])

  const imageRef = useParallaxImage()
  useEffect(() => {
    gsap.to(compact([imageRef.current.querySelector('img')]), {
      scale: 1,
      duration: 1
    })
  }, [])

  return (
    <Section fullWidth className={cn(classes.content, className)}>
      <div className={classes.imageContainer} ref={sliceColorRef}>
        <ResponsiveImage
          {...image}
          className={classes.image}
          onImageLoaded={onImageLoaded}
          disableLazy
          preload
          ref={imageRef}
        />
        {desktopVideo?.url && (
          <BackgroundVideo
            desktopVideo={desktopVideo}
            mobileVideo={mobileVideo}
            cover
          />
        )}
        {hasQuotes && <Quotes quotes={quotes} classes={classes} />}
      </div>
      <Section tag='div' grid noBottomMargin>
        <div className={classes.titleContainer}>
          {/* <SplitWordsAnimation duration={0.8} stagger={0.1} threshold={0}> */}
          <h1 className={classes.heroTitle}>
            {title && (
              <SplitWordsAnimation duration={0.8} stagger={0.1}>
                <span>
                  {title}
                </span>
              </SplitWordsAnimation>
            )}
            {subtitle && (
              <SplitWordsAnimation duration={0.8} stagger={0.1} delay={title ? 0.2 : 0}>
                <span className={cn(classes.subtitle)}>
                  {subtitle}
                </span>
              </SplitWordsAnimation>
            )}
          </h1>
          {/* </SplitWordsAnimation> */}
        </div>
      </Section>
    </Section>
  )
}

const useStyles = createUseStyles(
  {
    imageContainer: {
      position: 'relative'
    },
    subtitle: {
      opacity: 0.5
    },
    image: {
      marginBottom: theme.spacing(3),
      '& picture:before': {
        paddingTop: '120%',
        [theme.breakpoints.up('xs')]: {
          paddingTop: '62%'
        }
      },
      '& img': {
        transform: 'scale(1.2)'
      }
    },
    titleContainer: {
      gridColumn: '1 / span 2',
      [theme.breakpoints.up('xs')]: {
        gridColumn: '1 / span 6'
      }
    },
    quotes: {
      color: theme.colors.white,
      position: 'absolute',
      bottom: 0,
      right: 0,
      display: 'none',
      fontSize: 8,
      margin: theme.spacing(2),
      width: 450,
      gridTemplateColumns: '1fr 1fr',
      columnGap: theme.spacing(2),
      rowGap: `${theme.spacing(1)}px`,
      [theme.breakpoints.up('md')]: {
        display: 'grid'
      },
      ...getFontScaling(8),
      '&:before': {
        content: '""',
        backgroundColor: '#F2FC2B',
        position: 'absolute',
        borderRadius: '50%',
        border: [1, 'solid', theme.colors.white],
        width: 8,
        height: 8,
        left: -20,
        top: 3
      }
    },
    quote: {
      mixBlendMode: 'difference',
      extend: theme.textStyles.caption,
      display: 'block',
      maxWidth: 160
    },
    heroTitle: {
      marginBottom: 0
    }
  },
  { name: 'ProjectHero' }
)

export default ProjectHero
