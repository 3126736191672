import React, { forwardRef } from 'react'
import cn from 'clsx'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import { getNextPageColorByPath } from '../../redux/slices/content'
import { useSelector } from 'react-redux'

export default forwardRef((props, ref) => {
  const { className } = props
  const pageColor = useSelector(getNextPageColorByPath)
  const classes = useStyles({ pageColor })

  return (
    <div className={cn(classes.curtain, className)} id='page-curtain' ref={ref} />
  )
})

const useStyles = createUseStyles({
  curtain: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    width: '100%',
    transform: 'translateY(-105%)',
    backgroundColor: ({ pageColor = theme.colors.black }) => pageColor === '#ffffff' ? theme.colors.black : pageColor
  }
}, { name: 'Curtain' })
