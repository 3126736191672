import React, { useCallback } from 'react'
import SignupModal from './SignupModal'
import { useSelector, useDispatch } from 'react-redux'
import { closeSignUpDialog, isSignUpDialogOpen, openSignUpDialog } from '../../../redux/slices/layout'
import DialogToggleButton from '../DialogToggleButton'

const SignupButton = ({ className, mobile }) => {
  const dialogOpen = useSelector(isSignUpDialogOpen)[mobile ? 'mobile' : 'desktop']
  const dispatch = useDispatch()

  const onClose = useCallback(() => {
    dispatch(closeSignUpDialog({ mobile }))
  }, [mobile])

  const onOpen = useCallback(() => {
    dispatch(openSignUpDialog({ mobile }))
  }, [mobile])

  return (
    <DialogToggleButton
      className={className}
      open={dialogOpen}
      onClose={onClose}
      onOpen={onOpen}
      ariaLabel={`${dialogOpen ? 'Show' : 'Hide'} the newsletter signup`}
      text={mobile && dialogOpen ? 'Back To Menu' : 'Subscribe'}
      back={mobile}
      ignoredQuery='.newsletter-btn-footer'
    >
      <SignupModal active={dialogOpen} onClose={onClose} mobile={mobile} />
    </DialogToggleButton>
  )
}

export default SignupButton
