import React, { useEffect, useRef, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import ResponsiveImage from '../ResponsiveImage'
import cn from 'clsx'
import round from '../../helpers/round'
import theme from '../../style/theme'
import BackgroundVideo from './BackgroundVideo'

const Image = ({ preload, image, aspect, index, classes, onImageLoaded }) => {
  const handleImageLoaded = useCallback(() => { onImageLoaded(index) }, [index])
  return <ResponsiveImage {...image} className={cn(classes.image, { hide: index !== 0 })} aspect={aspect} onImageLoaded={handleImageLoaded} preload={preload} />
}

const ImageTicker = ({
  className,
  tickerClassName,
  images = [],
  animate,
  aspect = 1,
  mobileAspect,
  largeAspect,
  desktopVideo,
  mobileVideo,
  preload
}) => {
  const classes = useStyles({ aspect, mobileAspect: mobileAspect || aspect, largeAspect })
  const containerRef = useRef()
  const currentFrameRef = useRef(0)
  const loadedImages = useRef({ 0: true })

  if (images.length === 0) return null
  if (images.length === 1 || (desktopVideo && desktopVideo.url)) {
    return (
      <div className={cn(className, classes.container)} ref={containerRef}>
        <ResponsiveImage {...images[0]} className={classes.image} preload={preload} />
        {desktopVideo && desktopVideo.url && <BackgroundVideo desktopVideo={desktopVideo} mobileVideo={mobileVideo} />}
      </div>
    )
  }

  useEffect(() => {
    const bufferNextImage = () => {
      const nextFrame = (currentFrameRef.current + 1) % images.length
      containerRef.current.children[nextFrame].style.display = 'block'
      containerRef.current.children[nextFrame].style.visibility = 'hidden' // This will load the image ready to animate next
    }

    const showNextImage = (imageIndex) => {
      containerRef.current.children[currentFrameRef.current].style.visibility = 'hidden'
      currentFrameRef.current = imageIndex
      containerRef.current.children[currentFrameRef.current].style.display = 'block'
      containerRef.current.children[currentFrameRef.current].style.visibility = 'visible'
    }

    const tick = () => {
      const nextFrame = (currentFrameRef.current + 1) % images.length
      if (loadedImages.current[nextFrame]) {
        showNextImage(nextFrame)
        bufferNextImage()
      }
    }

    if (containerRef.current && animate) {
      bufferNextImage()
      tick()
      var intervalId = setInterval(tick, 250)
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
      showNextImage(0)
    }
  }, [animate])

  const imageLoaded = useCallback((index) => {
    loadedImages.current = {
      ...loadedImages.current,
      [index]: true
    }
  }, [])

  return (
    <div className={cn(className, classes.container)} ref={containerRef}>
      {images.map((image, i) => <Image key={i} classes={classes} index={i} image={image} aspect={aspect} onImageLoaded={imageLoaded} preload={preload && i === 0} />)}
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    paddingTop: ({ mobileAspect }) => `${round(100 / mobileAspect)}%`,
    [theme.breakpoints.up('xs')]: {
      paddingTop: ({ aspect }) => aspect ? `${round(100 / aspect)}%` : undefined
    },
    [theme.breakpoints.up('xxl')]: {
      paddingTop: ({ largeAspect }) => largeAspect ? `${round(100 / largeAspect)}%` : undefined
    }

  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '&.hide': {
      display: 'none'
    }
  }
}, { name: 'ImageTicker' })

export default ImageTicker
