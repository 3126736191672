import layout from './layout'
import content from './content'
import config from './config'
import nextPages from './nextPages'
import newsletter from './newsletter'
import preload from './preload'

export default {
  config,
  layout,
  nextPages,
  preload,
  content,
  newsletter
}
