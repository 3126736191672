import React, { useCallback, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import useFadeInAnimation from '../../animations/useFadeInAnimation'
import composeRefs from '../../helpers/composeRefs'
import { getFontScaling } from '../../style/textStyles'
import theme from '../../style/theme'
import Section from '../Section'

const Quote = ({ className, slice: { quote, attribution } }) => {
  const classes = useStyles()
  const ref = useRef()
  const [inViewRef] = useFadeInAnimation(useCallback(() => ref.current.children, []))
  return (
    <Section grid>
      <div className={classes.quote} ref={composeRefs(inViewRef, ref)}>
        <blockquote className={classes.quoteText}>{quote}</blockquote>
        {attribution && <div className={classes.attribution}>{attribution}</div>}
      </div>
    </Section>
  )
}

const useStyles = createUseStyles({
  quote: {
    gridColumn: '1 / span 2',
    [theme.breakpoints.up('xs')]: {
      gridColumn: '7 / span 4'
    }
  },
  quoteText: {
    lineHeight: 1.5,
    fontSize: 24,
    display: 'block',
    margin: 0,
    padding: 0,
    opacity: 0,
    ...getFontScaling(24)
  },
  attribution: {
    opacity: 0,
    display: 'block',
    paddingLeft: '1em',
    position: 'relative',
    fontSize: 14,
    marginTop: theme.spacing(2),
    ...getFontScaling(14),
    '&:before': {
      content: '"-"',
      position: 'absolute',
      left: 0,
      display: 'block'
    }
  }
}, { name: 'Quote' })

export default Quote
