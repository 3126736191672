import React, { useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import get from 'lodash/get'

import useHlsPlayer from '../../../hooks/useHlsPlayer'
import { isCurrentBreakpointMobile } from '../../../redux/slices/layout'
import { useInView } from 'react-intersection-observer'
import useComposeRefs from '../../../hooks/useComposeRefs'

export default ({ className, desktopVideo, mobileVideo, canPlay, onCanPlay }) => {
  const playerRef = useRef()
  const playPromise = useRef()

  const isMobile = useSelector(isCurrentBreakpointMobile)
  const url = isMobile
    ? get(mobileVideo, ['url'], desktopVideo.url)
    : desktopVideo.url

  useHlsPlayer(playerRef, url)

  const [inViewRef, inView] = useInView({ threshold: 0 })

  const togglePlay = useCallback(play => {
    if (!playerRef.current) return

    if (play) {
      playPromise.current = playerRef.current.play()
      return
    }

    if (!playPromise.current) return
    playPromise.current = playPromise.current.then(() => {
      playerRef.current.pause()
    })
  }, [])

  useEffect(() => {
    if (!canPlay) return
    togglePlay(inView)
  }, [togglePlay, inView, canPlay])

  const composedRefs = useComposeRefs(inViewRef, playerRef)

  return (
    <video
      ref={composedRefs}
      src={url}
      className={className}
      controls={false}
      autoPlay
      muted
      loop
      playsInline
      crossOrigin='anonymous'
      onCanPlay={onCanPlay}
    />
  )
}
