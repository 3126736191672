import { NOT_FOUND, selectLocationState } from 'redux-first-router'
import { createSelector } from 'reselect'
import compact from 'lodash/compact'
import first from 'lodash/first'

export const getCurrentRoute = state => selectLocationState(state).type
export const getCurrentRoutePath = state => selectLocationState(state).pathname
export const getRoutesMap = state => selectLocationState(state).routesMap
export const isPageNotFound = (state) => getCurrentRoute(state) === NOT_FOUND
export const isRedirect = (state) => selectLocationState(state).kind === 'redirect'
export const getCurrentQueryString = state => selectLocationState(state).query
export const isPreviewRoute = createSelector(
  getCurrentRoutePath,
  (pathname) => first(compact(pathname.split('/'))) === 'preview'
)
