import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import range from 'lodash/range'
import cn from 'clsx'
import theme from '../style/theme'
import gsap from 'gsap'
import useDebouncedCallback from '../hooks/useDebouncedCallback'
import useWindowResize from '../hooks/useWindowResize'

const Ticker = ({ text, className, duration = 10, direction = -1, offset = 0 }) => {
  const classes = useStyles()

  const containerRef = useRef()
  const [count, setCount] = useState(1)
  const localsRef = useRef({})

  const resize = useCallback(() => {
    if (containerRef.current) {
      var textElement = containerRef.current.children[0]
      const rect = textElement.getBoundingClientRect()
      const c = rect.width > window.innerWidth
        ? 2
        : Math.ceil(window.innerWidth / rect.width) + 1
      setCount(c)
      localsRef.current.width = rect.width

      var tl = gsap.timeline({ repeat: -1 })
      const offsetPixels = (localsRef.current.width * offset)
      const start = (direction > 0 ? -localsRef.current.width : 0) - offsetPixels
      const end = (direction > 0 ? 0 : -localsRef.current.width) - offsetPixels
      tl.fromTo(containerRef.current, { x: start }, { x: end, duration, ease: 'none' })
      localsRef.current.timeline = tl

      return () => {
        if (localsRef.current.timeline) {
          localsRef.current.timeline.kill()
        }
      }
    }
  }, [text])

  useEffect(resize, [resize])

  useWindowResize(useDebouncedCallback(resize, 250, [resize]))

  const repeat = useMemo(() => range(count), [count])

  return (
    <div className={cn(className, classes.ticker)} ref={containerRef}>
      {repeat.map((_, key) => <span key={key} className={classes.text}>{text}</span>)}
    </div>
  )
}

const duration = [30, 35, 25]

const MultiLineTicker = ({ className, title, copy }) => {
  const classes = useStyles()
  const repeat = range(3)

  return (
    <div className={classes.multilineTicker}>
      {repeat.map((_, i) => (
        <React.Fragment key={i}>
          <Ticker text={copy} className={classes.title} direction={i % 2 === 0 ? -1 : 1} duration={duration[i]} />
          <Ticker text={copy} className={classes.copy} direction={i % 2 === 0 ? 1 : -1} duration={duration[i] / 5} />
        </React.Fragment>
      ))}
    </div>
  )
}

const useStyles = createUseStyles({
  multilineTicker: {
    position: 'relative',
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    overflow: 'hidden',
    padding: [theme.spacing(2), 0]
  },
  title: {
    fontSize: 130,
    textTransform: 'uppercase',
    lineHeight: 1,
    '& > span': {
      padding: [0, '0.15em']
    },
    [theme.breakpoints.up('xs')]: {
      fontSize: 190
    }
  },
  copy: {
    fontSize: 14,
    textTransform: 'uppercase',
    lineHeight: 1,
    '& > span': {
      padding: [0, '0.35em']
    }
  },
  ticker: {
    whiteSpace: 'nowrap',
    overflow: 'visible'
  },
  text: {
    display: 'inline-block'
  }
}, { name: 'MultiLineTicker' })

export default MultiLineTicker
