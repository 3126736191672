import React, {
  forwardRef,
  useRef,
  useContext,
  useCallback,
  useEffect,
  useState
} from 'react'
import { createUseStyles } from 'react-jss'
import get from 'lodash/get'
import map from 'lodash/map'
import cn from 'clsx'
import Color from 'color'
import gsap from 'gsap'
import { Helmet } from 'react-helmet-async'

import round from '../helpers/round'
import composeRefs from '../helpers/composeRefs'
import { quart } from '../style/eases'
import theme from '../style/theme'
import useImageInAnimation from '../animations/useImageInAnimation'
import ColorContext from './ColorContext'
import { values as breakpoints } from '../style/breakpoints'

const SHOW_PALETTE = false

const Picture = forwardRef(
  ({ classes, alt, sizes, disableLazy, onLoad, preload }, ref) => {
    const hasWebp = !!get(sizes, [0, 'webpUrl'])
    const srcset = key =>
      sizes.map(item => `${item[key]} ${item.width}w`).join()
    const srcName = (disableLazy || preload) ? 'srcSet' : 'data-srcset'

    // if (preload) {
    //   console.log(srcset('webpUrl'))
    // }

    return (
      <picture>
        {hasWebp && (
          <source {...{ [srcName]: srcset('webpUrl') }} type='image/webp' />
        )}
        {sizes && !hasWebp && <source {...{ [srcName]: srcset('url') }} />}
        <img
          ref={ref}
          data-sizes='auto'
          alt={alt}
          className={cn(
            (sizes && !disableLazy && 'lazyload') ||
              (disableLazy && 'lazyloaded'),
            classes.image
          )}
          onLoad={onLoad}
        />
        {preload &&
          <Helmet>
            <link
              rel='preload'
              as='image'
              href={hasWebp ? sizes[0].webpUrl : sizes[0].url}
              imageSrcSet={srcset(hasWebp ? 'webpUrl' : 'url')}
              imageSizes='50vw'
              media={`(min-width: ${breakpoints.md}px)`}
            />
          </Helmet>}
      </picture>
    )
  }
)

const NoScript = ({ classes, alt, sizes, onLoad }) => {
  if (sizes) {
    return (
      <noscript>
        <img
          src={sizes[sizes.length - 1].url}
          srcSet={sizes.map(item => `${item.url} ${item.width}w`).join()}
          className={`${classes.image} lazyloaded`}
          alt={alt}
          onLoad={onLoad}
        />
      </noscript>
    )
  }
  return null
}

const Palette = ({ classes, palette }) => {
  return (
    <div className={classes.palette}>
      {map(palette, (colors, pName) => (
        <React.Fragment key={pName}>
          <label>{pName}</label>
          <ul>
            {map(colors, (c, name) => (
              <li key={name}>
                <div style={{ backgroundColor: c, width: 10, height: 10 }} />
                {name} ({c})
              </li>
            ))}
          </ul>
        </React.Fragment>
      ))}
    </div>
  )
}

const ResponsiveImage = React.forwardRef(function ResponsiveImage (props, ref) {
  const [loaded, setLoaded] = useState()
  const { backgroundColor = theme.colors.white } = useContext(ColorContext)
  const bg = Color(backgroundColor)
  const lightBg = bg
    .lighten(1.5)
    .alpha(0.4)
    .string()
  const darkBg = bg
    .darken(0.1)
    .alpha(0.2)
    .string()
  const imageBackgroundColor = bg.isDark() ? lightBg : darkBg

  const {
    disableLazy,
    aspect,
    mobileAspect,
    children,
    className,
    alt,
    sizes,
    palette,
    hotspot,
    crop,
    style,
    title,
    onImageLoaded,
    pictureRef,
    preload
  } = props

  const classes = useStyles({
    aspect,
    mobileAspect,
    palette,
    backgroundColor: imageBackgroundColor,
    hotspot,
    crop
  })

  const containerRef = useRef()
  const localsRef = useRef({ loaded: false })
  const imageRef = useRef()

  const onLoad = useCallback(
    e => {
      if (!localsRef.current.loaded && e.target.currentSrc) {
        gsap.to(containerRef.current, {
          backgroundColor: Color(imageBackgroundColor)
            .alpha(0)
            .string(),
          duration: 0.5
        })
        if (onImageLoaded) onImageLoaded(e)
        localsRef.current.loaded = true
        setLoaded(true)
      }
    },
    [imageBackgroundColor, onImageLoaded]
  )

  useEffect(() => {
    if (onImageLoaded && imageRef.current && imageRef.current.complete) {
      onImageLoaded({ target: imageRef.current })
      setLoaded(true)
    }
  }, [onImageLoaded])

  var [inViewRef] = useImageInAnimation(
    useCallback(() => {
      return containerRef.current.querySelector('img')
    }, []),
    loaded
  )

  return (
    <div
      className={cn(classes.container, className, { withCaption: title })}
      ref={composeRefs(ref, inViewRef, containerRef)}
      style={style}
    >
      <Picture
        classes={classes}
        alt={alt}
        sizes={sizes}
        disableLazy={disableLazy}
        ref={composeRefs(pictureRef, imageRef)}
        onLoad={onLoad}
        preload={preload}
      />
      <NoScript classes={classes} alt={alt} sizes={sizes} />
      {children}
      {title && <span className={classes.caption}>{title}</span>}
      {SHOW_PALETTE && <Palette palette={palette} classes={classes} />}
    </div>
  )
})

const useStyles = createUseStyles(
  {
    palette: {
      position: 'absolute',
      top: 16,
      left: 16,
      backgroundColor: 'rgba(0,0,0,0.5)',
      padding: 16,
      color: 'white',
      fontSize: 8,
      '& ul': {
        display: 'flex',
        margin: 0,
        padding: 0,
        listStyle: 'none'
      },
      '& li': {
        display: 'flex',
        alignItems: 'center',
        marginRight: 16,
        '& div': {
          display: 'block',
          marginRight: 16
        }
      },
      '& label': {
        marginBottom: 16,
        fontSize: '1.2em'
      }
    },
    container: {
      position: 'relative',
      width: '100%',
      display: 'block',
      overflow: 'hidden',
      backgroundColor: ({ backgroundColor }) => backgroundColor,
      '& picture::before': {
        display: 'block',
        [theme.breakpoints.up('md')]: {
          content: ({ aspect }) => (aspect ? '""' : undefined),
          paddingTop: ({ aspect }) => aspect ? `${round(100 / aspect)}%` : undefined
        },
        [theme.breakpoints.down('md')]: {
          content: ({ aspect, mobileAspect }) => ((aspect || mobileAspect) ? '""' : undefined),
          paddingTop: ({ aspect, mobileAspect }) => {
            // eslint-disable-next-line
            const x = (aspect || mobileAspect) ? `${round(100 / (mobileAspect ? mobileAspect : aspect))}%` : undefined
            return x
          }
        }
      },
      '&.withCaption': {
        overflow: 'visible',
        marginBottom: 32
      }
    },
    image: {
      position: 'absolute',
      opacity: 0,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      transition: `opacity 0.5s ${quart.out}`,
      willChange: 'opacity',
      objectFit: 'cover',
      objectPosition: ({ hotspot, crop }) => {
        if (!hotspot) return '50% 50%'
        if (!crop) return `${hotspot.x * 100}% ${hotspot.y * 100}%`
        return `${((hotspot.x - crop.left) / (1 - (crop.left + crop.right))) *
          100}% ${((hotspot.y - crop.top) / (1 - (crop.top + crop.bottom))) *
          100}%`
      },
      fontFamily: '"object-fit: cover;"' // object-fit polyfill
    },
    link: {
      textDecoration: 'none'
    },
    caption: {
      position: 'absolute',
      top: '100%',
      right: 0,
      fontSize: 10,
      lineHeight: 1.4,
      fontFamily: theme.fonts.mono,
      display: 'block',
      marginTop: 6
    }
  },
  { name: 'ResponsiveImage' }
)

export default ResponsiveImage
