import React, { useMemo } from 'react'
import cn from 'clsx'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { getWorkHome } from '../../../redux/slices/content'
import Link from '../../Link'
import ResponsiveImage from '../../ResponsiveImage'
import { resolveInternalLink } from '../../../helpers/resolveLink'
import theme, { span } from '../../../style/theme'
import ProgressLine from '../../ProgressLine'
import useHovering from '../../../hooks/useHovering'

const MenuTile = ({ className, classNames = {}, article }) => {
  const {
    title,
    tag,
    portrait_image: image,
    client
  } = article
  const classes = useStyles()

  const workHome = useSelector(getWorkHome)
  const link = useMemo(() => resolveInternalLink(article), [article])
  const { hovering, ref } = useHovering(true)

  return (
    <div className={cn(classes.tile, className)} ref={ref}>
      <Link className={classes.tileBody} link={link} nonLinkTag='div'>
        <ResponsiveImage {...image} className={cn(classes.image, classNames.image)} aspect={0.76} />
        <div className={cn(classes.content, classNames.content)}>
          <h4 className={classes.title}>{title}</h4>
        </div>
      </Link>
      <div className={cn(classes.meta, classNames.meta)}>
        {tag && <Link to={`/${workHome.reference.slug}?filter=${tag.slug}`} className={classes.tag}>{tag.title}</Link>}
        <span className={classes.client}>{client}</span>
      </div>
      <ProgressLine hovering={hovering} color={theme.colors.white} backgroundColor={theme.colors.borderLight} />
    </div>
  )
}

const useStyles = createUseStyles({
  tile: {
    display: 'block'
  },
  meta: {
    extend: theme.textStyles.caption,
    display: 'flex',
    overflow: 'hidden',
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.up('md')]: {
      maxWidth: span(4, 'md')
    }
  },
  metaTag: {
    display: 'block',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  tag: {
    minWidth: 120,
    composes: ['$metaTag']
  },
  client: {
    display: 'block',
    flex: 1,
    opacity: 0.5,
    composes: ['$metaTag']
  },
  tileBody: {
    textDecoration: 'none'
  },
  content: {
    overflow: 'hidden',
    [theme.breakpoints.up('xs')]: {
      maxWidth: span(5, 'md')
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: span(3, 'md')
    }
  },
  image: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    overflow: 'hidden'
  },
  title: {
    extend: theme.textStyles.body,
    marginBottom: theme.spacing(2)
  }
}, { name: 'MenuTile' })

export default MenuTile
