import React from 'react'
import { createUseStyles } from 'react-jss'
import Section from '../../Section'
import SliceHeader from '../SliceHeader'

const FeaturedText = ({ className, slice, first, last, ...props }) => {
  const classes = useStyles()

  const removeBottomBorder = slice.removeBottomBorder || last

  return (
    <Section className={classes.section} bottomBorder={!removeBottomBorder} noBottomMargin={last}>
      <SliceHeader slice={slice} noBottomMargin titleTag={first ? 'h1' : 'h2'} />
    </Section>
  )
}

const useStyles = createUseStyles({
}, { name: 'FeaturedText' })

export default FeaturedText
