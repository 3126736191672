import React, { useState, useRef, useEffect, useCallback } from 'react'
import cn from 'clsx'
import gsap from 'gsap'
import { createUseStyles } from 'react-jss'
import theme from '../../../style/theme'
import Award from './Award'
import StyledButton from '../../StyledButton'
import useFadeInAnimation from '../../../animations/useFadeInAnimation'
import SplitWordsAnimation from '../../SplitWordsAnimation'
import composeRefs from '../../../helpers/composeRefs'
import { getFontScaling } from '../../../style/textStyles'

const AWARDS_PER_PAGE = 9

const padZeros = number => (number <= 9 ? `0${number}` : `${number}`)

const AwardColumn = props => {
  const {
    calendar_year: calendarYear,
    awards,
    setNextImageId,
    setHoverActive,
    className,
    onLoadMore,
    onMouseEnter,
    onMouseLeave
  } = props

  const classes = useStyles()

  const initialAwards = awards.slice(0, AWARDS_PER_PAGE)
  const [awardsToShow, setAwardsToShow] = useState(initialAwards)
  const [currStartIndex, setCurrStartIndex] = useState(0)

  const awardsRef = useRef()

  const paginate = () => {
    if (awardsToShow.length < awards.length) {
      const newStartIndex = awardsToShow.length
      if (awardsToShow.length + AWARDS_PER_PAGE < awards.length) {
        setAwardsToShow(awards.slice(0, awardsToShow.length + AWARDS_PER_PAGE))
      } else {
        setAwardsToShow(awards)
      }
      setCurrStartIndex(newStartIndex)
      if (onLoadMore) onLoadMore()
    }
  }

  const [inViewRef] = useFadeInAnimation(
    useCallback(() => awardsRef.current.children, []),
    { stagger: 0.06 }
  )

  useEffect(() => {
    if (currStartIndex !== 0) {
      const awardNodes = Array.from(awardsRef.current.children)
      const awardsToAnimate = awardNodes.slice(currStartIndex)
      gsap.fromTo(
        [...awardsToAnimate],
        {
          opacity: 0
        },
        {
          opacity: 1,
          stagger: 0.06,
          duration: 1.2,
          ease: 'expo.out',
          delay: 0.15
        }
      )
    }
  }, [currStartIndex])

  return (
    <div className={cn(classes.list, className)}>
      <SplitWordsAnimation>
        <h3 className={classes.columnTitle}>
          {calendarYear}
          <span className={classes.awardsCount}>
            ({padZeros(awards.length)})
          </span>
        </h3>
      </SplitWordsAnimation>
      <div className={classes.awards} ref={composeRefs(inViewRef, awardsRef)}>
        {awardsToShow.map(award => (
          <Award
            key={award.id}
            setNextImageId={setNextImageId}
            setHoverActive={setHoverActive}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            award={award}
          />
        ))}
      </div>
      <StyledButton
        className={cn(classes.loadMoreButton, {
          visible: awardsToShow.length < awards.length
        })}
        iconClassName={classes.icon}
        onClick={paginate}
        buttonLabel='More'
      />
    </div>
  )
}

const useStyles = createUseStyles(
  {
    columnTitle: {
      fontSize: 32,
      marginBottom: 40,
      [theme.breakpoints.up('xs')]: {
        fontSize: 40,
        marginBottom: 56
      },
      ...getFontScaling(40)
    },
    awardsCount: {
      fontSize: 20,
      display: 'inline-block',
      marginLeft: theme.spacing(0.5),
      verticalAlign: 'super',
      [theme.breakpoints.up('xs')]: {
        fontSize: 20
      },
      ...getFontScaling(20)
    },
    awards: {
      '& > *': {
        opacity: 0
      }
    },
    loadMoreButton: {
      color: theme.colors.black,
      opacity: 0,
      pointerEvents: 'none',
      '&.visible': {
        opacity: 1,
        pointerEvents: 'all'
      }
    },
    icon: {
      transform: 'rotate(90deg)'
    }
  },
  { name: 'AwardColumn' }
)

export default AwardColumn
