import React, { useEffect, useMemo, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import {
  getPageId,
  getPageSlices,
  isPostPage,
  isWorkPage,
  isErrorPage,
  getPageColorByPath
} from '../redux/slices/content'
import Footer from './Footer'
import Header from './Header'
import Slices from './Slices'
import NextPage from './NextPage'
import theme from '../style/theme'
import useSmoothScrollbar, { SmoothScrollContext } from './useSmoothScrollbar'
import ColorContext from './ColorContext'
import ErrorPage from './ErrorPage'
import detectIt from 'detect-it'
import usePageColorTransition from './usePageColorTransition'
import Cursor, { useCursorText } from './Cursor'
import { isPreloading, pagePreloaded } from '../redux/slices/preload'
import Color from 'color'

const Page = () => {
  const containerRef = useRef()
  const pageBackgroundColor = useSelector(getPageColorByPath)
  const errorPage = useSelector(isErrorPage)
  const backgroundColor = pageBackgroundColor
  const foregroundColor = theme.getForegroundColor(backgroundColor)
  const colorContextValue = useMemo(() => (
    {
      foregroundColor,
      backgroundColor,
      borderColor: Color(backgroundColor).isDark() ? theme.colors.borderLight : theme.colors.borderDark
    }), [foregroundColor, backgroundColor])
  const classes = useStyles()

  const { ref: scrollAreaRef, scrollbarRef } = useSmoothScrollbar({ bodyScroll: true })

  const slices = useSelector(getPageSlices)
  const id = useSelector(getPageId)
  const isPost = useSelector(isPostPage)
  const isWork = useSelector(isWorkPage)
  const dispatch = useDispatch()
  const [ready, setReady] = useState(false)
  const preloading = useSelector(isPreloading)

  usePageColorTransition(containerRef)

  useEffect(() => {
    dispatch(pagePreloaded())
  }, [])

  useEffect(() => {
    if (!preloading && !ready) {
      setReady(true)
    }
  }, [preloading, ready])

  useCursorText(containerRef, 'Loading', true, !ready)

  return (
    <SmoothScrollContext.Provider value={scrollbarRef}>
      <ColorContext.Provider value={colorContextValue}>
        <>
          <Header className={classes.header} ready={ready} />
          <div className={classes.container} id='page-container' ref={containerRef}>
            <main className={classes.main} ref={scrollAreaRef} id='page-scroller'>
              <div className={classes.inner}>
                {errorPage && <ErrorPage />}
                <Slices slices={slices} id={id} key={id} />
                {(isPost || isWork) && <NextPage isPostPage={isPost} isWorkPage={isWork} />}
                <Footer />
              </div>
            </main>
          </div>
        </>
        <Cursor />
      </ColorContext.Provider>
    </SmoothScrollContext.Provider>
  )
}

const useStyles = createUseStyles({
  '@global': {
    '#page-scroller .scrollbar-track': {
      backgroundColor: 'transparent'
    },
    '#page-scroller .scrollbar-track-y': {
      width: 4
    },
    '#page-scroller .scrollbar-track-y .scrollbar-thumb': {
      width: 4,
      borderRadius: 0,
      backgroundColor: '#444'
    }
  },
  header: {},
  main: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    overflowX: 'hidden',
    ...(detectIt.primaryInput === 'touch' ? { } : {
      overflowY: 'auto',
      height: '100vh'
    })
  },
  container: {
    overflow: 'hidden'
  },
  inner: {
    width: '100vw'
  }
}, { name: 'Page' })

export default Page
