import { useRef, useEffect } from 'react'
import get from 'lodash/get'
import isFunction from 'lodash/isFunction'

export default (options = { threshold: 1.0 }, callback) => {
  const ref = useRef()
  useEffect(() => {
    if (ref.current) {
      const opt = isFunction(options) ? options() : options
      const trigger = (entries) => {
        const inView = get(entries, [0, 'isIntersecting'])
        callback(entries, inView)
        if (inView && opt.triggerOnce) {
          observer.disconnect()
        }
      }
      const observer = new window.IntersectionObserver(trigger, opt)
      observer.observe(ref.current)
      return () => {
        observer.disconnect()
      }
    }
  }, [callback])
  return ref
}
