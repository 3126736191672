import React, { forwardRef, useCallback, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'clsx'
import isEmpty from 'lodash/isEmpty'
import theme from '../../style/theme'
import RichText from '../RichText'
import Section from '../Section'
import { getFontScaling } from '../../style/textStyles'
import useFadeInAnimation from '../../animations/useFadeInAnimation'

export const postTextStyles = {
  '& h1, h2, h3, h4, h5, h6': {
    marginBottom: theme.spacing(2)
  },
  '& blockquote': {
    fontSize: 32,
    margin: 0,
    marginBottom: theme.spacing(1),
    lineHeight: 1.2,
    letterSpacing: '-1%',
    [theme.breakpoints.up('xs')]: {
      fontSize: 40,
      maxWidth: 496
    },
    ...getFontScaling(40)
  },
  '& p': {
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0
    }
  },
  '& ul': {
    margin: [theme.spacing(1), 0]
  }
}

export const PostTextContainer = forwardRef(({ className, title, text, columns, children, slice, tag = 'div', serializers, layout }, ref) => {
  const classes = useStyles({ layout })
  return (
    <div className={cn(classes.textContainer, className)}>
      <div className={classes.richTextContent}>
        <RichText
          className={className}
          ref={ref}
          content={text}
          tag={tag}
          serializers={serializers}
          columns={columns}
        />
        {children}
      </div>
    </div>
  )
})

const PostTextSlice = forwardRef(({ className, slice, ...props }, ref) => {
  const {
    primary_text: primaryText,
    secondary_text: secondaryText
  } = slice
  const hasSecondaryText = !isEmpty(secondaryText)
  const classes = useStyles({ hasSecondaryText })
  const leftRef = useRef()
  const rightRef = useRef()

  const [inViewRef] = useFadeInAnimation(useCallback(() => hasSecondaryText ? [leftRef.current, rightRef.current] : [rightRef.current], []), null, true, 0)

  return (
    <Section className={classes.section} grid ref={inViewRef}>
      <div className={cn(classes.col, classes.colLeft)} ref={leftRef}>
        <PostTextContainer className={classes.secondaryText} {...secondaryText} />
      </div>
      <div className={cn(classes.col, classes.colRight)} ref={rightRef}>
        <PostTextContainer className={classes.primaryText} {...primaryText} />
      </div>
    </Section>
  )
})

const useStyles = createUseStyles({
  section: {
    rowGap: 0,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(6)
    }
  },
  col: {
    display: 'block',
    gridColumn: 'span 2',
    [theme.breakpoints.up('xs')]: {
      gridColumn: 'span 6',
      gridTemplateColumns: 'repeat(6, 1fr)',
      display: 'grid',
      columnGap: theme.grid.gutter,
      rowGap: '16px',
      marginBottom: 0
    }
  },
  colLeft: {
    order: 2,
    opacity: 0,
    [theme.breakpoints.up('xs')]: {
      order: 'unset',
      '& > div': {
        gridColumn: '1 / span 5'
      }
    }
  },
  colRight: {
    order: 1,
    opacity: 0,
    marginBottom: ({ hasSecondaryText }) => hasSecondaryText ? theme.spacing(4) : 0,
    [theme.breakpoints.up('xs')]: {
      marginBottom: 0,
      order: 'unset',
      '& > div': {
        gridColumn: '2 / span 4'
      }
    }
  },
  richTextContent: {
    '& > div': {
      extend: postTextStyles
    }
  },
  primaryText: {
    '& p, & ul, & ol': {
      extend: theme.textStyles.bodyPlus
    }
  },
  secondaryText: {
    '& p, & ul, & ol': {
      extend: theme.textStyles.body
    }
  }
}, { name: 'PostTextSlice' })

export default PostTextSlice
