import { useEffect, useRef } from 'react'
import useLottieAnimation from './useLottieAnimation'

export default function useReversingLottieAnimation (animationData, forwards, speed = 1, delay = 0) {
  const tweenRef = useRef()
  const targetRef = useLottieAnimation(tweenRef, animationData)

  useEffect(() => {
    if (tweenRef.current) {
      tweenRef.current.delay(delay)
      tweenRef.current.timeScale(speed)
      tweenRef.current.reversed(!forwards).resume()
    }
  }, [forwards, speed])

  return targetRef
}
