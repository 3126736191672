import React from 'react'
import { createUseStyles } from 'react-jss'
import { Helmet } from 'react-helmet-async'
import theme from '../style/theme'
import { useSelector } from 'react-redux'
import { getPageMetaData, getPageHeadTitle } from '../redux/slices/content'
import Page from './Page'
import gsap from 'gsap'

function App () {
  const classes = useStyles() /* eslint-disable-line */
  const meta = useSelector(getPageMetaData)
  const headTitle = useSelector(getPageHeadTitle)

  gsap.config({
    force3D: true,
    nullTargetWarn: process.env.NODE_ENV !== 'production'
  })

  return (
    <>
      <Helmet>
        <title>{headTitle}</title>
        {meta && (
          <>
            <meta name='title' content={meta.metaTitle} />
            {meta.metaDescription && <meta name='description' content={meta.metaDescription} />}
            {meta.metaKeywords && <meta name='keywords' content={meta.metaKeywords} />}
            {meta.ogUrl && <meta property='og:url' content={meta.ogUrl} />}
            <meta property='og:site_name' content={meta.siteName} />
            <meta property='og:title' content={meta.metaTitle} />
            {meta.metaDescription && <meta property='og:description' content={meta.metaDescription} />}
            {meta.metaImage && [
              <meta property='og:image' content={meta.metaImage.url} key={0} />,
              <meta property='og:image:width' content={meta.metaImage.width} key={1} />,
              <meta property='og:image:height' content={meta.metaImage.height} key={2} />
            ]}
            {meta.metaImage && <meta name='twitter:card' content={meta.twitterCardType} />}
            {meta.siteName && <meta name='twitter:site' content={meta.siteName} />}
            <meta name='twitter:title' content={meta.metaTitle} />
            {meta.metaDescription && <meta name='twitter:description' content={meta.metaDescription} />}
            {meta.metaImage && <meta name='twitter:image' content={meta.metaImage.url} />}
            <link rel='canonical' href={meta.canonicalUrl} />
          </>
        )}
      </Helmet>
      <Page />
    </>
  )
}

const useStyles = createUseStyles({
  '@global': {
    ...theme.global
  }
}, { name: 'App' })

export default App
