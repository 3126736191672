import { useCallback, useRef, useEffect } from 'react'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import remove from 'lodash/remove'
import forEach from 'lodash/forEach'

const listeners = []

export default function useBodyScrollLock (locked, options = {}) {
  const state = useRef({ el: null, locked })

  return useCallback((el) => {
    if (el && locked) {
      disableBodyScroll(el, { reserveScrollBarGap: false, ...options })
      forEach(listeners, listener => listener(el, true))
    }

    const { el: prevEl, locked: prevLocked } = state.current
    if (prevEl && prevLocked) {
      enableBodyScroll(prevEl)
      forEach(listeners, listener => listener(prevEl, false))
    }

    state.current.el = el
    state.current.locked = locked
  }, [locked])
}

export const useBodyScrollLockListener = (callback) => {
  useEffect(() => {
    listeners.push(callback)
    return () => {
      remove(listeners, x => x === callback)
    }
  }, [callback])
}
