import { createAction } from '@reduxjs/toolkit'
import makeFetchSlice from '../makeFetchSlice'

export const workContentActionCreator = createAction('work/content', (payload) => ({
  payload,
  meta: { scope: 'work' }
}))

export const serverErrorActionCreator = createAction('work/serverError')

const workSlice = makeFetchSlice('work', workContentActionCreator, serverErrorActionCreator)

export default workSlice.reducer
