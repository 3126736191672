import React, { useRef, useState, useCallback, useEffect } from 'react'
import cn from 'clsx'
import get from 'lodash/get'
import { createUseStyles } from 'react-jss'
import SliceHeader from './SliceHeader'
import theme from '../../style/theme'
import useHlsPlayer from '../../hooks/useHlsPlayer'
import { useInView } from 'react-intersection-observer'
import Section from '../Section'

const FullWidthVideo = ({ className, slice }) => {
  const classes = useStyles()
  const {
    video,
    loop,
    loopFromSeconds = 0,
    loopToSeconds,
    narrowMarginBottom
  } = slice
  const { url, data } = video
  const [playing, setPlaying] = useState(false)
  const playerRef = useRef()
  const [inViewRef, inView] = useInView({ threshold: 0, triggerOnce: false })

  useHlsPlayer(playerRef, url, () => {
    playerRef.current.play()
  })

  const handleVideoClick = useCallback(() => {
    setPlaying(value => !value)
  }, [])

  useEffect(() => {
    if (playerRef.current) {
      if (playing) {
        // We want to continue playing from where we left off
        playerRef.current.currentTime = 0
        playerRef.current.muted = false
      } else {
        playerRef.current.currentTime = loopFromSeconds
        playerRef.current.muted = true
        if (loop) {
          const videoDuration = get(data, 'duration', 5)
          const loopFrom = loopFromSeconds || 0
          const loopTo = loopToSeconds || videoDuration
          const loopCallback = () => {
            if (playerRef.current.currentTime >= loopTo) {
              playerRef.current.currentTime = loopFrom
              playerRef.current.play()
            }
          }
          playerRef.current.addEventListener('timeupdate', loopCallback)
          return () => {
            playerRef.current.removeEventListener('timeupdate', loopCallback)
          }
        }
      }
    }
  }, [playing, loop])

  useEffect(() => {
    if (inView) {
      playerRef.current.play()
    } else {
      playerRef.current.pause()
    }
  }, [inView])

  return (
    <Section
      className={cn(classes.content, className, { narrowMarginBottom })}
      noBottomMargin={narrowMarginBottom}
    >
      <div className={classes.titleWrapper}>
        <SliceHeader slice={slice} />
      </div>
      <div className={classes.videoContainer} ref={inViewRef}>
        <video
          ref={playerRef}
          src={url}
          className={classes.video}
          controls={false}
          autoPlay
          muted
          playsInline
        />
        <div className={classes.videoPoster} onClick={handleVideoClick} />
      </div>
    </Section>
  )
}

const useStyles = createUseStyles(
  {
    content: {
      '&.narrowMarginBottom': {
        marginBottom: theme.spacing(1)
      }
    },
    titleWrapper: {
      padding: [0, theme.spacing(2)]
    },
    videoContainer: {
      position: 'relative'
    },
    video: {
      width: '100%',
      display: 'block'
    },
    videoPoster: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      opacity: 0,
      cursor: 'pointer'
    }
  },
  { name: 'FullWidthVideo' }
)

export default FullWidthVideo
