import React, { useMemo } from 'react'
import uniqBy from 'lodash/uniqBy'
import cn from 'clsx'
import { useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'
import {
  getPostListSlice,
  getWorkListSlice
} from '../../../redux/slices/content'
import theme from '../../../style/theme'
import { expo, quad } from '../../../style/eases'
import { POST_LIST_MODE, WORK_LIST_MODE } from './FilterButton'
import compact from 'lodash/compact'

const FilterDropdown = ({ active, onClose, onOptionSelected, mode, activeFilter }) => {
  const classes = useStyles({ active })
  const workListSlice = useSelector(getWorkListSlice)
  const postListSlice = useSelector(getPostListSlice)

  const filterTags = useMemo(() => {
    if (mode === POST_LIST_MODE) {
      return postListSlice.tags
    } else if (mode === WORK_LIST_MODE) {
      const { tiles } = workListSlice
      const tags = tiles.map(({ article }) => ({ tag: article.tag }))
      const uniqueTags = tags && uniqBy(tags, 'tag.slug')
      return compact(uniqueTags)
    } else {
      return []
    }
  }, [mode, postListSlice, workListSlice])

  return (
    <div className={cn(classes.filterDropdown, { active })}>
      <div className={cn(classes.inner, { active })}>
        <ul className={classes.filterOptions} role='menu'>
          <li className={classes.filterOption} role='menuitem'>
            <button
              className={classes.filterOptionBtn}
              onClick={(e) => onOptionSelected(e, null)}
              aria-label={`Show ${mode === POST_LIST_MODE ? 'articles from all categories' : 'work from all industries'}`}
              disabled={!activeFilter}
            >
              <span className={classes.buttonText}>{`All ${mode === POST_LIST_MODE ? 'Articles' : 'Industries'}`}</span>
            </button>
          </li>
          {filterTags && filterTags.map(({ tag }) => (
            tag ? (
              <li className={classes.filterOption} key={tag.slug} role='menuitem'>
                <button
                  className={classes.filterOptionBtn}
                  onClick={(e) => onOptionSelected(e, tag.slug)}
                  aria-label={`Filter listings by ${tag.title}`}
                  disabled={activeFilter === tag.slug}
                >
                  <span className={classes.buttonText}>{tag.title}</span>
                </button>
              </li>
            ) : null
          ))}
        </ul>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  filterDropdown: {
    position: 'absolute',
    top: -32,
    left: -16,
    right: -16,
    display: 'flex',
    flexDirection: 'column',
    color: theme.colors.text,
    backgroundColor: theme.colors.beige,
    transform: 'translate3d(0, -100%, 0)',
    transition: `transform 1s ${expo.out}, border-color 0.25s ${quad.inOut}`,
    zIndex: 1,
    pointerEvents: 'all',
    overflow: 'hidden',
    border: [1, 'solid', 'transparent'],
    borderTop: 'none',
    '&.error': {
      border: [1, 'solid', theme.colors.error],
      borderTop: 'none'
    },
    '&.active': {
      transform: 'translate3d(0, 0, 0)'
    },
    [theme.breakpoints.down('xs')]: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }
  },
  inner: {
    height: '100%',
    transition: `transform 1s ${expo.out}`,
    transform: 'translate3d(0, 100%, 0)',
    '&.active': {
      transform: 'translate3d(0, 0, 0)'
    },
    padding: [theme.spacing(5), theme.grid.margin, 0],
    [theme.breakpoints.up('xs')]: {
      padding: [theme.spacing(5), theme.grid.gutter, theme.spacing(2)]
    }
  },
  filterOptions: {
    listStyle: 'none',
    margin: 0,
    padding: 0
  },
  filterOption: {
    marginBottom: theme.spacing(0.5),
    '&:last-child': {
      marginBottom: 0
    }
  },
  filterOptionBtn: {
    extend: theme.textStyles.bodySmall,
    '&:hover, &:disabled': {
      opacity: 0.5
    }
  }
}, { name: 'FilterDropdown' })

export default FilterDropdown
