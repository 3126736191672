import { oneLineTrim } from 'common-tags'

export const unquotedBodyFace = 'Suisse Intl'
export const bodyFace = `"${unquotedBodyFace}"`
export const body = `${bodyFace}, "Lucida Sans", Tahoma, sans-serif`
export const bodyFontWeight = 300

export const unquotedHeadingsFace = 'Suisse Intl SemiBold'
export const headingsFace = `"${unquotedHeadingsFace}"`
export const headings = `${headingsFace}, "Lucida Sans", Tahoma, sans-serif`
export const headingsFontWeight = 600

export const unquotedMonoFace = 'Suisse Int\'l Mono'
export const monoFace = `"${unquotedMonoFace}"`
export const mono = `${monoFace}, monospace`
export const monoFontWeight = 300

export const preload = [
  require('./suisseintl-regular-webm.woff2'),
  require('./suisseintl-semibold-webm.woff2'),
  require('./SuisseIntlMono-Regular.woff2')
].filter(item => !item.startsWith('data:'))

export const faces = [{
  fontFamily: bodyFace,
  src: oneLineTrim`
    url('${require('./suisseintl-regular-webm.woff2')}') format('woff2'),
    url('${require('./suisseintl-regular-webm.woff')}') format('woff')`,
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontDisplay: 'swap'
}, {
  fontFamily: monoFace,
  src: oneLineTrim`
    url('${require('./SuisseIntlMono-Regular.woff2')}') format('woff2'),
    url('${require('./SuisseIntlMono-Regular.woff')}') format('woff')`,
  fontWeight: monoFontWeight,
  fontStyle: 'normal',
  fontDisplay: 'swap'
}, {
  fontFamily: headingsFace,
  src: oneLineTrim`
    url('${require('./suisseintl-semibold-webm.woff2')}') format('woff2'),
    url('${require('./suisseintl-semibold-webm.woff')}') format('woff')`,
  fontWeight: headingsFontWeight,
  fontStyle: 'normal',
  fontDisplay: 'swap'
}]
