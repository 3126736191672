import React, { useCallback, useEffect, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { getMenuItems } from '../../redux/slices/content'
import MenuItem from './MenuItem'
import gsap from 'gsap'
import { ReactComponent as CaretIcon } from '../../images/caret.svg'
import theme from '../../style/theme'
import { isMenuOpen, closeMenu, openMenu } from '../../redux/slices/layout'
import { useScrollListener } from '../useSmoothScrollbar'
import cn from 'clsx'
import Color from 'color'

export default ({ color }) => {
  const classes = useStyles()
  const menu = useSelector(getMenuItems).filter(menuItem => !(menuItem.link.reference.slug === 'lab'))
  const menuOpen = useSelector(isMenuOpen)
  const dropDownRef = useRef()
  const navListRef = useRef()
  const buttonRef = useRef()
  const buttonContainerRef = useRef()
  const ref = useRef()
  const caretRef = useRef()
  const dispatch = useDispatch()

  const [atTopOfPage, setOpenAtTop] = useState(true)

  useScrollListener(useCallback(({ y }) => {
    if (y > 0 && atTopOfPage) {
      setOpenAtTop(false)
    }
    if (y === 0 && !atTopOfPage) {
      setOpenAtTop(true)
      dispatch(closeMenu())
    }
  }, [atTopOfPage]))

  const toggleMenu = useCallback(() => {
    dispatch(menuOpen ? closeMenu() : openMenu())
  }, [atTopOfPage, menuOpen])

  useEffect(() => {
    var timeline = gsap.timeline()
    const fgColor = atTopOfPage ? color : theme.colors.black
    timeline.set(dropDownRef.current, { color: fgColor })
    return () => { timeline.kill() }
  }, [color])

  useEffect(() => {
    var timeline = gsap.timeline()
    if (atTopOfPage) {
      const bgColor = Color(theme.colors.background).alpha(0).string()
      const fgColor = color
      timeline.set(dropDownRef.current, { backgroundColor: bgColor, color: fgColor })
      timeline.set(navListRef.current, { paddingTop: 11 })
      timeline.to(buttonContainerRef.current, { opacity: 0, pointerEvents: 'none', duration: 0.25, ease: 'expo.out' })
      timeline.to(navListRef.current, { y: '0%', duration: 1, ease: 'expo.out' }, 0.15)
      timeline.to(dropDownRef.current, { y: '0%', duration: 1, ease: 'expo.out' }, 0.15)
    } else {
      const fgColor = menuOpen ? theme.colors.white : color
      timeline.to(buttonRef.current, { color: fgColor, duration: 0.25 })
      timeline.to(navListRef.current, { y: '100%', duration: 1, ease: 'expo.out' }, 0)
      timeline.to(dropDownRef.current, { y: '-100%', duration: 1, ease: 'expo.out' }, 0)
      timeline.to(buttonContainerRef.current, { opacity: 1, pointerEvents: 'all', duration: 0.5, ease: 'sine.out' }, 0.25)
    }
    timeline.to(caretRef.current, { rotate: menuOpen ? 0 : 180, duration: 0.35, ease: 'sine.inOut' }, 0)
    return () => { timeline.kill() }
  }, [atTopOfPage, menuOpen, color])

  return (
    <div className={classes.container} ref={ref}>
      <div className={classes.dropdown} ref={dropDownRef}>
        <ul role='menu' className={cn(classes.navList, { offset: !atTopOfPage })} ref={navListRef}>
          {menu.map(item => (
            <MenuItem key={item.id} item={item} />
          ))}
        </ul>
      </div>
      <div className={cn(classes.buttonContainer, { hide: atTopOfPage })} ref={buttonContainerRef}>
        <button
          id='menu-button'
          className={classes.button}
          onClick={toggleMenu} ref={buttonRef}
        >
          Menu <CaretIcon ref={caretRef} className={cn(classes.caretIcon, { show: !atTopOfPage })} />
        </button>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  dropdown: {
    position: 'absolute',
    top: 0,
    left: theme.spacing(-1),
    right: theme.spacing(-1),
    margin: 0,
    transform: 'translate(0, -100%)',
    backgroundColor: Color(theme.colors.background).alpha(0).string(),
    color: 'currentColor',
    overflow: 'hidden'
  },
  navList: {
    listStyle: 'none',
    padding: [11, theme.spacing(1), theme.spacing(1)],
    margin: 0,
    transform: 'translate(0, 100%)'
  },
  buttonContainer: {
    opacity: 0
  },
  button: {
    position: 'relative',
    minHeight: 42,
    extend: theme.textStyles.bodySmall,
    display: 'flex',
    alignItems: 'center',
    color: 'currentColor',
    '&:hover': {
      opacity: 0.3
    }
  },
  caretIcon: {
    width: 7,
    height: 5,
    marginLeft: theme.spacing(0.5),
    transform: 'rotate(180deg)',
    transition: 'opacity 0.25s ease-in-out',
    opacity: 0,
    '&.show': {
      opacity: 1
    }
  }
})
