import inDOM from 'dom-helpers/canUseDOM'
import { ActionType } from 'redux-promise-middleware'
import { rehydrated, transitionComplete } from '../../actions'
import { pageContentActionCreator } from '../../slices/content/page'
import { fetchWork } from '../../api'
import { getProjectHeroSlice } from '../../slices/content/selectors'

export default store => next => action => {
  if (!inDOM) {
    return next(action)
  }
  const ret = next(action)
  const projectHeroSlice = getProjectHeroSlice(store.getState())
  if (projectHeroSlice) {
    if (action.type === rehydrated.toString() ||
      action.type === `${pageContentActionCreator}_${ActionType.Fulfilled}` ||
      action.type === transitionComplete.toString()) {
      fetchWork(store.dispatch, store.getState, {
        page: 0,
        limit: 1000
      })
    }
  }
  return ret
}
