import contentMiddleware from './content'
import layoutMiddleware from './layout'
import googleTagsMiddleware from './googleTags'
import scrollMiddleware from './scroll'
import rejectedPromiseMiddleware from './rejectedPromise'
import promiseMiddleware from 'redux-promise-middleware'
import pageTransition from './pageTransition'
import nextPages from './nextPages'
import newsletter from './newsletter'

export default [
  contentMiddleware,
  layoutMiddleware,
  googleTagsMiddleware,
  scrollMiddleware,
  rejectedPromiseMiddleware,
  pageTransition,
  nextPages,
  newsletter,
  promiseMiddleware
]
