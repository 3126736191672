import React, { useRef, useEffect, useCallback, useState } from 'react'
import get from 'lodash/get'
import { createUseStyles } from 'react-jss'
import theme from '../../../style/theme'
import { expo, quad } from '../../../style/eases'
import cn from 'clsx'
import useHlsPlayer from '../../../hooks/useHlsPlayer'
import { useSelector } from 'react-redux'
import { getShowreel } from '../../../redux/slices/content'
import useBodyScrollLock from '../../../hooks/useBodyScrollLock'
import gsap from 'gsap'
import useDebouncedCallback from '../../../hooks/useDebouncedCallback'
import useWindowResize from '../../../hooks/useWindowResize'
import { isCurrentBreakpointMobile, isShowreelDialogOpen } from '../../../redux/slices/layout'
import ShowreelButton from './ShowreelButton'
import ResponsiveImage from '../../ResponsiveImage'
import { ReactComponent as PlayIcon } from '../../../images/play.svg'
import { getVideoAspect } from '../../../helpers/video'

const ShowreelContent = ({ className }) => {
  const showreel = useSelector(getShowreel)
  const video = get(showreel, 'video', null)
  const image = get(showreel, 'image', null)
  const url = get(video, 'url', null)
  const playerRef = useRef()
  const containerRef = useRef()
  const classes = useStyles()
  const open = useSelector(isShowreelDialogOpen)
  const ref = useBodyScrollLock(open)
  const isMobile = useSelector(isCurrentBreakpointMobile)
  const [playing, setPlaying] = useState()

  useHlsPlayer(playerRef, url)

  useEffect(() => {
    var timeoutId
    if (open) {
      if (!isMobile) {
        timeoutId = setTimeout(() => playerRef.current.play(), 1250)
      }
    } else {
      timeoutId = setTimeout(() => playerRef.current.pause(), 750)
    }
    return () => {
      clearTimeout(timeoutId)
    }
  }, [open, isMobile])

  const resizeVideo = useCallback(() => {
    const aspect = getVideoAspect()
    const { width, height } = containerRef.current.getBoundingClientRect()
    const innerHeight = height - (theme.spacing(6) * 2) - (isMobile ? 44 : 0) // 44 is for the close button at the bottom
    const innerWidth = width - (theme.grid.margin * 2)
    var videoHeight = innerWidth * aspect
    var videoWidth = innerWidth
    if (videoHeight > innerHeight) {
      videoHeight = innerHeight
      videoWidth = innerHeight * (1 / aspect)
    }
    gsap.set(playerRef.current, { width: videoWidth, height: videoHeight })
  }, [video, isMobile])

  useEffect(resizeVideo, [resizeVideo])
  useWindowResize(useDebouncedCallback(resizeVideo, 250, [resizeVideo]))

  useEffect(() => {
    const onPlaying = () => { setPlaying(true) }
    const onPause = () => { setPlaying(false) }
    playerRef.current.addEventListener('play', onPlaying)
    playerRef.current.addEventListener('pause', onPause)
  }, [])

  const onPlayClick = useCallback(() => {
    playerRef.current.play()
    setPlaying(true)
  }, [])

  // if (!video) return null

  return (
    <section className={cn(classes.section, { open }, className)} ref={containerRef}>
      <div className={cn(classes.inner, { open })} ref={ref}>
        <div className={classes.videoContainer}>
          {image && <ResponsiveImage {...image} className={classes.posterImage} />}
          <video
            ref={playerRef}
            src={url}
            className={classes.video}
            controls={false}
            loop
          />
          {!playing && isMobile && (
            <button onClick={onPlayClick} className={classes.playButton} aria-label='Play showreel'><PlayIcon className={classes.playIcon} /></button>
          )}
        </div>
        <ShowreelButton text={isMobile ? 'Back To Menu' : 'Close Showreel'} mobile={isMobile} className={classes.button} role={null} />
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    position: 'fixed',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    color: theme.colors.white,
    backgroundColor: theme.colors.black,
    transform: `translate3d(0, calc(-100% - ${theme.grid.gutter}px), 0)`,
    transition: `transform 1.5s ${expo.out}, border-color 0.25s ${quad.inOut}`,
    overflow: 'hidden',
    border: [1, 'solid', 'transparent'],
    borderTop: 'none',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
    '&.open': {
      pointerEvents: 'all',
      transform: 'translate3d(0, 0, 0)'
    }
  },
  inner: {
    height: '100%',
    transition: `transform 1.5s ${expo.out}`,
    transform: `translate3d(0, calc(100% + ${theme.grid.gutter}px), 0)`,
    '&.open': {
      transform: 'translate3d(0, 0, 0)'
    },
    padding: [theme.spacing(6), theme.grid.margin],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    [theme.breakpoints.up('xs')]: {
      alignItems: 'center'
    }
  },
  video: {
    width: '100%',
    display: 'block',
    position: 'relative'
  },
  playButton: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'block',
    width: '100%'
  },
  playIcon: {
    position: 'absolute',
    width: 64,
    height: 64,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  button: {
    extend: theme.textStyles.body,
    marginTop: theme.spacing(7),
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
      display: 'none'
    }
  },
  videoContainer: {
    position: 'relative'
  },
  posterImage: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  }
}, { name: 'ShowreelModal' })

export default ShowreelContent
