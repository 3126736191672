import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import {
  getPageTitle, getPageSummary
} from '../redux/slices/content'
import theme from '../style/theme'
import ColorContext from './ColorContext'
import MultiLineTicker from './MultiLineTicker'
import { useSliceColor } from './useSliceColor'

const ErrorPage = () => {
  const { foregroundColor, backgroundColor } = useContext(ColorContext)
  const classes = useStyles({ foregroundColor, backgroundColor })
  const title = useSelector(getPageTitle)
  const summary = useSelector(getPageSummary)

  const ref = useSliceColor('404', theme.colors.black, true)

  return (
    <div className={classes.container} ref={ref}>
      <h1 className='srOnly'>{title}</h1>
      <MultiLineTicker title={title} copy={summary} />
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    overflow: 'hidden',
    flexDirection: 'column',
    color: theme.colors.white,
    backgroundColor: theme.colors.black,
    minHeight: '100vh',
    [theme.breakpoints.up('lg')]: {
      minHeight: 960
    }
  }
}, { name: 'ErrorPage' })

export default ErrorPage
