import * as breakpoints from './breakpoints'
import * as fonts from './fonts'
import * as grid from './grid'
import * as textStyles from './textStyles'
import Color from 'color'

export { vw } from './vw'
export { span } from './span'

const colors = {
  black: '#000000',
  text: '#000000',
  white: '#FFFFFF',
  accent: '#FF3F2C',
  beige: '#F6F4F2',
  background: '#F2F2F2',
  backgroundDark: 'rgb(26, 26, 26)',
  borderDark: 'rgba(34,34,34,0.1)',
  borderLight: 'rgba(255,255,255,0.1) ',
  offWhite: '#F6F4F2',
  offWhiteText: '#666666',
  success: '#1C8B0A',
  error: '#DC1C1C',
  schemes: {
    light: {
      background: '#F2F2F2',
      foreground: '#000000'
    },
    dark: {
      background: '#000000',
      foreground: '#F2F2F2'
    },
    default: {
      background: '#F2F2F2',
      foreground: '#000000'
    }
  }
}

const headingsSelector = 'h1,h2,h3,h4,h5,h6'
const globalStyles = {
  '@font-face': fonts.faces,
  '*, *::before, *::after': {
    boxSizing: 'border-box',
    '-webkit-tap-highlight-color': 'transparent'
  },
  ':root': {
    '--scrollbar-width': '0px'
  },
  html: {
    lineHeight: 1.6
  },
  body: {
    // ...grid.generateAllGridStyles(),
    backgroundColor: colors.background,
    color: colors.text,
    height: '100vh',
    width: '100%',
    '-webkitFontSmoothing': 'antialiased',
    '-mozOsxFontSmoothing': 'grayscale',
    extend: textStyles.baseBodyStyles,
    textRendering: 'optimizeLegibility',
    ...textStyles.getFontScaling(16, 1.12, 1.5)
  },
  [headingsSelector]: {
    fontFamily: fonts.headings,
    fontWeight: fonts.headingsFontWeight,
    lineHeight: 0.8,
    margin: [0, 0, '0.6em']
  },
  '.headings': {
    fontFamily: fonts.headings,
    fontWeight: fonts.headingsFontWeight,
    letterSpacing: '-0.01em'
  },
  '.mono': {
    fontFamily: fonts.mono,
    fontWeight: fonts.monoFontWeight
  },
  '.styled-link': {
    position: 'relative',
    '&:before': {
      content: ' ',
      backgroundColor: 'black',
      position: 'absolute',
      width: '100%',
      height: '100%'
    }
  },
  h1: {
    extend: textStyles.h1
  },
  h2: {
    extend: textStyles.h2
  },
  h3: {
    extend: textStyles.h3
  },
  h4: {
    extend: textStyles.h4
  },
  h5: {
    extend: textStyles.h5
  },
  p: {
    margin: [0, 0, '1.4em']
  },
  button: {
    userSelect: 'none',
    cursor: 'pointer',
    appearance: 'none',
    outline: 'none',
    border: 'none',
    background: 'none',
    padding: 0,
    margin: 0
  },
  '.scrollbar-track': {
    zIndex: '120 !important'
  },
  '.srOnly': {
    border: '0 !important',
    clip: 'rect(1px, 1px, 1px, 1px) !important',
    clipPath: 'inset(50%) !important',
    height: '1px !important',
    margin: '-1px !important',
    overflow: 'hidden !important',
    padding: '0 !important',
    position: 'absolute !important',
    width: '1px !important',
    whiteSpace: 'nowrap !important'
  },
  a: {
    color: 'currentColor',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  // For modern browsers
  // 1. The space content is one way to avoid an Opera bug when the
  //    contenteditable attribute is included anywhere else in the document.
  //    Otherwise it causes space to appear at the top and bottom of elements
  //    that are clearfixed.
  // 2. The use of `table` rather than `block` is only necessary if using
  //    `:before` to contain the top-margins of child elements.
  '.clearFix': {
    '&::before,&::after': {
      content: '" "', // 1
      display: 'table' // 2
    },
    '&::after': {
      clear: 'both'
    }
  },
  '::-moz-selection': {
    color: colors.black,
    background: '#C9FD0E'
  },
  '::selection': {
    color: colors.black,
    background: '#C9FD0E'
  }
}

export default {
  headingsSelector,
  global: globalStyles,
  breakpoints,
  grid,
  colors,
  fonts,
  textStyles,
  zIndex: {
    // Keep all zIndexes here in one place so it’s easy to see what order things are in,
    menu: 103,
    dialog: 105,
    header: 106,
    acknowledgementOverlay: 107,
    curtain: 109,
    cursor: 110
  },
  spacing: (count = 1) => {
    return 16 * count
  },
  scrollbarWidthVar: 'var(--scrollbar-width)',
  gutter: (prop = 'margin', func = x => x) => ({
    [prop]: func(grid.gutter)
  }),
  getMargin: (bp = 'min') => grid.config[bp].margin,
  getGutter: (bp = 'min') => grid.config[bp].gutter,
  section: {
    marginBottom: 64,
    [breakpoints.up('md')]: {
      marginBottom: 200
    },
    [breakpoints.up('xxxl')]: {
      marginBottom: 240
    }
  },
  getForegroundColor: backgroundColor => {
    const { light, dark } = colors.schemes

    if (!backgroundColor) {
      return light.foreground
    }

    if (backgroundColor.toLowerCase() === colors.accent.toLowerCase()) {
      return dark.foreground
    }

    const c = Color(backgroundColor)
    return c.isLight() ? light.foreground : dark.foreground
  }
}
