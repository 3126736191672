import composeReduxMiddlewares from '../../../helpers/composeReduxMiddlewares'
import raceMiddleware from './race'
import serverErrorMiddleware from './serverError'
import postsMiddleware from './posts'
import workMiddleware from './work'

export default composeReduxMiddlewares(
  raceMiddleware,
  serverErrorMiddleware,
  postsMiddleware,
  workMiddleware
)
