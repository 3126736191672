import React, { useCallback, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'clsx'
import { useSelector } from 'react-redux'
import { format } from 'date-fns-tz'
import { getPostDate, getProjectTag } from '../../redux/slices/content'
import ResponsiveImage from '../ResponsiveImage'
import Section from '../Section'
import { span } from '../../style/span'
import theme from '../../style/theme'
import SplitWordsAnimation from '../SplitWordsAnimation'
import useFadeInAnimation from '../../animations/useFadeInAnimation'
import composeRefs from '../../helpers/composeRefs'
import round from '../../helpers/round'
import { getFontScaling } from '../../style/textStyles'
import BackgroundVideo from './BackgroundVideo'
import { useSliceColor } from '../useSliceColor'

// const LAYOUT_IMAGE_PORTRAIT = 'image-portrait'
const LAYOUT_IMAGE_LANDSCAPE = 'image-landscape'

const BlogHero = ({ slice, tag = 'div', serializers }) => {
  const {
    id,
    image,
    hero_layout: heroLayout,
    background_color: backgroundColor,
    title,
    subtitle,
    author,
    desktop_video: desktopVideo,
    mobile_video: mobileVideo
  } = slice

  const foregroundColor = theme.getForegroundColor(backgroundColor.hex)
  const ref = useSliceColor(id, backgroundColor.hex, true)
  const subtitleRef = useRef()
  const classes = useStyles({ foregroundColor, backgroundColor: backgroundColor.hex, layout: heroLayout })
  const postDate = useSelector(getPostDate)
  const postTag = useSelector(getProjectTag)

  const [inViewRef] = useFadeInAnimation(useCallback(() => subtitleRef.current.children, []), { delay: 0.25 }, true)

  const isLandscape = heroLayout === LAYOUT_IMAGE_LANDSCAPE
  const heroTitle = (
    <SplitWordsAnimation threshold={0}>
      <h1 className={classes.heroTitle}>{title}</h1>
    </SplitWordsAnimation>
  )

  return (
    <Section className={classes.section} fullWidth ref={composeRefs(inViewRef, ref)}>
      <Section tag='div' className={classes.grid} grid noBottomMargin>
        {isLandscape && heroTitle}
        <div className={cn(classes.textCol, classes.col)}>
          <div className={classes.richTextWrapper}>
            {!isLandscape && heroTitle}
            <div className={classes.subtitleContainer} ref={subtitleRef}>
              <p className={classes.subtitle}>{subtitle}</p>
              {postDate && postTag && (
                <div className={classes.postMeta}>
                  {`${format(new Date(postDate), 'yyyy.MM.dd')} - ${postTag.title} - ${author}`}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={cn(classes.imageCol, classes.col)}>
          <ResponsiveImage {...image} className={cn(classes.image, { portrait: !isLandscape, landscape: isLandscape })} aspect={343 / 478} preload />
          {desktopVideo?.url && (
            <BackgroundVideo
              desktopVideo={desktopVideo}
              mobileVideo={mobileVideo}
            />
          )}
        </div>
      </Section>
    </Section>
  )
}

const useStyles = createUseStyles({
  section: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(1),
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    color: ({ foregroundColor }) => foregroundColor,
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      paddingTop: ({ layout }) => layout === LAYOUT_IMAGE_LANDSCAPE ? theme.spacing(6) : theme.spacing(5)
    }
  },
  grid: {
    rowGap: 0,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      gridTemplateRows: ({ layout }) => layout === LAYOUT_IMAGE_LANDSCAPE ? 'min-content' : '1fr'
    }
  },
  imageCol: {
    order: 2,
    gridColumn: 'span 2',
    position: 'relative',
    [theme.breakpoints.up('xs')]: {
      gridColumn: 'span 12'
    },
    [theme.breakpoints.up('md')]: {
      order: 'unset',
      marginBottom: 0,
      gridColumn: ({ layout }) => layout === LAYOUT_IMAGE_LANDSCAPE ? '5 / span 8' : '8 / span 5'
    }
  },
  image: {
    height: '100%',
    '&.portrait': {
      [theme.breakpoints.up('md')]: {
        '& picture:before': {
          paddingTop: `${round(100 / (574 / 800))}%`
        }
      }
    },
    '&.landscape': {
      [theme.breakpoints.up('md')]: {
        '& picture:before': {
          paddingTop: `${round(100 / (930 / 615))}%`
        }
      }
    }
  },
  textCol: {
    order: 1,
    gridColumn: 'span 2',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      gridColumn: 'span 12'
    },
    [theme.breakpoints.up('md')]: {
      order: 'unset',
      marginBottom: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      gridColumn: ({ layout }) => layout === LAYOUT_IMAGE_LANDSCAPE ? '1 / span 4' : '1 / span 7'
    }
  },
  heroTitle: {
    gridColumn: ({ layout }) => layout === LAYOUT_IMAGE_LANDSCAPE ? 'span 2' : 'none',
    fontSize: 40,
    extend: theme.textStyles.h1,
    textTransform: 'none',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      fontSize: 64,
      gridColumn: ({ layout }) => layout === LAYOUT_IMAGE_LANDSCAPE ? '1 / span 8' : 'none'
    },
    ...getFontScaling(64)
  },
  subtitleContainer: {
    [theme.breakpoints.up('md')]: {
      maxWidth: span(3, 'md')
    }
  },
  subtitle: {
    opacity: 0,
    maxWidth: span(1.5),
    extend: theme.textStyles.body,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      maxWidth: 'none'
    }
  },
  postMeta: {
    extend: theme.textStyles.caption,
    opacity: 0
  }
}, { name: 'BlogHero' })

export default BlogHero
