import inDOM from 'dom-helpers/canUseDOM'
import { ActionType } from 'redux-promise-middleware'
import { updateScroll } from 'redux-first-router'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import detectIt from 'detect-it'

import afterFrame from '../../helpers/afterFrame'
import { setPageYOffset } from '../../components/useSmoothScrollbar'
import { pageContentActionCreator as pageAction } from '../slices/content/page'
import { isPreloading, pagePreloaded, slicePreloaded } from '../slices/preload'
import { transitionContentDelayed, transitionComplete } from '../actions'
import { getStateStorage } from './PauseableSessionStorage'

let pendingContentFulfilledAction
let transitioningOut = false
let transitionedOut = true
let firstLoad = true

const updateScrollPosition = () => {
  if (detectIt.primaryInput !== 'touch') {
    const storage = getStateStorage()
    const scrollState = storage.read(window.history.state || {}, null)
    const pageScrollerElement = document.getElementById('page-scroller')
    if (scrollState) {
      const [, y] = scrollState
      setPageYOffset(pageScrollerElement, y)
    } else {
      setPageYOffset(pageScrollerElement, 0)
    }
    ScrollTrigger.refresh(true)
  } else {
    updateScroll()
    ScrollTrigger.refresh(true)
  }
}

const transitionOut = store => {
  getStateStorage().pause()

  transitioningOut = true
  const main = document.getElementById('page-container')
  const curtain = document.getElementById('page-curtain')
  const tl = gsap.timeline({
    onComplete: () => {
      firstLoad = false
    }
  })

  tl.fromTo(
    curtain,
    { y: '100%' },
    {
      y: '0%',
      duration: 1,
      ease: 'quart.in',
      onComplete: () => {
        transitioningOut = false
        transitionedOut = true
        store.dispatch(transitionComplete())
      }
    },
    0
  )
  tl.to(
    main.children,
    {
      y: -window.innerHeight * 0.25,
      duration: 1,
      ease: 'quart.in'
    },
    0
  )
}

export const transitionIn = () => {
  if (!transitionedOut) return

  // Before we transition in, we need to update the scroll position
  updateScrollPosition()

  const main = document.getElementById('page-container')
  const curtain = document.getElementById('page-curtain')
  const tl = gsap.timeline()
  transitionedOut = false

  tl.to(
    curtain,
    {
      y: '-105%',
      ease: 'expo.out',
      duration: 1
    },
    0.2
  )
  tl.fromTo(
    main.children,
    { y: window.innerHeight * 0.25 },
    {
      y: '0%',
      ease: 'expo.out',
      duration: firstLoad ? 0 : 1,
      clearProps: 'transform',
      onComplete: () => {
        getStateStorage().resume()
      }
    },
    0.2
  )
}

export default store => next => action => {
  if (inDOM) {
    if (action.type === pageAction.toString()) {
      transitionOut(store)
    }

    if (action.type === `${pageAction}_${ActionType.Fulfilled}`) {
      // If we are no longer transition out then we just need to pass the action on
      if (transitioningOut) {
        pendingContentFulfilledAction = action
        return next(transitionContentDelayed())
      } else {
        const result = next(action)
        if (!isPreloading(store.getState()) && transitionedOut) {
          afterFrame(() => transitionIn(store))
        }
        return result
      }
    }

    if (action.type === transitionComplete.toString()) {
      if (pendingContentFulfilledAction) {
        const result = next(pendingContentFulfilledAction)
        if (!isPreloading(store.getState()) && transitionedOut) {
          afterFrame(() => transitionIn(store))
        }
        pendingContentFulfilledAction = null
        return result
      }
    }

    if (
      action.type === pagePreloaded.toString() ||
      action.type === slicePreloaded.toString()
    ) {
      const result2 = next(action)
      if (!isPreloading(store.getState()) && transitionedOut) {
        // We need to take some extra time on the initial load to load the menu in first
        transitionIn(store)
      }
      return result2
    }
  }
  return next(action)
}
