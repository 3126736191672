import React, { useRef, useEffect, useMemo } from 'react'
import cn from 'clsx'
import get from 'lodash/get'
import SplitText from 'gsap/SplitText'
import gsap from 'gsap'
import isEmpty from 'lodash/isEmpty'
import CustomEase from 'gsap/CustomEase'
import Link from '../Link'
import { resolveLink } from '../../helpers/resolveLink'

gsap.registerPlugin(SplitText)
gsap.registerPlugin(CustomEase)

export default ({ className, message, selected, link }) => {
  const resolvedLink = useMemo(() => resolveLink(get(link, [0])), [link])
  const ref = useRef()
  const locals = useRef({}).current

  useEffect(() => {
    locals.split = new SplitText(ref.current, { type: 'lines,words' })
    if (isEmpty(locals.split.words)) return
    gsap.set(locals.split.lines, { overflow: 'hidden', padding: '0 0 0.12em 0', marginBottom: '-0.12em', whiteSpace: 'nowrap' })
    gsap.set(locals.split.words, { yPercent: 110, visibility: 'hidden', opacity: 1 })
    gsap.set(ref.current, { visibility: 'visible' })

    locals.timeline = gsap.timeline()
    locals.timeline.to(
      locals.split.words,
      { ease: CustomEase.create('title', '.4, 0, .12, 1'), yPercent: 0, stagger: 0.08, duration: 1, opacity: 1, visibility: 'visible' }
    )
    locals.timeline.pause()
    return () => {
      locals.split.revert()
    }
  }, [])

  useEffect(() => {
    if (selected) {
      locals.timeline.play()
    } else {
      locals.timeline.reverse()
    }
  }, [selected])

  return (
    <Link link={resolvedLink} className={cn(className, { selected })} nonLinkTag='div' ref={ref} title={message}>{message}</Link>
  )
}
