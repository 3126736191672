import get from 'lodash/get'
import map from 'lodash/map'
import last from 'lodash/last'
import find from 'lodash/find'
import keyBy from 'lodash/keyBy'
import uniqBy from 'lodash/uniqBy'
import defaults from 'lodash/defaults'
import { createSelector } from 'reselect'
import { getCurrentRoutePath, isPreviewRoute, getCurrentQueryString } from '../location'
import { resolveInternalLink } from '../../../helpers/resolveLink'
import isEmpty from 'lodash/isEmpty'

export const getState = state => state

export const isContentBusy = state => !!(get(state, ['content', 'page', 'busy']) || get(state, ['content', 'global', 'busy']))
export const getContentError = state => state.content.page.error || state.content.global.error
export const getPageContent = state => get(state, ['content', 'page', 'content'])
export const isContentError404 = state => get(getContentError(state), ['status']) === 404

export const getGlobalContent = state => get(state, ['content', 'global', 'content'])
export const getFooter = state => get(getGlobalContent(state), ['footer'])
export const getShowreel = state => get(getGlobalContent(state), ['showreel'])
export const isContentReadyOrError = state => !isContentBusy(state) && !!(
  (getPageContent(state) && getGlobalContent(state)) || getContentError(state)
)

export const getSiteTitle = state => get(getGlobalContent(state), ['title'])
export const getSocialLinks = state => get(getGlobalContent(state), ['socialLinks'])
export const getBlogHome = state => get(getGlobalContent(state), ['blog_home'])
export const getWorkHome = state => get(getGlobalContent(state), ['portfolio_home'])
export const getAcknowledgement = state => get(getGlobalContent(state), ['acknowledgement'])
export const getMobileMenuCopy = state => get(getGlobalContent(state), ['mobile_menu_copy'])

export const getPageId = state => get(getPageContent(state), ['id'])
export const getPageSlug = state => get(getPageContent(state), ['slug'])
export const getPageParent = state => get(getPageContent(state), ['parent'])
export const getPageType = state => get(getPageContent(state), ['type'])
export const getPageTitle = state => get(getPageContent(state), ['title'])
export const getPageSummary = state => get(getPageContent(state), ['summary'])
export const getProjectIndex = state => get(getPageContent(state), ['index'])
export const getProjectTag = state => get(getPageContent(state), ['tag'])
export const getPostDate = state => get(getPageContent(state), ['post_date'])
export const getProjectClient = state => get(getPageContent(state), ['client'])
export const getPageBackgroundColor = state => get(getPageContent(state), ['color', 'hex'])
export const isErrorPage = state => getPageType(state) === 'errorPage'

export const getPosts = state => get(state, ['content', 'posts', 'content', 'pages'])
export const getPostsTotal = state => get(state, ['content', 'posts', 'content', 'total'])
export const getPostsIndex = state => get(state, ['content', 'posts', 'content', 'page'])
export const isPostsBusy = state => !!(get(state, ['content', 'posts', 'busy']))
export const isPostsLoaded = state => get(state, ['content', 'posts', 'content'])

export const getWork = state => get(state, ['content', 'work', 'content', 'pages'])
export const getWorkTotal = state => get(state, ['content', 'work', 'content', 'total'])
export const getWorkIndex = state => get(state, ['content', 'work', 'content', 'page'])
export const isWorkBusy = state => !!(get(state, ['content', 'work', 'busy']))
export const isWorkLoaded = state => get(state, ['content', 'work', 'content'])

export const getPostsQueryStringArguments = (state) => {
  const qs = getCurrentQueryString(state)
  const filter = get(qs, ['filter'])
  return { filter }
}

export const getActiveFilterTag = (state) => {
  const qs = getCurrentQueryString(state)
  const filter = get(qs, ['filter'])
  return filter
}

export const getActivePostTags = createSelector(
  getPosts,
  (posts) => {
    const tags = posts && posts.map(post => post.tag)
    return uniqBy(tags, 'slug')
  }
)

export const getPageMetaData = createSelector(
  getGlobalContent,
  getPageContent,
  getCurrentRoutePath,
  (global, page, path) => {
    const defaultCanonicalUrl = `${process.env.REACT_APP_CANONICAL_URL_BASE}${path}`
    return defaults(
      {},
      get(page, ['seo'], {}),
      {
        // these are the defaults for fields that aren't set above
        metaTitle: get(page, ['title']),
        siteName: get(global, ['title']),
        ogUrl: defaultCanonicalUrl,
        canonicalUrl: defaultCanonicalUrl
      }
    )
  }
)

export const isHomepage = createSelector(
  getPageSlug,
  (slug) => slug === 'home'
)

export const isPostPage = createSelector(
  getPageParent,
  (parent) => {
    return parent && parent.slug === 'wire'
  }
)

export const isWorkPage = createSelector(
  getPageParent,
  (parent) => {
    return parent && parent.slug === 'work'
  }
)

export const getPageHeadTitle = createSelector(
  getGlobalContent,
  getPageMetaData,
  getPageTitle,
  (global, meta, title = get(meta, ['title'])) => {
    const globalTitle = get(global, ['title'])
    if (!title) {
      return globalTitle
    }
    if (!globalTitle) {
      return title
    }
    return `${title} – ${globalTitle}`
  }
)

export const getPageSlices = createSelector(
  getPageContent,
  (page) => {
    return (page && page.slices) ? map(page.slices, (slice, index) => ({
      id: `${page.id}-${index}`,
      ...slice
    })) : undefined
  }
)

export const getPreviewMode = state => get(state, ['content', 'preview'])

export const isPreviewMode = createSelector(
  isPreviewRoute,
  getPreviewMode,
  (previewRoute, previewMode) => previewRoute || previewMode
)

export const getMenuItems = createSelector(
  getGlobalContent,
  getCurrentRoutePath,
  (global, path) => {
    const menu = get(global, ['menu'])
    if (!menu) return []
    return menu.map(item => ({
      ...item,
      selected: get(item, ['reference', 'slug']) === last(path.split('/'))
    }))
  }
)

export const getPostListSlice = createSelector(
  getPageSlices,
  (slices) => find(slices, x => x.type === 'post_list_slice')
)

export const getWorkListSlice = createSelector(
  getPageSlices,
  (slices) => find(slices, x => x.type === 'work_list_slice')
)

export const getProjectHeroSlice = createSelector(
  getPageSlices,
  (slices) => find(slices, x => x.type === 'project_hero')
)

export const getPageColorsByPath = createSelector(
  getGlobalContent,
  (global) => {
    return keyBy(global.page_colors.map(x => ({
      path: resolveInternalLink(x).url,
      color: x.color,
      hero_color: x.hero_color
    })), x => x.path)
  }
)

export const getPageColorByPath = createSelector(
  getPageColorsByPath,
  getPageContent,
  (colors, page) => {
    const path = resolveInternalLink(page).url
    return get(colors, [path, 'color'], '#FFFFFF')
  }
)

export const getNextPageColorByPath = createSelector(
  getPageColorsByPath,
  getCurrentRoutePath,
  (colors, path) => {
    return get(colors, [path, 'hero_color']) || get(colors, [path, 'color'], '#FFFFFF')
  }
)

export const isRehydrated = state => state.content.rehydrated

export const getTickerMessages = state => get(getGlobalContent(state), ['ticker'])
export const hasTickerMessages = state => !isEmpty(get(getGlobalContent(state), ['ticker']))
