import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'clsx'
import isEmpty from 'lodash/isEmpty'
import RichText from '../../RichText'
import theme from '../../../style/theme'
import BlockImages from './BlockImages'
import { getFontScaling } from '../../../style/textStyles'

const PADDING_LOOSE = 'padding-loose'

const Block = (props) => {
  const {
    images,
    text_block: textBlock,
    mono_style: monoStyle,
    reverse_columns: reverseColumns,
    padding
  } = props
  const classes = useStyles({ reverseColumns, monoStyle, padding })
  const fullWidth = isEmpty(textBlock)
  return (
    <div className={classes.block}>
      <div className={cn(classes.cols, { reverseColumns })}>
        {textBlock && (
          <div className={cn(classes.textCol, classes.col)}>
            <div className={cn(classes.textWrapper, { monoStyle })}>
              <RichText content={textBlock.text} />
            </div>
          </div>
        )}
        {images && (
          <div className={cn(classes.imageCol, classes.col, { fullWidth })}>
            <BlockImages images={images} isFullWidth={fullWidth} />
          </div>
        )}
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  block: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('xs')]: {
      paddingTop: ({ padding }) => padding === PADDING_LOOSE ? '10vw' : '5vw',
      paddingBottom: ({ padding }) => padding === PADDING_LOOSE ? '10vw' : '5vw'
    }
  },
  cols: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      flexDirection: ({ reverseColumns }) => reverseColumns ? 'row-reverse' : 'row'
    }
  },
  col: {
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      minHeight: '25vh',
      paddingTop: ({ padding }) => padding === PADDING_LOOSE ? '10vh' : '5vh',
      paddingBottom: ({ padding }) => padding === PADDING_LOOSE ? '10vh' : '5vh'
    },
    [theme.breakpoints.up('xs')]: {
      '&:first-child': {
        marginRight: ({ reverseColumns }) => reverseColumns ? 0 : theme.grid.gutter / 2,
        marginLeft: ({ reverseColumns }) => reverseColumns ? theme.grid.gutter / 2 : 0
      },
      '&:last-child': {
        marginRight: ({ reverseColumns }) => reverseColumns ? theme.grid.gutter / 2 : 0,
        marginLeft: ({ reverseColumns }) => reverseColumns ? 0 : theme.grid.gutter / 2
      }
    }
  },
  imageCol: {
    [theme.breakpoints.up('xs')]: {
      width: '50%',
      '&.fullWidth': {
        width: '100%',
        paddingLeft: 0,
        paddingRight: 0
      }
    }
  },
  textCol: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('xs')]: {
      width: '50%',
      display: 'grid',
      columnGap: theme.grid.gutter,
      rowGap: '16px',
      gridTemplateColumns: 'repeat(6, 2fr)'
    }
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 5,
    [theme.breakpoints.up('xs')]: {
      gridColumn: '1 / span 6'
    },
    [theme.breakpoints.up('md')]: {
      gridColumn: '2 / span 3'
    },
    '& p': {
      marginBottom: 20,
      [theme.breakpoints.up('xs')]: {
        marginBottom: 20
      }
    },
    '&.monoStyle': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.up('xs')]: {
        gridColumn: '2 / span 4'
      },
      [theme.breakpoints.up('md')]: {
        gridColumn: '3 / span 2'
      },
      '& p': {
        extend: theme.textStyles.caption
      }
    },
    '&:not(.monoStyle)': {
      '& p': {
        fontFamily: theme.fonts.body,
        fontWeight: theme.fonts.bodyFontWeight,
        fontSize: 14,
        lineHeight: 1.6,
        ...getFontScaling(14)
      }
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '80%'
    }
  }
}, { name: 'Block' })

export default Block
