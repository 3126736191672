import gsap from 'gsap'
import { useEffect } from 'react'

export default (ref, handler, enabled = true, ignoredQuery) => {
  useEffect(() => {
    if (enabled) {
      const listener = event => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }

        if (
          ignoredQuery &&
          !!gsap.utils.toArray(
            document.querySelectorAll(ignoredQuery)
          )?.find(x => x.contains(event.target))
        ) return

        handler(event)
      }

      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)

      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    }
  }, [handler, enabled])
}
