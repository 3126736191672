import React from 'react'
import cn from 'clsx'
import { createUseStyles } from 'react-jss'

import ResponsiveImage from '../ResponsiveImage'
import theme from '../../style/theme'
import Section from '../Section'
import BackgroundVideo from './BackgroundVideo'

const PostImageSlice = ({ className, slice }) => {
  const { images, narrowMarginBottom } = slice
  const numImages = images.length
  const classes = useStyles({ numImages })

  return (
    <Section className={cn(classes.section, { narrowMarginBottom })} grid>
      {images &&
        images.map((image, i) => (
          <div key={i} className={classes.galleryImage}>
            {image.type === 'video' && image.desktop_video?.url && (
              <BackgroundVideo
                desktopVideo={image.desktop_video}
                mobileVideo={image.mobile_video}
                className={classes.video}
              />
            )}
            <ResponsiveImage {...(image?.image || image)} />
          </div>
        ))}
    </Section>
  )
}

const useStyles = createUseStyles(
  {
    section: {
      marginBottom: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(6)
      },
      '&.narrowMarginBottom': {
        marginBottom: theme.spacing(1)
      }
    },
    video: {
      zIndex: 3
    },
    galleryImage: {
      position: 'relative',
      gridColumn: 'span 2',
      [theme.breakpoints.up('xs')]: {
        gridColumn: ({ numImages }) => `span ${12 / numImages}`
      }
    }
  },
  { name: 'PostImageSlice' }
)

export default PostImageSlice
