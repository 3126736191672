import React, { useCallback, useEffect, useRef, useMemo, memo } from 'react'
import gsap from 'gsap'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { getWork, getPosts, isPostPage } from '../../../redux/slices/content'
import MenuTile from './MenuTile'
import theme from '../../../style/theme'
import { closeCaseStudies, isCaseStudiesOpen } from '../../../redux/slices/layout'
import Slider from '../../Slider'
import flatten from 'lodash/flatten'
import map from 'lodash/map'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import cn from 'clsx'
import range from 'lodash/range'
import PostTile from '../../Slices/ArticleTiles/Tile'

export const sliderOptions = {
  cellAlign: 'left',
  pageDots: false,
  contain: true,
  freeScroll: true,
  prevNextButtons: false,
  autoPlay: false
}

const useGetArticles = () => {
  const postPage = useSelector(isPostPage)

  const work = useSelector(getWork)
  const posts = useSelector(getPosts)

  return postPage ? posts : work || []
}

const SliderMemo = memo(({ children }) => <Slider options={sliderOptions}>{children}</Slider>)
const SliderPlaceholderMemo = memo(({ children, className }) => <div className={className}>{children}</div>)

const BrowseMenu = ({ className, menuButtonRef }) => {
  const open = useSelector(isCaseStudiesOpen)

  const postPage = useSelector(isPostPage)

  const articles = useGetArticles()
  const dispatch = useDispatch()

  const classes = useStyles({ open })
  const menuRef = useRef()
  const sliderRef = useRef()
  const innerRef = useRef()

  useOnClickOutside(menuRef, useCallback((e) => {
    if (menuButtonRef && e.target !== menuButtonRef.current) {
      if (open) {
        dispatch(closeCaseStudies())
      }
    }
  }, [open]), open)

  useEffect(() => {
    if (menuRef.current) {
      var timeline = gsap.timeline()
      const animationProps = { ease: open ? 'expo.out' : 'sine.out', duration: open ? 1.6 : 0.5 }
      timeline.to(menuRef.current, { y: open ? '0%' : '-100%', ...animationProps })
      timeline.to(innerRef.current, { y: open ? '0%' : '100%', ...animationProps }, 0)
      if (open && !postPage) {
        const images = sliderRef.current.querySelectorAll('.article-image img')
        const content = sliderRef.current.querySelectorAll('.article-content')
        const meta = sliderRef.current.querySelectorAll('.article-meta')
        if (images) {
          timeline.set(images, { y: '-105%' }, 0)
          timeline.to(images, { y: '0%', ease: 'expo.out', duration: 2, stagger: 0.1 }, 0.25)
        }

        const contentChildren = flatten(map(content, c => c.children))
        if (contentChildren) {
          timeline.set(contentChildren, { opacity: 0, y: '-100%' }, 0)
          timeline.to(contentChildren, { opacity: 1, y: '0%', ease: 'expo.out', duration: 2, stagger: 0.1 }, 0.8)
        }

        const metaChildren = flatten(map(meta, m => m.children))
        if (metaChildren) {
          timeline.set(metaChildren, { y: '100%' }, 0)
          timeline.to(metaChildren, { y: '0%', ease: 'expo.out', duration: 2, stagger: 0.1 }, 1.2)
        }
      }

      return () => {
        timeline.kill()
      }
    }
  }, [open])

  const placeholderItems = useMemo(() => (range(5).map((x, index) => ({ image: {}, id: index, placeholder: true }))), [])
  const items = articles || placeholderItems

  const SliderContainer = articles ? SliderMemo : SliderPlaceholderMemo

  const slides = useMemo(() => {
    return items && items.map((article) => postPage
      ? (
        <PostTile
          key={article.id}
          className={classes.postArticle}
          tight
          {...article}
        />
      )
      : (
        <MenuTile
          key={article.id}
          article={article}
          className={classes.article}
          classNames={{
            image: 'article-image',
            content: 'article-content',
            meta: 'article-meta'
          }}
        />
      )
    )
  }, [items])

  return (
    <div className={cn(classes.browseMenu, className, { light: postPage })} ref={menuRef} key={postPage ? 'post' : 'work'}>
      <div className={classes.inner} ref={innerRef}>
        <div className={classes.sliderWrapper} ref={sliderRef}>
          <SliderContainer className={classes.sliderPlaceholder}>
            {slides}
          </SliderContainer>
        </div>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  browseMenu: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    color: theme.colors.white,
    backgroundColor: theme.colors.backgroundDark,
    pointerEvents: 'all',
    overflow: 'hidden',
    transform: 'translateY(-100%)',
    '&.light': {
      color: theme.colors.black,
      backgroundColor: theme.colors.background
    }
  },
  inner: {
    transform: 'translateY(100%)'
  },
  sliderWrapper: {
    paddingTop: 60,
    margin: [0, theme.spacing(1), theme.spacing(2)]
  },
  slider: {
    display: 'flex',
    outline: 'none',
    alignItems: 'flex-end'
  },
  article: {
    margin: [0, theme.spacing(0.5)],
    width: `calc(25% - ${theme.spacing(1)}px)`,
    flexShrink: 0
  },
  postArticle: {
    margin: [0, theme.spacing(0.5)],
    width: `calc(33% - ${theme.spacing(1)}px)`,
    flexShrink: 0
  },
  sliderPlaceholder: {
    whiteSpace: 'nowrap',
    display: 'flex',
    overflow: 'visible'
  }
}, { name: 'BrowseMenu' })

export default BrowseMenu
