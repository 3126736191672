import React, {
  useCallback,
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef
} from 'react'
import cn from 'clsx'
import { createUseStyles } from 'react-jss'
import gsap from 'gsap'
import { useDispatch, useSelector } from 'react-redux'
import {
  getPostListSlice,
  getWorkListSlice,
  getProjectHeroSlice,
  isPostPage
} from '../../redux/slices/content'
import {
  isMenuOpen,
  isOverlayOpen,
  openMenu,
  closeMenu,
  isSignUpDialogOpen,
  isShowreelDialogOpen,
  isCurrentBreakpointAtLeast,
  isCaseStudiesOpen,
  getInViewSliceColorTransparency,
  getInViewSliceForegroundColor,
  getInViewSliceColor
} from '../../redux/slices/layout'
import theme from '../../style/theme'
import { expo } from '../../style/eases'
import Logo from './Logo'
import SignupButton from './NewsletterSignup/SignupButton'
import ShowreelButton from './Showreel/ShowreelButton'
import FilterButton from './Filter/FilterButton'
import BrowseButton from './Browse/BrowseButton'
import BrowseMenu from './Browse/BrowseMenu'
import Section from '../Section'
import ColorContext from '../ColorContext'
import MenuButton from './MenuButton'
import { transitionProps } from '../usePageColorTransition'
import Color from 'color'
import MobileMenu from '../MobileMenu'
import ShowreelModal from './Showreel/ShowreelModal'
import { getFontScaling } from '../../style/textStyles'
import Curtain from './Curtain'
import toArray from 'lodash/toArray'
// import { transitionIn } from '../../redux/middlewares/pageTransition'

const isCurrentBreakpointXS = state => !isCurrentBreakpointAtLeast(state, 'xs')

const Header = props => {
  const { className, ready } = props
  const postListSlice = useSelector(getPostListSlice)
  const workListSlice = useSelector(getWorkListSlice)
  const projectHeroSlice = useSelector(getProjectHeroSlice)
  const postPage = useSelector(isPostPage)
  const menuOpen = useSelector(isMenuOpen)
  const overlayOpen = useSelector(isOverlayOpen)
  const isMobile = useSelector(isCurrentBreakpointXS)
  const classes = useStyles({ menuOpen: menuOpen && isMobile })
  const isMobileSignupDialogOpen = useSelector(isSignUpDialogOpen).mobile
  const showreelDialogOpen = useSelector(isShowreelDialogOpen)
  const caseStudiesOpen = useSelector(isCaseStudiesOpen)
  const menuButtonRef = useRef()
  const navRef = useRef()
  const showreelButtonRef = useRef()
  const curtainRef = useRef()
  const logoRef = useRef()
  const ref = useRef()
  const [animatedIn, setAnimatedIn] = useState()

  const { backgroundColor } = useContext(ColorContext)

  const sliceColor = useSelector(getInViewSliceColor)
  const sliceForegroundColor = useSelector(getInViewSliceForegroundColor)
  const sliceColorTransparency = useSelector(getInViewSliceColorTransparency)

  const dispatch = useDispatch()

  const toggleMenu = useCallback(() => {
    dispatch(menuOpen ? closeMenu() : openMenu())
  }, [dispatch, menuOpen])

  useEffect(() => {
    if (ready) {
      const timeline = gsap.timeline()

      const menuList = menuButtonRef.current.querySelector('ul')
      const button = menuButtonRef.current.querySelector('button')
      const menuItems = [button, ...toArray(menuList.children)]
      timeline.set(menuItems, { opacity: 0, x: -20 })
      timeline.set(menuButtonRef.current, { opacity: 1 })

      timeline.to(menuItems, {
        opacity: 1,
        x: 0,
        stagger: 0.1,
        duration: isMobile ? 0 : 0.5,
        ease: 'sine.out'
      })
      timeline.to(
        navRef.current,
        { opacity: 1, duration: isMobile ? 0 : 1, ease: 'sine.out' },
        '-=0.1'
      )
      // timeline.add(() => {
      //   transitionIn()
      // }, 0)
      timeline.add(() => {
        setAnimatedIn(true)
      }, 0)
      timeline.set(curtainRef.current, { zIndex: 4 }, '+=1')
    }
  }, [ready])

  const caseStudiesBackgroundColor = postPage
    ? theme.colors.black
    : theme.colors.background
  const caseStudiesForegroundColor = postPage
    ? theme.colors.white
    : theme.colors.black

  const { color, bg } = useMemo(() => {
    const { colors, getForegroundColor } = theme

    const foregroundColorAgainstBackgroundColor =
      sliceForegroundColor ?? getForegroundColor(backgroundColor)
    const transparentBg = Color(backgroundColor || colors.white)
      .alpha(0)
      .string()

    function getFgColor () {
      if (isMobileSignupDialogOpen) return colors.black
      if (overlayOpen) return colors.white
      if (caseStudiesOpen) return caseStudiesBackgroundColor
      if (!animatedIn) return colors.white
      if ((menuOpen && isMobile) || showreelDialogOpen) return colors.white
      return foregroundColorAgainstBackgroundColor || colors.black
    }

    function getBgColor () {
      if (isMobileSignupDialogOpen) return colors.white
      if (overlayOpen) return 'transparent'
      if (caseStudiesOpen) return caseStudiesForegroundColor
      if (menuOpen && isMobile) return colors.black
      if (sliceColorTransparency || !animatedIn) return transparentBg
      return sliceColor || backgroundColor || colors.white
    }

    return { color: getFgColor(), bg: getBgColor() }
  }, [
    menuOpen,
    animatedIn,
    isMobile,
    overlayOpen,
    showreelDialogOpen,
    isMobileSignupDialogOpen,
    sliceColor,
    sliceColorTransparency,
    sliceForegroundColor,
    backgroundColor,
    caseStudiesOpen,
    caseStudiesForegroundColor,
    caseStudiesBackgroundColor
  ])

  useEffect(() => {
    gsap.set(ref.current, {
      color: color,
      '--foreground': color,
      backgroundColor: bg
    })
  }, [color, bg])

  useEffect(() => {
    gsap.to(ref.current, {
      color: color,
      '--foreground': color,
      backgroundColor: bg,
      ...transitionProps
    })
  }, [color, bg])

  return (
    <header
      className={cn(classes.header, className, { overlayOpen })}
      ref={ref}
    >
      <MobileMenu />
      <Section tag='div' className={classes.headerWrapper} noBottomMargin grid>
        <Curtain ref={curtainRef} />
        {!isMobile && <BrowseMenu className={classes.browseMenu} />}
        <ShowreelModal
          menuButtonRef={showreelButtonRef}
          className={classes.showreelModal}
        />
        <div className={classes.headerLeft} ref={logoRef}>
          <Logo />
        </div>
        <nav className={classes.headerMiddle} ref={navRef}>
          <ul className={classes.middleNav}>
            <li className={classes.signupButtonListItem}>
              <SignupButton className={classes.signupButton} />
            </li>
            <li>
              {(projectHeroSlice || postPage) && (
                <BrowseButton
                  className={classes.browseButton}
                  ref={showreelButtonRef}
                />
              )}
              {(postListSlice || workListSlice) && (
                <FilterButton className={classes.signupButton} />
              )}
              {!(
                projectHeroSlice ||
                postListSlice ||
                workListSlice ||
                postPage
              ) && <ShowreelButton className={classes.signupButton} />}
            </li>
          </ul>
        </nav>
        <nav className={classes.nav} ref={menuButtonRef}>
          <MenuButton color={color} />
        </nav>
        <button
          className={classes.menuButton}
          onClick={toggleMenu}
          aria-haspopup
          aria-expanded={menuOpen}
          aria-label='Toggle navigation menu'
        >
          {menuOpen ? 'Close' : 'Menu'}
        </button>
      </Section>
    </header>
  )
}

const useStyles = createUseStyles(
  {
    header: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      zIndex: theme.zIndex.header,
      color: theme.colors.white,
      '&.overlayOpen': {
        transition: 'background-color 0.5s ease-out'
      }
    },
    headerWrapper: {
      alignItems: 'start',
      zIndex: 1, // put this in front of the dialogs
      position: 'relative'
    },
    headerLeft: {
      flexGrow: 0,
      flexShrink: 0,
      display: 'flex',
      alignItems: 'center',
      gridColumn: '1',
      // zIndex: 4,
      [theme.breakpoints.up('xs')]: {
        gridColumn: '1 / span 3'
      }
    },
    headerMiddle: {
      flexGrow: 1,
      flexShrink: 1,
      fontSize: 12,
      display: 'none',
      opacity: 0,
      [theme.breakpoints.up('xs')]: {
        gridColumn: '4 / span 6',
        display: 'block'
      },
      ...getFontScaling(12)
    },
    middleNav: {
      alignItems: 'stretch',
      padding: 0,
      margin: 0,
      listStyle: 'none',
      minHeight: 42,
      display: 'grid',
      columnGap: theme.grid.gutter,
      gridTemplateColumns: '1fr 1fr',
      '& > li': {
        display: 'flex',
        alignItems: 'center',
        position: 'relative'
      },
      '& a': {
        // transition: `opacity 0.15s ${expo.inOut}`,
        minHeight: 42,
        display: 'inline-flex',
        alignItems: 'center'
      },
      '& a:hover': {
        opacity: 0.3
      }
    },
    item: {
      extend: theme.textStyles.bodySmall,
      textDecoration: 'none'
    },
    nav: {
      display: 'none',
      position: 'relative',
      alignItems: 'center',
      height: '100%',
      opacity: 0,
      // zIndex: 4,
      [theme.breakpoints.up('xs')]: {
        gridColumn: '12',
        display: 'flex'
      }
    },
    menuButton: {
      ...theme.textStyles.body,
      justifySelf: 'end',
      minHeight: 42,
      position: 'relative',
      cursor: 'pointer',
      appearance: 'none',
      outline: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      // color: 'currentColor',
      color: 'var(--foreground)',
      gridColumn: '2',
      [theme.breakpoints.up('xs')]: {
        display: 'none'
      }
    },
    signupButton: {
      transition: `opacity 0.15s ${expo.inOut}`,
      height: 42,
      // zIndex: 4,
      '&:hover': {
        opacity: 0.5
      }
    },
    signupButtonListItem: {
      transition: 'opacity 0.45s ease-in-out',
      '&.hide': {
        transition: 'opacity 0.05s ease-in-out',
        opacity: 0
      }
    },
    browseMenu: {
      margin: [0, theme.spacing(-1)],
      width: `calc(100% + ${theme.spacing(2)}px)`,
      zIndex: 1
    },
    showreelModal: {
      // zIndex: 4
    }
  },
  { name: 'Header' }
)

export default Header
