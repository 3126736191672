import { useEffect } from 'react'

const useWindowResize = (callback, runOnMount = false) => {
  useEffect(() => {
    window.addEventListener('resize', callback, { passive: true })
    if (runOnMount) {
      callback()
    }
    return () => {
      window.removeEventListener('resize', callback)
    }
  }, [callback])
}

export default useWindowResize
