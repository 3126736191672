import React, { forwardRef, useCallback, useRef } from 'react'
import SliceHeader from './SliceHeader'
import ArticleGridTile from './ArticleGridTile'
import Section from '../Section'
import composeRefs from '../../helpers/composeRefs'
import { createUseStyles } from 'react-jss'
import useFadeInAnimation from '../../animations/useFadeInAnimation'
import theme from '../../style/theme'
import StyledButton from '../StyledButton'
import get from 'lodash/get'
import cn from 'clsx'

const aspects = ['10:11', '10:9', '10:6']

const ArticleGrid = forwardRef(({ className, slice }, ref) => {
  const classes = useStyles()
  const { articles, singleLine } = slice
  const containerRef = useRef()
  const [inVewRef] = useFadeInAnimation(useCallback(() => containerRef.current.children, []), null, true, 0, false)

  const link = get(slice, ['button', '0'], slice.button)

  return (
    <>
      <SliceHeader slice={slice} fullWidth={false} classNames={{ link: classes.desktopLink }} />
      <Section grid ref={composeRefs(inVewRef, containerRef)} className={classes.grid}>
        {articles.map(({ article }, i) => (
          <ArticleGridTile
            key={article.id}
            article={article}
            className={cn(classes.article, singleLine && classes.singleLineGrid)}
            span={6}
            largeSpan={singleLine ? 4 : 6}
            aspect={aspects[i % aspects.length]}
          />
        ))}
        <div className={classes.mobileLink}>
          <StyledButton link={link} />
        </div>
      </Section>
    </>
  )
})

const useStyles = createUseStyles({
  article: {
    opacity: 0
  },
  grid: {
    rowGap: '60px'
  },
  singleLineGrid: {
    display: 'none',
    '&:nth-child(1)': {
      display: 'block'
    },
    '&:nth-child(2)': {
      display: 'block'
    },
    [theme.breakpoints.up('lg')]: {
      '&:nth-child(3)': {
        display: 'block'
      }
    }
  },
  mobileLink: {
    display: 'block',
    gridColumn: 'span 2',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      display: 'none'
    }
  },
  desktopLink: {
    display: 'none',
    [theme.breakpoints.up('xs')]: {
      display: 'flex'
    }
  }
})

export default ArticleGrid
