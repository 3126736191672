import { createSlice } from '@reduxjs/toolkit'

const nextPagesSlice = createSlice({
  name: 'nextPages',
  initialState: {
    pages: {}
  },
  reducers: {
    setNextPages: (state, action) => {
      state.pages = {
        ...state.pages,
        ...action.payload
      }
    }
  }
})

export const {
  setNextPages
} = nextPagesSlice.actions

export const getNextPages = state => state.nextPages.pages

export default nextPagesSlice.reducer
