import React from 'react'
import { createUseStyles } from 'react-jss'
import { getFontScaling } from '../../style/textStyles'
import theme from '../../style/theme'
import Section from '../Section'
import SplitWordsAnimation from '../SplitWordsAnimation'

export default ({ slice }) => {
  const { title, subtitle } = slice
  const classes = useStyles()
  return (
    <Section className={classes.section}>
      <SplitWordsAnimation delay={0.5}>
        <h1 className={classes.title}>{title}</h1>
      </SplitWordsAnimation>
      <SplitWordsAnimation delay={1}>
        <h2 className={classes.subtitle}>{subtitle}</h2>
      </SplitWordsAnimation>
    </Section>
  )
}

const useStyles = createUseStyles({
  section: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: [theme.spacing(5), 0, theme.spacing(8)],
    [theme.breakpoints.up('xs')]: {
      padding: [theme.spacing(10), 0, theme.spacing(4)]
    }
  },
  title: {
    maxWidth: '8em',
    fontSize: 40,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('xs')]: {
      fontSize: 64
    },
    ...getFontScaling(64)
  },
  subtitle: {
    maxWidth: '8em',
    fontSize: 40,
    marginBottom: 0,
    lineHeight: 1,
    [theme.breakpoints.up('xs')]: {
      fontSize: 64
    },
    ...getFontScaling(64)
  }

})
