import { useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import theme from '../style/theme'
import { setInViewSliceColor, clearInViewSliceColor } from '../redux/slices/layout'
import get from 'lodash/get'
import last from 'lodash/last'
import useWindowResize from '../hooks/useWindowResize'
import useDebouncedCallback from '../hooks/useDebouncedCallback'

const HEADER_HEIGHT = 42

export const useSliceColor = (id, isDarkOrImageOrColor = true, transparent = false, enabled = true) => {
  const ref = useRef()
  const observerRef = useRef()
  const setRef = useRef(false)
  const dispatch = useDispatch()

  const resize = () => {
    if (enabled) {
      const imageForegroundColor = get(isDarkOrImageOrColor, ['palette', 'dominant', 'title'])
      const color = (typeof isDarkOrImageOrColor) === 'string'
        ? isDarkOrImageOrColor
        : isDarkOrImageOrColor ? theme.colors.white : theme.colors.black
      const foregroundColor = imageForegroundColor || theme.getForegroundColor(color)
      if (ref.current) {
        if (observerRef.current) {
          observerRef.current.unobserve(ref.current)
          observerRef.current.disconnect()
          observerRef.current = null
        }

        const trigger = (entries) => {
          var entry = last(entries)
          if (entry && entry.isIntersecting) {
            // We need to pause the color change otherwise we get a flicker
            setRef.current = true
            dispatch(setInViewSliceColor({ id, color, transparent, foregroundColor }))
          } else {
            if (setRef.current) {
              dispatch(clearInViewSliceColor({ id }))
              setRef.current = false
            }
          }
        }

        const options = {
          root: null,
          threshold: 0,
          rootMargin: `${-HEADER_HEIGHT + 1}px 0px -${window.innerHeight - HEADER_HEIGHT}px 0px`
        }

        observerRef.current = new window.IntersectionObserver(trigger, options)
        observerRef.current.observe(ref.current)
      }
    }
  }

  useEffect(() => {
    if (enabled) {
      resize()
      return () => {
        if (observerRef.current) {
          observerRef.current.unobserve(ref.current)
          observerRef.current.disconnect()
          observerRef.current = null
        }
      }
    }
  }, [enabled, isDarkOrImageOrColor])

  useWindowResize(useDebouncedCallback(resize, 200, [enabled]))

  return ref
}
