import React, { useCallback, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'clsx'
import theme from '../../../style/theme'
import Section from '../../Section'
import Clock from '../../Clock'
import Ticker from '../../Ticker'
import ProjectVideo from './ProjectVideo'
import SplitWordsAnimation from '../../SplitWordsAnimation'
import composeRefs from '../../../helpers/composeRefs'
import toArray from 'lodash/toArray'
// import { useSelector } from 'react-redux'
// import { isPreloading } from '../../../redux/slices/preload'
import useFadeInAnimation from '../../../animations/useFadeInAnimation'
import { getFontScaling } from '../../../style/textStyles'
import { useSliceColor } from '../../useSliceColor'

const HomeHero = ({ className, slice }) => {
  const { title, subtitle, project } = slice
  const classes = useStyles()
  const ref = useRef()
  const preloading = false

  const [inViewRef] = useFadeInAnimation(useCallback(() => {
    return toArray(ref.current.children).slice(1)
  }, []), { delay: 0.5, stagger: 0.15 }, !preloading)

  var sliceColorRef = useSliceColor(slice.id + 'video', '#000', false)

  return (
    <Section className={cn(classes.section, className)} fullWidth>
      <Section grid noBottomMargin tag='div' className={classes.titleContainer} ref={composeRefs(ref, inViewRef)}>
        <SplitWordsAnimation ready={!preloading} delay={0} duration={0.6} stagger={0.1}>
          <h1 className={classes.title}>{title}</h1>
        </SplitWordsAnimation>
        <Clock className={classes.clock} />
        <p className={classes.subtitle}>{subtitle}</p>
        <Ticker className={classes.ticker} />
      </Section>
      <ProjectVideo project={project} ref={sliceColorRef} />
    </Section>
  )
}

const useStyles = createUseStyles({
  section: {
    position: 'relative'
  },
  titleContainer: {
    gridTemplateRows: '3fr 2fr min-content',
    paddingBottom: theme.spacing(1),
    height: `calc(100vh - ${theme.spacing(2)}px)`,
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      maxHeight: 960
    }
  },
  clock: {
    fontSize: 10,
    opacity: 0,
    margin: 0,
    display: 'none',
    gridRow: 3,
    height: '1em',
    alignSelf: 'end',
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      gridColumn: '1 / span 3'
    },
    ...getFontScaling(10)
  },
  title: {
    margin: 0,
    textTransform: 'none',
    lineHeight: 1,
    gridRow: 1,
    gridColumn: '1 / span 2',
    maxWidth: '7em',
    alignSelf: 'end',
    [theme.breakpoints.up('xs')]: {
      gridRow: 1,
      gridColumn: '1 / span 5'
    }
  },
  subtitle: {
    margin: 0,
    gridRow: 2,
    gridColumn: '1 / span 2',
    fontSize: 12,
    opacity: 0,
    alignSelf: 'start',
    marginTop: theme.spacing(4),
    maxWidth: 200,
    [theme.breakpoints.up('xs')]: {
      maxWidth: 350,
      marginTop: theme.spacing(4.5),
      fontSize: 14,
      gridColumn: '1 / span 12'
    },
    ...getFontScaling(14)
  },
  ticker: {
    margin: 0,
    gridRow: 3,
    gridColumn: '1 / span 2',
    opacity: 0,
    maxWidth: 230,
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      gridColumn: '7 / span 4'
    }

  }
}, { name: 'HomeHero' })

export default HomeHero
