import React, { useCallback, useRef, forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import Flickity from 'react-flickity-component'
import gsap from 'gsap'
import composeRefs from '../helpers/composeRefs'

const preventVerticalScrolling = (flickity) => {
  // This will prevent the vertical scrolling when scrolling in flickity
  flickity.on('dragStart', () => { document.ontouchmove = e => e.preventDefault() })
  flickity.on('dragEnd', () => { document.ontouchmove = () => true })
}

export const defaultOptions = {
  cellAlign: 'left',
  pageDots: false,
  contain: true,
  freeScroll: false,
  prevNextButtons: false,
  autoPlay: false
}

export default forwardRef(({ className, enabled = true, children, flickityRef, options, reloadOnUpdate = true, ...rest }, ref) => {
  const classes = useStyles()
  const containerRef = useRef()
  const setFlickityRef = useCallback((flickity) => {
    if (flickity) {
      gsap.set(flickity.element, { display: 'block', overflow: 'visible' })
      // Flickity consumes the mouse move events so we need to create a customer event so the custom mouse
      // cursor continues to follow the mouse cursor
      flickity.on('dragMove', (event, pointer, moveVector) => {
        const dragMoveEvent = new window.CustomEvent('flickity-dragMove', { detail: event })
        window.dispatchEvent(dragMoveEvent)
      })
      preventVerticalScrolling(flickity)
    }
    if (flickityRef) flickityRef.current = flickity
  }, [])

  const composedRefs = composeRefs(ref, containerRef)

  if (enabled) {
    return (
      <div ref={composedRefs} className={className}>
        <Flickity
          options={options || defaultOptions}
          disableImagesLoaded
          reloadOnUpdate={reloadOnUpdate}
          static
          className={classes.flickity}
          flickityRef={setFlickityRef}
          {...rest}
        >
          {children}
        </Flickity>
      </div>
    )
  }
  return children
})

const useStyles = createUseStyles({
  flickity: {
    outline: 'none',
    whiteSpace: 'nowrap',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexShrink: 0
    },
    '& .flickity-slider': {
      height: '100%'
    }
  }
}, { name: 'Slider' })
