import React, { useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import {
  getPosts,
  getPostsQueryStringArguments,
  isPostsBusy
} from '../../redux/slices/content'
import Section from '../Section'
import SliceHeader from './SliceHeader'
import Tile from './ArticleTiles/Tile'
import theme from '../../style/theme'
import isEmpty from 'lodash/isEmpty'

const PostListSlice = ({ className, slice }) => {
  const classes = useStyles()
  const gridRef = useRef()

  const { initialPosts } = slice ?? {}
  const posts = useSelector(getPosts) || []

  const busy = useSelector(isPostsBusy)
  const params = useSelector(getPostsQueryStringArguments)

  const renderedPosts = isEmpty(posts) ? initialPosts : posts
  const isBusy = params?.filter !== undefined && busy

  return (
    <Section className={classes.section} fullWidth>
      <Section tag='div' noBottomMargin>
        <SliceHeader slice={slice} />
      </Section>
      <Section tag='div' noBottomMargin grid ref={gridRef} className={classes.grid}>
        {renderedPosts?.map(post => (
          <div key={post.id} className={classes.postContainer}>
            <Tile className={classes.post} {...post} placeholder={isBusy} />
          </div>
        ))}
      </Section>
    </Section>
  )
}

const useStyles = createUseStyles({
  section: {
    position: 'relative',
    overflow: 'hidden',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: -theme.spacing(1),
      left: 0,
      right: 0,
      height: 1,
      backgroundColor: theme.colors.borderDark
    }
  },
  grid: {
    // minHeight: '80vh',
    rowGap: `${theme.spacing(2)}px`,
    [theme.breakpoints.up('xs')]: {
      rowGap: `${theme.spacing(4)}px`
    }
  },
  postContainer: {
    // opacity: 0,
    minHeight: '100%',
    display: 'flex',
    width: '100%',
    gridColumn: 'span 2',
    [theme.breakpoints.up('xs')]: {
      gridColumn: 'span 6'
    },
    [theme.breakpoints.up('md')]: {
      gridColumn: 'span 4'
    },
    [theme.breakpoints.up('xxl')]: {
      gridColumn: 'span 3'
    }
  },
  post: {
    width: '100%'
  }
}, { name: 'PostListSlice' })

export default PostListSlice
