import React from 'react'

export default ({ className }) => {
  return (
    <svg className={className} viewBox='0 0 9 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path stroke='currentColor' d='M1.217 7.646L8.289.575' />
      <path stroke='currentColor' d='M8.425 7.646L1.354.576' />
    </svg>
  )
}
