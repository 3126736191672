import React, { useRef, useEffect, useContext } from 'react'
import detectIt from 'detect-it'
import Scrollbar from 'smooth-scrollbar'
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll'
import { getStateStorage } from '../redux/middlewares/PauseableSessionStorage'
import remove from 'lodash/remove'
import forEach from 'lodash/forEach'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import useWindowResize from '../hooks/useWindowResize'
import useDebouncedCallback from '../hooks/useDebouncedCallback'

export const SmoothScrollContext = React.createContext(null)

export const useScrollListener = (callback) => {
  const context = useContext(SmoothScrollContext)
  useEffect(() => {
    context.current.scrollCallbacks.push(callback)
    return () => {
      remove(context.current.scrollCallbacks, x => x === callback)
    }
  }, [callback])
}

export const useScrollReadyListener = (callback) => {
  const context = useContext(SmoothScrollContext)
  useEffect(() => {
    if (context.current.ready) {
      callback(context.current)
    } else {
      context.current.scrollReadyCallbacks.push(callback)
      return () => {
        remove(context.current.scrollReadyCallbacks, x => x === callback)
      }
    }
  }, [callback])
}

export const setPageYOffset = (element, y) => {
  if (detectIt.primaryInput !== 'touch' && Scrollbar.has(element)) {
    const scrollbar = Scrollbar.get(element)
    scrollbar.scrollTo(0, y, 0)
  } else {
    window.scrollTo(0, y)
  }
}

gsap.registerPlugin(ScrollTrigger)

const setScrollerProxy = (element, scrollbarRef) => {
  ScrollTrigger.scrollerProxy(element, {
    scrollTop (value) {
      if (arguments.length) {
        scrollbarRef.current.setPageYOffset(value)
      }
      return scrollbarRef.current.getPageYOffset()
    }
  })
}

export default (options = {}) => {
  const { bodyScroll } = options
  const ref = useRef()
  const scrollbarRef = useRef({
    scrollElement: null,
    scrollCallbacks: [],
    scrollVelocityCallbacks: [],
    scrollReadyCallbacks: [],
    ready: false,
    data: { lastOffset: 0, velocity: 0 }
  })

  scrollbarRef.current.getPageYOffset = () => {
    if (scrollbarRef.current && scrollbarRef.current.scrollbar) {
      return scrollbarRef.current.scrollbar.offset.y
    }
    if (!bodyScroll && scrollbarRef.current.scrollElement) {
      return scrollbarRef.current.scrollElement.scrollTop
    }
    return (window.pageYOffset || document.documentElement.scrollTop)
  }

  scrollbarRef.current.setPageYOffset = (y, duration = 0) => {
    if (scrollbarRef.current && scrollbarRef.current.scrollbar) {
      scrollbarRef.current.scrollbar.scrollTo(0, y, duration)
    } else {
      if (!bodyScroll && scrollbarRef.current.scrollElement) {
        scrollbarRef.current.scrollElement.scrollTop = y
      } else {
        window.scrollTo(0, y)
      }
    }
  }

  useEffect(() => {
    if (detectIt.primaryInput === 'touch') {
      scrollbarRef.current.scrollElement = bodyScroll ? document.body : ref.current
      scrollbarRef.current.ready = true
      forEach(scrollbarRef.current.scrollReadyCallbacks, cb => cb(scrollbarRef.current))
      return
    }

    if (ref.current) {
      setScrollerProxy(ref.current, scrollbarRef)

      Scrollbar.use(OverscrollPlugin)
      var scrollbar = Scrollbar.init(ref.current, { damping: 0.45, renderByPixels: true, alwaysShowTracks: false })
      scrollbar.updatePluginOptions('overscroll', { effect: 'bounce', maxOverscroll: 40 })

      scrollbar.addListener(({ offset }) => {
        // Updates the scroll history
        const storage = getStateStorage()
        storage.save(window.history.state || {}, null, [offset.x, offset.y])

        // Updates the scroll position for the scroll triggers
        ScrollTrigger.update()

        // Updates the scroll position for the scroll triggers
        forEach(scrollbarRef.current.scrollCallbacks, cb => cb(offset))
      })

      ScrollTrigger.refresh(true)

      scrollbarRef.current.scrollbar = scrollbar
      scrollbarRef.current.scrollElement = ref.current
      scrollbarRef.current.ready = true

      forEach(scrollbarRef.current.scrollReadyCallbacks, cb => cb(scrollbarRef.current))

      return () => {
        scrollbar.destroy()
        scrollbar = null
      }
    }
  }, [])

  useWindowResize(useDebouncedCallback(() => {
    if (detectIt.primaryInput !== 'touch') {
      ScrollTrigger.refresh(false)
    }
  }, 200, []))

  return {
    ref,
    scrollbarRef
  }
}
