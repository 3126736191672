import { useRef, useEffect } from 'react'
import gsap from 'gsap'

export default ({ open }) => {
  const ref = useRef()
  const locals = useRef({}).current

  useEffect(() => {
    if (ref.current) {
      if (locals.timeline) locals.timeline.kill()
      locals.timeline = gsap.timeline()
      if (open) {
        locals.timeline.set(ref.current, { zIndex: 2 })
      } else {
        locals.timeline.set(ref.current, { zIndex: 1, delay: 1 })
      }
    }
  }, [open])

  return ref
}
