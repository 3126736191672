import React, { useMemo, forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'clsx'
import BlockContent from '@sanity/block-content-to-react'
import map from 'lodash/map'
import merge from 'lodash/merge'
import Link from './Link'
import { resolveLink } from '../helpers/resolveLink'
import theme, { span } from '../style/theme'

const marks = {
  link: ({ mark, children }) => (
    <Link to={mark.href} target={mark.open_in_new_window ? '_blank' : undefined}>{children}</Link>
  ),
  internal_link: ({ mark, children }) => (
    <Link link={resolveLink(mark)}>{children}</Link>
  ),
  label: ({ mark, children }) => (
    <span className='altHeadings'>{children}</span>
  )
}

const listSerializers = { marks }
const ListItem = ({ title, subtitle, copy, content }) => {
  const classes = useStyles()
  const blocks = useMemo(() => content && map(content.text, block => ({
    _type: block.type, // BlockContent expects `_type` prop, not `type`.
    ...block
  })))
  return (
    <div className={cn(classes.listItem, { withSubtitle: subtitle })}>
      <p className={cn(classes.listTitle, { withSubtitle: subtitle })}>{title}</p>
      <div className={cn(classes.listCopy, { withSubtitle: subtitle })}>
        {subtitle && <h4 className={classes.listSubTitle}>{subtitle}</h4>}
        {copy && <p className={classes.listInnerCopy}>{copy}</p>}
        {blocks && (
          <BlockContent blocks={blocks} serializers={listSerializers} />
        )}
      </div>
    </div>
  )
}

const serializers = {
  marks,
  types: {
    columns: ({ node: { id, columns } }) => {
      const classes = useStyles()
      return (
        <div className={classes.columns}>
          {columns.map(({ title, copy }, i) => (
            <div key={id + i} className={classes.column}>
              <h5 className={classes.columnTitle}>{title}</h5>
              <p className={classes.columnCopy}>{copy}</p>
            </div>
          ))}
        </div>
      )
    },
    list: ({ node: { id, items, spacing = 'spacing-small' } }) => {
      const classes = useStyles()
      return (
        <div className={cn(classes.list, spacing)}>
          {items.map(({ title, subtitle, copy, rich_text: richText }, i) => (
            <ListItem
              key={id + i}
              title={title}
              subtitle={subtitle}
              copy={copy}
              content={richText}
            />
          ))}
        </div>
      )
    }
  }
}

const RichText = forwardRef(({ className, content, tag = 'div', serializers: extraSerializers, children, extraSpacedHeadings = false }, ref) => {
  const classes = useStyles()
  const Component = tag
  const allSerializers = useMemo(() => {
    if (extraSerializers) {
      return merge({}, serializers, extraSerializers)
    }
    return serializers
  }, [extraSerializers])
  const blocks = useMemo(() => map(content, block => ({
    _type: block.type, // BlockContent expects `_type` prop, not `type`.
    ...block
  })))
  return (
    <Component className={cn(className, classes.container, { 'spaced-headings': extraSpacedHeadings })} ref={ref}>
      <BlockContent blocks={blocks} serializers={allSerializers} />
      {children}
    </Component>
  )
})

const useStyles = createUseStyles({
  container: {
    '& > *:last-child': {
      marginBottom: 0
    },
    '& ul': {
      margin: [theme.spacing(1), 0]
    },
    '& li': {
      padding: [theme.spacing(0.5), 0]
    },
    '&.spaced-headings': {
      '& h1, h2, h3, h4': {
        marginBottom: theme.spacing(5),
        [theme.breakpoints.up('xs')]: {
          marginBottom: theme.spacing(8)
        }
      }
    },
    '& strong': {
      fontWeight: 600
    }
  },
  columns: {
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      columnGap: '80px',
      rowGap: theme.spacing(2),
      marginBottom: 0
    }
  },
  column: {
    margin: [theme.spacing(2), 0, theme.spacing(1)],
    [theme.breakpoints.up('md')]: {
      maxWidth: 230
    }
  },
  columnTitle: {
    ...theme.textStyles.caption,
    marginBottom: theme.spacing(1)
  },
  columnCopy: {
    whiteSpace: 'pre-wrap'
  },
  list: {
    '&:not(:first-child)': {
      marginTop: theme.spacing(3),
      [theme.breakpoints.up('xs')]: {
        marginTop: theme.spacing(3)
      }
    },

    '& > *': {
      marginBottom: theme.spacing(3),
      '&:last-child': {
        marginBottom: theme.spacing(1)
      }
    },
    '&.spacing-medium': {
      '& > *': {
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
          marginBottom: theme.spacing(3)
        }
      }
    },
    '&.spacing-large': {
      '& > *': {
        marginBottom: theme.spacing(4),
        [theme.breakpoints.up('md')]: {
          marginBottom: theme.spacing(9)
        }
      }
    }
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('xs')]: {
      flexDirection: 'row'
    },
    '&.withSubtitle': {
      display: 'block',
      [theme.breakpoints.up('xs')]: {
        display: 'flex'
      }
    }
  },
  listTitle: {
    extend: theme.textStyles.caption,
    width: '100%',
    marginRight: theme.grid.gutter,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('xs')]: {
      width: span(2, 'md')
    },
    '&.withSubtitle': {
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    }
  },
  listCopy: {
    width: '100%',
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.up('xs')]: {
      width: span(3, 'md')
    },
    '&.withSubtitle': {
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    }
  },
  listInnerCopy: {
    marginBottom: '0 !important',
    whiteSpace: 'break-spaces'
  },
  listSubTitle: {
    marginBottom: theme.spacing(1)
  }
}, { name: 'RichText' })

export default RichText
