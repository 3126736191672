import React, { useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import remove from 'lodash/remove'
import theme from '../style/theme'
import gsap from 'gsap'
import detectIt from 'detect-it'
import lerp from 'lerp'
import forEach from 'lodash/forEach'
import { useSelector } from 'react-redux'
import { isContentBusy, getPageColorByPath } from '../redux/slices/content'
import cn from 'clsx'
import afterFrame from '../helpers/afterFrame'
import { useScrollListener } from './useSmoothScrollbar'
import { isPreloading } from '../redux/slices/preload'
import { getFontScaling } from '../style/textStyles'
import throttle from 'lodash/throttle'

const CURSOR_ENABLED = true

const EASE = 0.8

export const data = {
  mouse: {
    x: 0,
    y: 0
  },
  current: {
    x: undefined,
    y: undefined
  },
  last: {
    x: undefined,
    y: undefined
  },
  ease: EASE,
  fx: {
    diff: 0,
    acc: 0,
    velocity: 0,
    scale: 1
  },
  fy: {
    diff: 0,
    acc: 0,
    velocity: 0,
    scale: 1
  },
  state: {
    text: null,
    targetElement: null,
    animatedInText: false,
    loading: false,
    ticker: false,
    tickerAnimationTimeline: null,
    messageBoxWidth: 0,
    hoverElement: null,
    hoverListeners: []
  }
}

const updateHoverElement = throttle(() => {
  if (!data.state.loading) {
    if (data.current && data.current.x >= 0 && data.current.y >= 0) {
      const element = document.elementFromPoint(data.current.x, data.current.y)
      if (data.state.hoverElement !== element) {
        data.state.hoverElement = element
        forEach(data.state.hoverListeners, cb => {
          cb(element)
        })
      }
    }
  }
}, 500, { leading: false, trailing: true })

export const clearCursorState = () => {
  data.state = {}
}

const animationCallbacks = []
const mouseMoveCallbacks = []

export const useCursorAnimation = (mouseMoveCallback, animationCallback) => {
  useEffect(() => {
    animationCallbacks.push(animationCallback)
    mouseMoveCallbacks.push(mouseMoveCallback)
    return () => {
      remove(animationCallbacks, x => x === animationCallback)
      remove(mouseMoveCallbacks, x => x === mouseMoveCallback)
    }
  }, [animationCallback])
}

export const updateCursorText = (ref, text, ticker, show) => {
  if (CURSOR_ENABLED && detectIt.primaryInput !== 'touch' && ref.current) {
    if (show && text) {
      data.state.targetElement = ref.current
      data.state.text = text
      data.state.ticker = ticker
    }
  }
}

export const clearCursorText = (text) => {
  if (text === data.state.text) {
    data.state.targetElement = null
    data.state.text = null
    data.state.ticker = false
  }
}

export const useCursorText = (ref, text, ticker, show) => {
  useEffect(() => {
    updateCursorText(ref, text, ticker, show)
    return () => {
      clearCursorText(text)
    }
  }, [show, text, ticker])
}

const isDescendant = (parent, child) => {
  var node = child.parentNode
  while (node !== null) {
    if (node === parent) {
      return true
    }
    node = node.parentNode
  }
  return false
}

export const useCursorHover = (ref, text, ticker, enter, leave, enabled = true) => {
  const hoveringRef = useRef(false)
  useEffect(() => {
    if (CURSOR_ENABLED && detectIt.primaryInput !== 'touch' && ref.current && enabled) {
      const onEnter = e => {
        if (!data.state.loading) {
          hoveringRef.current = true
          updateCursorText(ref, text, ticker, true)
          if (enter) { enter(data) }
        }
      }
      const onLeave = e => {
        hoveringRef.current = false
        clearCursorText(text)
        if (leave) { leave(data) }
      }
      ref.current.addEventListener('mouseenter', onEnter)
      ref.current.addEventListener('mouseleave', onLeave)
      return () => {
        if (ref.current) {
          ref.current.removeEventListener('mouseenter', onEnter)
          ref.current.removeEventListener('mouseleave', onLeave)
        }
        if (data.state.targetElement === ref.current) {
          onLeave()
        }
      }
    }
  }, [ref, ticker, text, enter, leave, enabled])

  // This is for performance
  useEffect(() => {
    const check = (hoverElement) => {
      if (hoveringRef.current) {
        if (ref.current !== hoverElement && !isDescendant(ref.current, hoverElement)) {
          hoveringRef.current = false
          clearCursorText(text)
          if (leave) { leave(data) }
        }
      } else {
        if (ref.current === hoverElement || isDescendant(ref.current, hoverElement)) {
          hoveringRef.current = true
          updateCursorText(ref, text, ticker, true)
          if (enter) { enter(data) }
        }
      }
    }
    data.state.hoverListeners.push(check)
    return () => {
      data.state.hoverListeners = data.state.hoverListeners.filter(x => x !== check)
    }
  }, [enter, leave, ref, text, ticker])

  useScrollListener(updateHoverElement)

  useEffect(() => {
    return () => clearCursorText(text)
  }, [])
}

function Cursor () {
  const ref = useRef()
  const messageBoxRef = useRef()
  const backgroundColor = useSelector(getPageColorByPath)
  const foregroundColor = theme.getForegroundColor(backgroundColor)
  const classes = useStyles({ foregroundColor })

  useEffect(() => {
    if (CURSOR_ENABLED && detectIt.primaryInput !== 'touch' && ref.current) {
      gsap.set(ref.current, { display: 'block' })
    }
  }, [])

  useEffect(() => {
    if (CURSOR_ENABLED && detectIt.primaryInput !== 'touch') {
      const updateMousePosition = (evnt) => {
        afterFrame(() => {
          if (ref.current) {
            ref.current.style.opacity = 1
            const e = evnt.detail && evnt.detail.pageX ? evnt.detail : evnt
            data.mouse.x = e.pageX
            data.mouse.y = e.pageY - (window.pageYOffset || document.documentElement.scrollTop)

            data.current.x = e.pageX
            data.current.y = e.pageY - (window.pageYOffset || document.documentElement.scrollTop)
            forEach(mouseMoveCallbacks, cb => cb(data, evnt))
          }
        })
      }
      window.addEventListener('mousemove', updateMousePosition, { passive: true })
      window.addEventListener('dragover', updateMousePosition, { passive: true })
      window.addEventListener('flickity-dragMove', updateMousePosition, { passive: true })
      return () => {
        window.removeEventListener('mousemove', updateMousePosition)
        window.removeEventListener('dragover', updateMousePosition)
        window.removeEventListener('flickity-dragMove', updateMousePosition)
      }
    }
  }, [])

  useEffect(() => {
    if (CURSOR_ENABLED && detectIt.primaryInput !== 'touch') {
      const track = () => {
        if (ref.current && data.current.x !== undefined) {
          // on the initial mouse move, we do not want any easing. Just put the mouse on the cursor
          if (data.last.x === undefined) {
            data.last.x = data.current.x
            data.last.y = data.current.y
          } else {
            data.last.x = lerp(data.last.x, data.current.x, data.ease)
            data.last.y = lerp(data.last.y, data.current.y, data.ease)
          }

          data.fx.diff = data.current.x - data.last.x
          data.fx.acc = data.fx.diff / window.innerWidth
          data.fx.velocity = +data.fx.acc

          data.fy.diff = data.current.y - data.last.y
          data.fy.acc = data.fy.diff / window.innerWidth
          data.fy.velocity = +data.fy.acc

          ref.current.style.transform = `translate3d(${data.last.x}px, ${data.last.y}px, 0)`

          const {
            text,
            targetElement,
            animatedInText,
            ticker,
            tickerAnimationTimeline
          } = data.state
          if (text && targetElement) {
            if (animatedInText !== text) {
              if (tickerAnimationTimeline) {
                tickerAnimationTimeline.kill()
              }

              messageBoxRef.current.innerHTML = ''
              const textElement = document.createElement('span')
              textElement.innerHTML = text
              messageBoxRef.current.appendChild(textElement)

              if (ticker) {
                const copy = textElement.cloneNode()
                copy.innerHTML = text
                copy.className = classes.tickerCopy
                messageBoxRef.current.appendChild(copy)
                const { width } = textElement.getBoundingClientRect()
                const tl = gsap.timeline({ repeat: -1 })
                tl.to([textElement, copy], { x: -width, duration: width / 80, ease: 'none' }) // This will move at 80px per second
                data.state.tickerAnimationTimeline = tl
              }
              gsap.to(messageBoxRef.current, { clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', duration: 0.25, ease: 'sine.out' })
              data.state.animatedInText = text
              data.state.messageBoxWidth = messageBoxRef.current.getBoundingClientRect().width
            }

            const offsetX = -((data.state.messageBoxWidth + data.current.x) - window.innerWidth)
            if (offsetX < 0) {
              gsap.to(messageBoxRef.current, { x: '-100%', duration: 0.25 })
            } else {
              gsap.to(messageBoxRef.current, { x: '0%', duration: 0.25 })
            }
          } else {
            if (animatedInText) {
              gsap.to(messageBoxRef.current, {
                clipPath: 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)',
                duration: 0.25,
                ease: 'sine.out',
                onComplete: () => {
                  if (tickerAnimationTimeline) tickerAnimationTimeline.kill()
                }
              })
              data.state.animatedInText = false
            }
          }
        }
      }
      gsap.ticker.add(track)
      return () => {
        gsap.ticker.remove(track)
      }
    }
  }, [])

  // Animates the loading effect
  const contentLoading = useSelector(isContentBusy)
  const preloading = useSelector(isPreloading)
  const loading = contentLoading || preloading
  useEffect(() => {
    data.state.loading = loading
  }, [loading])
  useCursorText(ref, 'Loading', true, loading)

  if (!CURSOR_ENABLED) return null

  return (
    <div className={cn(classes.cursor, { loading })} ref={ref}>
      <div className={classes.messageBox} ref={messageBoxRef} />
    </div>
  )
}

const useStyles = createUseStyles({
  cursor: {
    fontSize: 16,
    position: 'fixed',
    width: '1em',
    height: '1em',
    top: 0,
    left: 0,
    pointerEvents: 'none',
    zIndex: theme.zIndex.cursor,
    opacity: 0,
    color: ({ foregroundColor }) => foregroundColor,
    ...getFontScaling(16, 1.12, 1.5)
  },
  icon: {
    opacity: 1,
    display: 'block',
    width: '1em',
    height: '1em'
  },
  text: {
    fill: ({ foregroundColor }) => theme.getForegroundColor(foregroundColor)
  },
  messageBox: {
    pointerEvents: 'none',
    backgroundColor: '#222222',
    color: theme.colors.white,
    position: 'absolute',
    top: 'calc(100% - 0.2em)',
    left: 'calc(100% - 0.2em)',
    whiteSpace: 'nowrap',
    fontSize: 10,
    lineHeight: 1.6,
    fontFamily: theme.fonts.mono,
    fontWeight: theme.fonts.monoFontWeight,
    textTransform: 'uppercase',
    overflow: 'hidden',
    clipPath: 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)',
    '& > *': {
      display: 'block',
      padding: [2, 8, 1]
    },
    ...getFontScaling(10, 1.12, 1.5)
  },
  tickerCopy: {
    position: 'absolute',
    left: '100%',
    top: 0
  }
}, { name: 'Cursor' })

export default Cursor
