import React from 'react'
import { createUseStyles } from 'react-jss'
import RichText from '../../RichText'
import theme, { span } from '../../../style/theme'

const BlockRichText = ({ text }) => {
  const classes = useStyles()
  return (
    <div className={classes.block}>
      <RichText content={text} className={classes.richText} />
    </div>
  )
}

const useStyles = createUseStyles({
  block: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '80%',
    [theme.breakpoints.up('xs')]: {
      margin: [0, span(1, 'md')],
      maxWidth: 900,
      paddingTop: '5vw',
      paddingBottom: '5vw'
    }
  },
  richText: {
    width: '100%'
  }
}, { name: 'BlockRichText' })

export default BlockRichText
