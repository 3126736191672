import React, { useCallback, useMemo } from 'react'
import cn from 'clsx'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { useInView } from 'react-intersection-observer'
import { isCurrentBreakpointMobile } from '../../../redux/slices/layout'
import animations from '../../../animations'
import useReversingLottieAnimation from '../../../hooks/useReversingLottieAnimation'
import composeRefs from '../../../helpers/composeRefs'
import theme from '../../../style/theme'

const ApproachItem = (props) => {
  const {
    index,
    animation,
    copy,
    label
  } = props

  const isMobile = useSelector(isCurrentBreakpointMobile)

  let animationData = null

  const getAnimationData = useCallback((key) => {
    switch (key) {
      case ('animation-immersion'):
        return animations.processImmersion
      case ('animation-architecture'):
        return animations.processArchitecture
      case ('animation-engineering'):
        return animations.processEngineering
      case ('animation-design'):
        return animations.processDesign
      default:
        return null
    }
  }, [])

  animationData = getAnimationData(animation)
  const aspectRatio = animationData ? animationData.w / animationData.h : 1

  const [inViewRef, inView] = useInView({ threshold: 0.5, triggerOnce: false })
  const playbackSpeed = 0.65
  const staggerDelay = useMemo(() => isMobile ? 0 : (index % 2) * 1, [index, isMobile])
  const animationRef = useReversingLottieAnimation(animationData, inView, playbackSpeed, staggerDelay)
  const ref = composeRefs(inViewRef, animationRef)

  const classes = useStyles({ aspectRatio })

  return (
    <div className={classes.item}>
      <div className={classes.logoContainer}>
        {animationData && (
          <span className={cn(classes.logo, animation)} ref={ref} />
        )}
      </div>
      <div className={classes.textContainer}>
        <h3 className={classes.label}>{label}</h3>
        <p className={classes.copy}>{copy}</p>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  logoContainer: {
    gridColumn: 'span 2',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      height: 96,
      display: 'grid',
      alignItems: 'end',
      columnGap: theme.grid.gutter,
      gridColumn: 'span 8',
      gridTemplateColumns: 'repeat(4, 1fr)'
    }
  },
  logo: {
    display: 'block',
    height: 96,
    width: ({ aspectRatio }) => 96 * aspectRatio,
    '&.animation-design': {
      height: 64,
      width: ({ aspectRatio }) => 64 * aspectRatio
    },
    [theme.breakpoints.up('xs')]: {
      gridColumn: '2 / span 2'
    }
  },
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  textContainer: {
    gridColumn: 'span 2',
    display: 'grid',
    columnGap: theme.grid.gutter,
    rowGap: '16px',
    gridTemplateColumns: '1fr 1fr',
    '& > *': {
      gridColumn: 'span 2'
    },
    [theme.breakpoints.up('xs')]: {
      gridColumn: 'span 8',
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)'
    }
  },
  label: {
    extend: theme.textStyles.caption,
    [theme.breakpoints.up('xs')]: {
      gridColumn: 'span 1'
    }
  },
  copy: {
    extend: theme.textStyles.body,
    marginBottom: 0,
    [theme.breakpoints.up('xs')]: {
      gridColumn: 'span 2'
    }
  }
}, { name: 'ApproachItem' })

export default ApproachItem
