import inDOM from 'dom-helpers/canUseDOM'
import { ActionType } from 'redux-promise-middleware'
import { rehydrated, transitionComplete } from '../../actions'

import { pageContentActionCreator } from '../../slices/content/page'
import { postsChangeFilterActionCreator } from '../../slices/content/posts'

import { fetchPosts } from '../../api'

import {
  getPageContent,
  getPostListSlice,
  getPostsQueryStringArguments,
  isPostPage
} from '../../slices/content/selectors'
import { getCurrentRoutePath } from '../../slices/location'
import { resolveInternalLinkUrl } from '../../../helpers/resolveLink'

export const POSTS_PER_PAGE = 6

export default store => next => action => {
  if (!inDOM) {
    return next(action)
  }
  const ret = next(action)
  const postListSlice = getPostListSlice(store.getState())
  if (postListSlice) {
    const currentPath = getCurrentRoutePath(store.getState())
    const pagePath = resolveInternalLinkUrl(getPageContent(store.getState()))

    if (currentPath === pagePath) {
      const { filter = null } = getPostsQueryStringArguments(store.getState())
      if (action.type === rehydrated.toString() ||
        action.type === postsChangeFilterActionCreator.toString() ||
        action.type === `${pageContentActionCreator}_${ActionType.Fulfilled}` ||
        action.type === transitionComplete.toString()) {
        let filterId = ''
        if (filter) {
          const match = postListSlice.tags.find(({ tag }) => filter === tag.slug)
          if (match) filterId = match.tag.id
        }
        fetchPosts(store.dispatch, store.getState, {
          filter: filterId,
          page: 0,
          limit: 1000
        })
      }
    }
  }

  const postPage = isPostPage(store.getState())
  if (postPage) {
    if (action.type === rehydrated.toString() ||
      action.type === `${pageContentActionCreator}_${ActionType.Fulfilled}` ||
      action.type === transitionComplete.toString()) {
      fetchPosts(store.dispatch, store.getState, {
        page: 0,
        limit: 10
      })
    }
  }

  return ret
}
