import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { getBlogHome } from '../../../redux/slices/content'
// import ResponsiveImage from '../../ResponsiveImage'
import Link from '../../Link'
import cn from 'clsx'
import theme, { span } from '../../../style/theme'
import { resolveInternalLink } from '../../../helpers/resolveLink'
import ProgressLine, { useProgressLineClickAnimation } from '../../ProgressLine'
import Placeholder from '../../Placeholder'
import { useCursorHover } from '../../Cursor'
import useHovering from '../../../hooks/useHovering'
import ImageVideoTicker from '../ImageTicker'

const Tile = (props) => {
  const {
    placeholder,
    heroColor,
    className,
    hero_images: heroImages,
    desktop_video: desktopVideo,
    mobile_video: mobileVideo,
    image,
    title,
    summary,
    read_time: readTime,
    client,
    tag,
    hover_label: hoverLabel,
    tight,
    classNames = {}
  } = props
  const classes = useStyles()
  const { hovering, ref } = useHovering(!placeholder)
  const img = heroImages ? heroImages[0] : image
  const link = useMemo(() => resolveInternalLink(props), [props])
  const blogHome = useSelector(getBlogHome)

  useCursorHover(ref, hoverLabel, false, null, null, !placeholder && hoverLabel)
  const { lineRef, clickRef } = useProgressLineClickAnimation(!placeholder)

  if (placeholder) {
    return (
      <div className={cn(classes.article, className)}>
        <div className={classes.top}>
          <Placeholder className={classes.imagePlaceHolder} />
          <div className={cn(classes.blurb, { tight })}>
            <div className={classes.blurbContainer}>
              <Placeholder type='title' className={classes.title} tag='h4' />
              <Placeholder type='copy' className={classes.summary} lines={3} />
            </div>
          </div>
        </div>
        <div className={classes.footer}>
          <Placeholder type='text' className={classes.footerPlaceholder} />
          <Placeholder type='text' className={classes.footerPlaceholder} />
        </div>
        <ProgressLine className={cn(classes.progressLine, classNames.progressLine)} />
      </div>
    )
  }

  const readTimeText = readTime ? `READ:${readTime}` : client

  return (
    <div className={cn(classes.article, className)} ref={ref}>
      <Link link={link} className={classes.top} ref={clickRef}>
        {/* <ResponsiveImage {...img} aspect={1.4} className={cn(classes.image, classNames.image)} /> */}
        {(image || desktopVideo) && (
          <ImageVideoTicker
            images={[img]}
            className={cn(classes.image, classNames.image)}
            animate={hovering}
            desktopVideo={desktopVideo}
            mobileVideo={mobileVideo}
            aspect={1.4}
          />
        )}
        <div className={cn(classes.blurb, { tight })}>
          <div className={classes.blurbContainer}>
            <h3 className={classes.title}>{title}</h3>
            <span className={classes.summary}>{summary}</span>
          </div>
        </div>
      </Link>
      <div className={classes.footer}>
        {tag && <Link to={`/${blogHome.reference.slug}?filter=${tag.slug}`} className={classes.tag}>{tag.title}</Link>}
        <span className={classes.readTime}>{readTimeText}</span>
      </div>
      <ProgressLine
        className={cn(classes.progressLine, classNames.progressLine)}
        color={heroColor ? heroColor.hex : null}
        hovering={hovering}
        ref={lineRef}
      />
    </div>
  )
}

const useStyles = createUseStyles({
  article: {
    position: 'relative',
    // color: 'currentColor',
    color: 'var(--foreground)',
    transition: 'color 0.15s ease-in-out',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      position: 'relative'
    }
  },
  progressLine: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0
  },
  top: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',
    color: 'currentColor',
    '&:hover $summary': {
      opacity: 1
    }
  },
  image: {
    marginBottom: theme.spacing(1)
  },
  title: {
    display: 'block',
    marginBottom: theme.spacing(1),
    whiteSpace: 'normal',
    ...theme.textStyles.bodyPlus
  },
  summary: {
    flexGrow: 1,
    display: 'block',
    whiteSpace: 'normal',
    marginBottom: theme.spacing(1),
    opacity: 0.5,
    transition: 'opacity 0.25s ease-in-out',
    fontSize: 14
  },
  blurb: {
    flex: 1,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('xs')]: {
      display: 'grid',
      columnGap: theme.grid.gutter,
      gridTemplateColumns: 'repeat(4, 1fr)',
      marginBottom: theme.spacing(8)
    },
    '&.tight': {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('xs')]: {
        marginBottom: theme.spacing(4)
      }
    }
  },
  blurbContainer: {
    [theme.breakpoints.up('xs')]: {
      gridColumn: '1 / span 3'
    }
  },
  footer: {
    ...theme.textStyles.caption,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: theme.spacing(1),
    marginBottom: theme.spacing(0.5)
  },
  readTime: {
    opacity: 0.5,
    justifySelf: 'start'
  },
  tag: {
    textDecoration: 'none'
  },
  imagePlaceHolder: {
    paddingTop: '71.43%',
    position: 'relative',
    marginBottom: theme.spacing(1)
  },
  footerPlaceholder: {
    opacity: '0.5',
    width: '40%'
  }
}, { name: 'Tile' })

export default Tile
