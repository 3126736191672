import React from 'react'

export default ({ className }) => {
  return (
    <svg className={className} viewBox='0 0 8 3' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.79 2.75c.207-.427.4-.747.58-.96H.54v-.42h5.83c-.18-.213-.373-.533-.58-.96h.35c.42.487.86.847 1.32 1.08v.18c-.46.227-.9.587-1.32 1.08h-.35z'
        fill='currentColor'
      />
    </svg>
  )
}
