import React, { useCallback, useMemo, useRef } from 'react'
import Section from '../Section'
import SliceHeader from './SliceHeader'
import ResponsiveImage from '../ResponsiveImage'
import { createUseStyles } from 'react-jss'
import Link from 'redux-first-router-link'
import { resolveInternalLinkUrl } from '../../helpers/resolveLink'
import theme, { span } from '../../style/theme'
import ProgressLine, { useProgressLineClickAnimation } from '../ProgressLine'
import composeRefs from '../../helpers/composeRefs'
import useFadeInAnimation from '../../animations/useFadeInAnimation'
import { useCursorHover } from '../Cursor'
import Slider from '../Slider'
import { isCurrentBreakpointAtLeast } from '../../redux/slices/layout'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import cn from 'clsx'
import useHovering from '../../hooks/useHovering'
import { getFontScaling } from '../../style/textStyles'

const isCurrentBreakpointXS = state => !isCurrentBreakpointAtLeast(state, 'xs')

const ArticleTile = ({ article }) => {
  const classes = useStyles()
  const ref = useRef()
  const url = useMemo(() => resolveInternalLinkUrl(article), [article])
  const { title, image, hero_images: heroImages, summary, tag, client, read_time: readTime, hover_label: hoverLabel } = article
  const readTimeText = readTime ? `READ:${readTime}` : client
  useCursorHover(ref, hoverLabel)
  const { ref: hoverRef, hovering } = useHovering(true)
  const { lineRef, clickRef } = useProgressLineClickAnimation()

  const thumbnailImage = image ?? get(heroImages, [0])

  return (
    <div ref={composeRefs(hoverRef, clickRef, ref)} className={cn(classes.article, 'article')}>
      <Link to={url} className={classes.articleLink}>
        {thumbnailImage && <ResponsiveImage className={classes.image} {...thumbnailImage} aspect={1.4375} />}
        <div className={classes.content}>
          <h4 className={classes.title}>{title}</h4>
          <p className={classes.summary}>{summary}</p>
          <footer className={classes.meta}>
            <span className={classes.tag}>
              {tag && tag.title}
            </span>
            <span className={classes.client}>
              {readTimeText}
            </span>
          </footer>
          <ProgressLine hovering={hovering} ref={lineRef} />
        </div>
      </Link>
    </div>
  )
}

export const ReadMore = ({ articles, title, link }) => {
  const classes = useStyles()
  const ref = useRef()
  const isMobile = useSelector(isCurrentBreakpointXS)
  const [inViewRef] = useFadeInAnimation(
    useCallback(() => {
      return ref.current.querySelectorAll('.article')
    }, []),
    { stagger: 0.15 }
  )
  const headerSlice = useMemo(() => ({
    title,
    button: link
  }), [])
  return (
    <Section>
      <SliceHeader slice={headerSlice} bottomBorder className={classes.sliceHeader} />
      <Section grid fullWidth className={classes.container} ref={composeRefs(inViewRef, ref)} noBottomMargin>
        <Slider enabled={isMobile}>
          {articles && articles.map(article => (
            <ArticleTile key={article.id} article={article} />
          ))}
        </Slider>
      </Section>
    </Section>
  )
}

export default ({ slice }) => {
  return <ReadMore articles={slice.articles} title={slice.title} link={slice.button} />
}

const useStyles = createUseStyles({
  container: {
    rowGap: '32px',
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'grid'
    }
  },
  sliceHeader: {
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(3),
      marginBottom: theme.spacing(3)
    }
  },
  article: {
    display: 'block',
    textDecoration: 'none',
    margin: [0, theme.spacing(0.5), theme.spacing(2)],
    width: '90%',
    opacity: 0,
    [theme.breakpoints.up('xs')]: {
      width: '100%',
      margin: [0, 0, theme.spacing(2)],
      gridColumn: '1 / span 12'
    },
    [theme.breakpoints.up('md')]: {
      margin: 0,
      gridColumn: '5 / span 8'
    },
    '&:hover $summary': {
      opacity: 1
    }
  },
  articleLink: {
    display: 'block',
    textDecoration: 'none',
    [theme.breakpoints.up('xs')]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      columnGap: `${theme.spacing(1)}px`,
      rowGap: `${theme.spacing(2)}px`
    }
  },
  image: {
    width: '100%',
    gridRow: 1,
    gridColumn: 2,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('xs')]: {
      marginBottom: 0
    }
  },
  content: {
    width: '100%',
    gridRow: 1,
    gridColumn: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  summary: {
    flexGrow: 1,
    marginBottom: theme.spacing(3.5),
    transition: 'opacity 0.25s ease-in-out',
    opacity: 0.5,
    whiteSpace: 'normal',
    minHeight: '4.2em',
    fontSize: 14,
    [theme.breakpoints.up('md')]: {
      maxWidth: '75%'
    }
  },
  title: {
    whiteSpace: 'normal',
    marginBottom: theme.spacing(1),
    fontSize: 16,
    ...getFontScaling(16)
  },
  meta: {
    extend: theme.textStyles.caption,
    display: 'grid',
    columnGap: theme.spacing(1),
    gridTemplateColumns: '1fr 1fr',
    marginBottom: theme.spacing(0.5)
  },
  client: {
    whiteSpace: 'normal',
    opacity: 0.5
  },
  tag: {
    textDecoration: 'none'
  }
})
