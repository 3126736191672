import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getPageColorByPath } from '../redux/slices/content'
import theme from '../style/theme'
import gsap from 'gsap'

export const transitionProps = {
  duration: 0.25,
  ease: 'sine.inOut'
}

export default (ref) => {
  const pageBackgroundColor = useSelector(getPageColorByPath)
  const backgroundColor = pageBackgroundColor
  const foregroundColor = theme.getForegroundColor(backgroundColor)

  useEffect(() => {
    gsap.to(ref.current, {
      backgroundColor: backgroundColor || theme.colors.schemes.default.background,
      color: foregroundColor || theme.colors.schemes.default.foreground,
      '--foreground': foregroundColor || theme.colors.schemes.default.foreground,
      borderColor: foregroundColor || theme.colors.schemes.default.foreground,
      ...transitionProps
    })
  }, [backgroundColor, foregroundColor])
}
