import React, { useContext, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { getPageContent, getPageSlices } from '../redux/slices/content'
import ErrorBoundary from './ErrorBoundary'
import reduce from 'lodash/reduce'
import last from 'lodash/last'
import cn from 'clsx'
import get from 'lodash/get'

import RichTextSlice from './Slices/RichTextSlice'
import StickyTextContent from './Slices/StickyTextContent'
import HomeHero from './Slices/HomeHero'
import ProjectHero from './Slices/ProjectHero'
import BlogHero from './Slices/BlogHero'
import PostTextSlice from './Slices/PostTextSlice'
import PostImageSlice from './Slices/PostImageSlice'
import PostImageGallery from './Slices/PostImageGallery'
import ImageGridSlice from './Slices/ImageGridSlice'
import ArticleGrid from './Slices/ArticleGrid'
import FullWidthImage from './Slices/FullWidthImage'
import FullWidthVideo from './Slices/FullWidthVideo'
import ImageSlider from './Slices/ImageSlider'
import Quote from './Slices/Quote'
import ArticleSlider from './Slices/ArticleSlider'
import Profiles from './Slices/Profiles'
import AwardsSlice from './Slices/AwardsSlice'
import ApproachSlice from './Slices/ApproachSlice'
import PartnersGrid from './Slices/PartnersGrid'
import FeaturedText from './Slices/FeaturedText'
import StudioPageSlice from './Slices/StudioPageSlice'
import PostListSlice from './Slices/PostListSlice'
import WorkListSlice from './Slices/WorkListSlice'
import TextWithParallaxImages from './Slices/TextWithParallaxImages'
import Contact from './Slices/Contact'
import ArticleTiles from './Slices/ArticleTiles'
import theme from '../style/theme'
import ColorContext from './ColorContext'
import ReadMore from './Slices/ReadMore'
import TextHero from './Slices/TextHero'
import IndustriesSlice from './Slices/IndustriesSlice'
import BackgroundColorChanger from './BackgroundColorChanger'
import AnchorPoint from './Slices/AnchorPoint'
import Careers from './Slices/Careers'
import NewsLetter from './Slices/Newsletter'

const sliceComponentSelector = {
  rich_text: RichTextSlice,
  sticky_text_content: StickyTextContent,
  home_hero: HomeHero,
  project_hero: ProjectHero,
  blog_hero: BlogHero,
  post_text_slice: PostTextSlice,
  post_image_slice: PostImageSlice,
  image_grid_slice: ImageGridSlice,
  post_image_gallery: PostImageGallery,
  article_grid_slice: ArticleGrid,
  full_width_image: FullWidthImage,
  full_width_video: FullWidthVideo,
  image_slider_slice: ImageSlider,
  quote_slice: Quote,
  article_slider_slice: ArticleSlider,
  post_list_slice: PostListSlice,
  work_list_slice: WorkListSlice,
  profiles_slice: Profiles,
  awards_slice: AwardsSlice,
  approach_slice: ApproachSlice,
  partners_grid_slice: PartnersGrid,
  featured_text_slice: FeaturedText,
  studio_page_slice: StudioPageSlice,
  text_with_parallax_images: TextWithParallaxImages,
  contact_slice: Contact,
  article_tiles_slice: ArticleTiles,
  read_more: ReadMore,
  text_hero_slice: TextHero,
  industriesSlice: IndustriesSlice,
  anchor_point: AnchorPoint,
  careers: Careers,
  newsletter: NewsLetter
}

const excludedFormTopPadding = ['home_hero', 'text_hero_slice', 'project_hero', 'blog_hero', 'strategy_hero', 'contact_slice', 'change_background_color', 'newsletter']

const Slices = ({ className }) => {
  const slices = useSelector(getPageSlices)
  const page = useSelector(getPageContent)
  const { backgroundColor } = useContext(ColorContext)
  const classes = useStyles({ backgroundColor })

  const sliceComponents = useMemo(() => {
    if (!slices) return null
    const groups = reduce(slices, (result, slice) => {
      if (slice.type === 'change_background_color') {
        result.push([])
      }
      last(result).push(slice)
      return result
    }, [[]])

    const createSlices = (groupedSlices, index) => {
      const topPadding = !excludedFormTopPadding.includes(groupedSlices[0].type)
      const sliceChangeOfColor = get(groupedSlices, [0, 'type']) === 'change_background_color' && get(groupedSlices, [0, 'backgroundColor', 'hex'])
      return (
        <BackgroundColorChanger
          backgroundColor={sliceChangeOfColor}
          key={index}
          className={cn(classes.sliceGroup, { topPadding }, 'clearFix')}
        >
          {groupedSlices.map((slice, index) => {
            const Component = sliceComponentSelector[slice.type]
            const last = index === groupedSlices.length - 1
            const first = index === 0
            const nextSlice = groupedSlices[index + 1]
            if (!Component) return null
            return (
              <ErrorBoundary key={`slice-${index}`}>
                <Component slice={slice} page={page} last={last} first={first} nextSlice={nextSlice} />
              </ErrorBoundary>
            )
          })}
        </BackgroundColorChanger>
      )
    }
    return (
      <div className={classes.container}>
        {groups.map(createSlices)}
      </div>
    )
  }, [slices, classes])

  return sliceComponents
}

const useStyles = createUseStyles({
  container: {
    backgroundColor: ({ backgroundColor = theme.colors.background }) => backgroundColor
  },
  sliceGroup: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    position: 'relative',
    zIndex: 1,
    '&.topPadding': {
      paddingTop: theme.spacing(6)
    }
  }
})

export default Slices
