import React, { useRef, forwardRef, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'clsx'
import theme from '../../style/theme'
import ResponsiveImage from '../ResponsiveImage'
import RichText from '../RichText'
import useStickyElement from './useStickyElement'
import Section from '../Section'
import useFadeInAnimation from '../../animations/useFadeInAnimation'
import composeRefs from '../../helpers/composeRefs'

const StickyTextContent = forwardRef(({ className, slice, ...props }, ref) => {
  const classes = useStyles()
  const sectionRef = useRef()
  const contentRef = useRef()
  const { copy, image } = slice

  useStickyElement(sectionRef, contentRef)
  const [inViewRef] = useFadeInAnimation(useCallback(() => contentRef.current, []))

  return (
    <Section grid ref={sectionRef}>
      <div className={cn(classes.textCol, classes.col)}>
        <div className={classes.textWrapper} ref={composeRefs(inViewRef, contentRef)}>
          <RichText
            ref={ref}
            content={copy.text}
          />
        </div>
      </div>
      <div className={cn(classes.imageCol, classes.col)}>
        <div className={classes.imageWrapper}>
          <ResponsiveImage {...image} />
        </div>
      </div>
    </Section>
  )
})

const useStyles = createUseStyles({
  imageCol: {
    gridRow: 2,
    gridColumn: '1 / span 2',
    [theme.breakpoints.up('xs')]: {
      gridColumn: '2 / span 6',
      gridRow: 1
    }
  },
  textCol: {
    marginBottom: theme.spacing(4),
    gridColumn: '1 / span 2',
    gridRow: 1,
    [theme.breakpoints.up('xs')]: {
      gridColumn: '9 / span 3',
      marginBottom: 0
    }
  },
  textWrapper: {
    opacity: 0
  }
}, { name: 'StickyTextContent' })

export default StickyTextContent
