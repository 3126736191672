import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../style/theme'
import cn from 'clsx'
import ProgressLine from './ProgressLine'

const Section = forwardRef(({ children, className, tag, noBottomMargin, bottomBorder, fullWidth, grid }, ref) => {
  const classes = useStyles()
  const Tag = tag || 'section'

  return (
    <Tag
      className={cn(
        classes.container,
        className,
        !noBottomMargin && classes.bottomMargin,
        grid && classes.grid,
        bottomBorder && classes.withBottomBorder,
        !fullWidth && classes.sideMargins
      )}
      ref={ref}
    >
      {children}
      {bottomBorder && <ProgressLine className={classes.line} />}
    </Tag>
  )
})

const useStyles = createUseStyles({
  container: {
  },
  grid: {
    display: 'grid',
    columnGap: theme.grid.gutter,
    rowGap: '16px',
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.up('xs')]: {
      gridTemplateColumns: 'repeat(12, 1fr)'
    }
  },
  sideMargins: {
    marginLeft: theme.grid.margin,
    marginRight: theme.grid.margin
  },
  bottomMargin: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(6)
    }
  },
  withBottomBorder: {
    position: 'relative',
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(6)
    }
  },
  line: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: 1
  }
}, { name: 'Section' })

export default Section
