import React, { useMemo, useCallback, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import get from 'lodash/get'
import Section from '../Section'
import SplitWordsAnimation from '../SplitWordsAnimation'
import theme from '../../style/theme'
import RichText from '../RichText'
import ResponsiveImage from '../ResponsiveImage'
import StyledButton from '../StyledButton'
import Link from '../Link'
import useFadeInAnimation from '../../animations/useFadeInAnimation'
import composeRefs from '../../helpers/composeRefs'
import toArray from 'lodash/toArray'
import { resolveLink } from '../../helpers/resolveLink'
import useHovering from '../../hooks/useHovering'
import ProgressLine, { useProgressLineClickAnimation } from '../ProgressLine'
import { getFontScaling } from '../../style/textStyles'

const Position = ({ title, link }) => {
  const classes = useStyles()
  const resolvedLink = useMemo(() => resolveLink(get(link, [0])), [link])
  const { ref, hovering } = useHovering(resolvedLink)
  const { lineRef, clickRef } = useProgressLineClickAnimation()
  return (
    <Link className={classes.position} link={resolvedLink} nonLinkTag='span' ref={composeRefs(clickRef, ref)}>
      <h5>{title}</h5>
      <ProgressLine hovering={hovering} className={classes.progressLine} ref={lineRef} />
    </Link>
  )
}

export default ({ className, slice }) => {
  const { title, positions, emailUsLink, rich_text: richText, image } = slice
  const classes = useStyles()
  const sectionRef = useRef()

  const link = get(emailUsLink, [0])

  const [inViewRef] = useFadeInAnimation(useCallback(() => {
    return toArray(sectionRef.current.children).slice(1)
  }, []), { delay: 0.5 })

  return (
    <Section grid className={classes.section} ref={composeRefs(inViewRef, sectionRef)}>
      <SplitWordsAnimation>
        <h1 className={classes.title}>{title}</h1>
      </SplitWordsAnimation>
      {richText && <RichText content={richText.text} className={classes.copy} />}
      <ResponsiveImage {...image} className={classes.imageContainer} />
      <div className={classes.positionsHeader}>
        <span>Positions Available</span>
        <span><span className={classes.openLabel}>Open</span> [{positions ? positions.length : 0}]</span>
        <span className={classes.searchingLabel}>We’re currently searching for</span>
      </div>
      {positions && (
        <>
          <div className={classes.positions}>
            {positions.map(({ title, link }, key) => {
              return <Position link={link} title={title} key={key} />
            })}
            <StyledButton link={link} className={classes.link} />
          </div>
        </>
      )}
    </Section>
  )
}

const useStyles = createUseStyles({
  section: {
    rowGap: '64px'
  },
  title: {
    gridRow: 1,
    gridColumn: 'span 2',
    marginBottom: 0,
    [theme.breakpoints.up('xs')]: {
      gridColumn: 'span 12'
    }
  },
  copy: {
    maxWidth: 450,
    gridColumn: 'span 2',
    opacity: 0,
    [theme.breakpoints.up('xs')]: {
      gridColumn: '1 / span 6'
    }
  },
  imageContainer: {
    gridColumn: 'span 2',
    opacity: 0,
    [theme.breakpoints.up('xs')]: {
      gridColumn: '7 / span 6'
    }
  },
  positionsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    gridColumn: 'span 2',
    fontSize: 10,
    textTransform: 'uppercase',
    opacity: 0,
    borderBottom: [1, 'solid', theme.colors.borderDark],
    paddingBottom: theme.spacing(1),
    ...theme.textStyles.baseMonoStyles,
    marginBottom: theme.spacing(-1),
    [theme.breakpoints.up('xs')]: {
      gridColumn: 'span 12',
      marginBottom: theme.spacing(-1),
      display: 'grid',
      gridTemplateColumns: 'repeat(12, 1fr)',
      gridGap: `${theme.spacing(1)}px`
    },
    ...getFontScaling(10),
    '& > *:first-child': {
      gridColumn: 'span 3'
    },
    '& > *:nth-child(2)': {
      gridColumn: 'span 3'
    },
    '& > *:last-child': {
      gridColumn: 'span 6'
    }
  },
  openLabel: {
    display: 'none',
    [theme.breakpoints.up('xs')]: {
      display: 'inline'
    }
  },
  searchingLabel: {
    display: 'none',
    [theme.breakpoints.up('xs')]: {
      display: 'inline'
    }
  },
  positions: {
    opacity: 0,
    gridColumn: 'span 2',
    [theme.breakpoints.up('xs')]: {
      gridColumn: '7 / span 6'
    }
  },
  position: {
    display: 'block',
    padding: [theme.spacing(1), 0],
    textDecoration: 'none',
    position: 'relative',
    '& h5': {
      margin: 0
    }
  },
  progressLine: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0
  },
  link: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      marginTop: theme.spacing(4)
    }
  }
}, { name: 'Careers' })
