import { useCallback, useRef } from 'react'
import { useScrollListener } from '../useSmoothScrollbar'
import afterFrame from '../../helpers/afterFrame'
import { isCurrentBreakpointMobile } from '../../redux/slices/layout'
import { useSelector } from 'react-redux'

const HEADER_OFFSET = 100

export default (containerRef, contentRef, noOffset = false) => {
  const locals = useRef({})
  const isMobile = useSelector(isCurrentBreakpointMobile)

  useScrollListener(useCallback(({ y }) => {
    if (isMobile) return null
    const { start, end } = locals.current

    if (contentRef.current && start) {
      if (y >= start && y <= end) {
        contentRef.current.style.transform = `translate3d(0,${y - start}px,0)`
      }
    }
    afterFrame(() => {
      if (contentRef.current) {
        locals.current = {
          start: containerRef.current.offsetTop - (noOffset ? 0 : HEADER_OFFSET),
          end: containerRef.current.offsetTop + containerRef.current.offsetHeight - contentRef.current.offsetHeight - (noOffset ? 0 : HEADER_OFFSET)
        }
      }
    })
  }, [isMobile]))
}
