import { requestSubscribeActionCreator } from '../slices/newsletter'
import { postSubscription } from '../api'

export default (store) => {
  return next => (action) => {
    const ret = next(action)
    if (action.type === requestSubscribeActionCreator.toString()) {
      postSubscription(store.dispatch, store.getState)
    }
    return ret
  }
}
