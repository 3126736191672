import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'
import { getPageSlug } from '../../redux/slices/content'
import { getNextPages } from '../../redux/slices/nextPages'
import Section from '../Section'
import ArticleGridTile from '../Slices/ArticleGridTile'
import theme from '../../style/theme'
import SliceHeader from '../Slices/SliceHeader'
import { ReadMore } from '../Slices/ReadMore'

const NextPage = ({ isWorkPage, isPostPage }) => {
  const classes = useStyles()
  const nextPages = useSelector(getNextPages)
  const slug = useSelector(getPageSlug)
  const nextPageData = nextPages[slug]

  const articles = nextPageData ? nextPageData.nextPages.slice(0, 3) : []
  const header = useMemo(() => ({
    title: isWorkPage ? 'Keep browsing' : 'Up Next',
    button: { url: isWorkPage ? '/work' : '/wire', text: isWorkPage ? 'All Work' : 'All Articles' }
  }))

  if (isPostPage) {
    return <ReadMore articles={articles} title={header.title} link={header.button} />
  }

  return (
    <>
      <SliceHeader slice={header} fullWidth={false} className={classes.sliceHeader} />
      <Section className={classes.section} fullWidth>
        <div className={classes.container}>
          {articles.map((article, i) => (
            <div key={article.id} className={classes.articleContainer}>
              {isWorkPage && (
                <ArticleGridTile
                  article={article}
                  aspect={i % 2 === 0 ? '10:13' : '10:11'}
                />
              )}
            </div>
          ))}
        </div>
      </Section>
    </>
  )
}

const useStyles = createUseStyles({
  section: {
    marginTop: `${theme.spacing(4)}px !important`,
    marginBottom: `${theme.spacing(4)}px !important`,
    overflow: 'hidden'
  },
  sliceHeader: {
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(3),
      marginBottom: theme.spacing(3)
    }
  },
  title: {
    extend: theme.textStyles.h4,
    margin: [theme.spacing(1.5), theme.grid.margin]
  },
  container: {
    margin: [0, theme.grid.margin],
    display: 'grid',
    rowGap: `${theme.spacing(2)}px`,
    columnGap: theme.grid.gutter,
    gridTemplateColumns: '1fr',
    '& > *:nth-of-type(1n+4)': {
      display: 'none'
    },
    [theme.breakpoints.up('xs')]: {
      gridTemplateColumns: '1fr 1fr'
    },
    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: '1fr 1fr 1fr'
    },
    [theme.breakpoints.down('xl')]: {
      '& > *:nth-of-type(1n+3)': {
        display: 'none'
      }
    }
  },
  progressLine: {
    left: theme.spacing(0.5),
    right: theme.spacing(0.5)
  }
}, { name: 'NextPage' })

export default NextPage
