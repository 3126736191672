import React, { useCallback, useEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import { createUseStyles } from 'react-jss'
import SplitText from 'gsap/SplitText'
import { useInView } from 'react-intersection-observer'
import composeRefs from '../helpers/composeRefs'
import useWebFontLoaded from '../hooks/useWebFontLoaded'
import isEmpty from 'lodash/isEmpty'
import CustomEase from 'gsap/CustomEase'
import useWindowResize from '../hooks/useWindowResize'
import useDebouncedCallback from '../hooks/useDebouncedCallback'

gsap.registerPlugin(SplitText)
gsap.registerPlugin(CustomEase)

function useSplitWordsAnimation ({ ready = true, onComplete = () => {}, delay, duration, stagger, threshold }) {
  const [inViewRef, inView] = useInView({ threshold, triggerOnce: true })
  const [fontsReady, setFontsReady] = useState(false)
  const fontsReadyRef = useWebFontLoaded(useCallback(() => {
    setFontsReady(true)
  }, []))

  const locals = useRef({}).current

  const constructSplitText = useCallback((el) => {
    locals.split = new SplitText(el, { type: 'lines,words' })
    if (isEmpty(locals.split.words)) return
    gsap.set(locals.split.lines, { overflow: 'hidden', padding: '0 0 0.12em 0', marginBottom: '-0.12em' })
    if (!locals.animatedIn) {
      gsap.set(locals.split.words, { yPercent: 110, visibility: 'hidden', opacity: 1 })
      gsap.set(el, { visibility: 'visible' })
    }

    locals.el = el
  }, [])
  const destructSplitText = useCallback(() => {
    locals.split.revert()
    delete locals.targets
    delete locals.split
  }, [])

  useWindowResize(useDebouncedCallback(() => {
    if (!fontsReady || !ready) return
    if (locals.split) {
      destructSplitText()
    }
    constructSplitText(locals.el)
  }, 150, [fontsReady, ready, destructSplitText, destructSplitText]), false)

  const mountRef = useCallback((el) => {
    if (el) {
      if (!fontsReady || !ready) return
      constructSplitText(el)
    } else if (locals.split) {
      destructSplitText()
      delete locals.el
    }
  }, [locals, fontsReady, ready, constructSplitText, destructSplitText])

  const inViewAndReady = inView && fontsReady && ready
  useEffect(() => {
    if (!locals.el) {
      return
    }

    if (inViewAndReady) {
      locals.animatedIn = true
      gsap.to(
        locals.split.words,
        { ease: CustomEase.create('title', '.4, 0, .12, 1'), yPercent: 0, stagger, duration, opacity: 1, visibility: 'visible', onComplete, delay }
      )
    }
  }, [inViewAndReady])

  return composeRefs(inViewRef, fontsReadyRef, mountRef)
}

const SplitWordsAnimation = ({ children, srOnlyContent, ready = true, screenReaderClone = true, onComplete, delay, duration = 1.2, stagger = 0.14, threshold = 1 }) => {
  const classes = useStyles()
  const child = React.Children.only(children)
  const content = child.props.children
  const ref = useSplitWordsAnimation({ ready, onComplete, delay, duration, stagger, threshold })
  return React.cloneElement(child, {}, (
    <>
      {screenReaderClone && <div className='srOnly'>{srOnlyContent || content}</div>}
      <div className={classes.splitWords} ref={ref} aria-hidden='true'>
        {content}
      </div>
    </>
  ))
}

const useStyles = createUseStyles({
  splitWords: {
    visibility: 'hidden',
    '& a': {
      textDecoration: 'none'
    }
  },
  srOnly: {
    composes: ['srOnly']
  }
})

export default SplitWordsAnimation
