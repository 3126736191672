import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import theme, { span } from '../../style/theme'
import Section from '../Section'
import padStart from 'lodash/padStart'
import get from 'lodash/get'
import ResponsiveImage from '../ResponsiveImage'
import cn from 'clsx'
import { resolveLink } from '../../helpers/resolveLink'
import Link from '../Link'
import RichText from '../RichText'
import useFadeInAnimation from '../../animations/useFadeInAnimation'
import composeRefs from '../../helpers/composeRefs'
import ProgressLine, { useProgressLineClickAnimation } from '../ProgressLine'
import useHovering from '../../hooks/useHovering'
import { getFontScaling } from '../../style/textStyles'
import gsap from 'gsap'
import { data as cursorData } from '../Cursor'
import useDebouncedCallback from '../../hooks/useDebouncedCallback'
import useWindowResize from '../../hooks/useWindowResize'
import { SmoothScrollContext } from '../useSmoothScrollbar'
import lerp from 'lerp'
import afterFrame from '../../helpers/afterFrame'

const ListItem = ({ title, client, image, index, onSelectIndex, link, tagFilter, selectedIndex }) => {
  const classes = useStyles()
  const url = useMemo(() => {
    let url = get(link && resolveLink(link[0]), ['url'])
    if (url && tagFilter) {
      url = `${url}?filter=${tagFilter.slug}`
    }
    return url
  }, [link])
  const { ref, hovering } = useHovering(true)
  useEffect(() => {
    onSelectIndex(hovering ? index : null)
  }, [hovering])
  const { lineRef, clickRef } = useProgressLineClickAnimation()

  return (
    <li className={cn(classes.listItem, { fade: !hovering })} ref={ref}>
      <Link to={url} nonLinkTag='span' className={classes.listItemLink} ref={clickRef}>
        <span className={cn(classes.itemTitle, { noClient: !client })}>
          <span className={cn(classes.count, { noClient: !client })}>{padStart(index + 1, 2, '0')}</span>
          {title}
        </span>
        {client && <span className={classes.client}>{client}</span>}
        <ProgressLine hovering={hovering} className={classes.line} duration={0.5} ref={lineRef} />
      </Link>
    </li>
  )
}

const IndustriesSlice = ({ className, slice, last }) => {
  const { industries, title, copy } = slice
  const classes = useStyles()
  const [showImage, setShowImage] = useState(false)
  const [hoverIndex, setHoverIndex] = useState(null)
  const sectionRef = useRef()
  const imageContainerRef = useRef()
  const contentContainerRef = useRef()
  const [inViewRef] = useFadeInAnimation(useCallback(() => {
    return sectionRef.current.children
  }, []))
  const localsRef = useRef({
    currentY: 0
  })

  useEffect(() => {
    const locals = localsRef.current
    locals.timeline = gsap.timeline()
    locals.timeline.to(imageContainerRef.current, {
      clipPath: showImage ? 'polygon(0 0, 100% 0, 100% 100%, 0 100%)' : 'polygon(0 0, 100% 0, 100% 0%, 0 0%)',
      duration: 0.8,
      ease: 'expo.out',
      delay: showImage ? 0 : 0.1
    })
    return () => {
      if (locals.timeline) locals.timeline.kill()
    }
  }, [showImage])

  const onSelectIndex = useCallback(index => {
    if (index !== null) {
      setHoverIndex(index)
      setShowImage(true)
    } else {
      setShowImage(false)
    }
  }, [setHoverIndex])

  useWindowResize(useDebouncedCallback(() => {
    const locals = localsRef.current
    locals.windowWidth = window.innerWidth
    locals.windowHeight = window.innerHeight
    locals.imageBoundingBox = imageContainerRef.current.getBoundingClientRect()
    locals.imageContainerBoundingBox = imageContainerRef.current.parentNode.getBoundingClientRect()
    locals.contentBoundingBox = contentContainerRef.current.getBoundingClientRect()
  }, 250, []), true)

  const scrollContext = useContext(SmoothScrollContext)

  useEffect(() => {
    var animationId
    const loop = () => {
      const locals = localsRef.current
      if (locals.contentBoundingBox) {
        locals.contentBoundingBox = contentContainerRef.current.getBoundingClientRect()

        const yPosition = cursorData.current.y - locals.contentBoundingBox.top
        const yPercent = Math.max(0, Math.min(1, yPosition / locals.contentBoundingBox.height))
        const ySpace = Math.max(0, locals.imageContainerBoundingBox.height - locals.imageBoundingBox.height)

        const target = ySpace * yPercent
        locals.currentY = lerp(locals.currentY || 0, target, 0.1)

        imageContainerRef.current.style.transform = `translate(0px, ${locals.currentY}px)`
        afterFrame(
          () => { if (contentContainerRef.current) { locals.contentBoundingBox = contentContainerRef.current.getBoundingClientRect() } }
        )
      }
      animationId = window.requestAnimationFrame(loop)
    }
    animationId = window.requestAnimationFrame(loop)
    return () => {
      window.cancelAnimationFrame(animationId)
    }
  }, [scrollContext])

  return (
    <Section className={className} noBottomMargin={last}>
      <Section grid noBottomMargin fullWidth className={classes.container} ref={composeRefs(sectionRef, inViewRef)}>
        {title && <h1 className={classes.title}>{title}</h1>}
        {copy && <RichText className={classes.copy} content={copy.text} />}
        <div className={classes.lhs}>
          <div className={classes.imageContainer} ref={imageContainerRef}>
            {industries && industries.map(({ image }, i) => image ? (
              <ResponsiveImage key={i} {...image} aspect={577 / 635} className={cn(classes.image, { show: hoverIndex === i })} />
            ) : null)}
          </div>
        </div>
        <div className={classes.industries} ref={contentContainerRef}>
          <ul className={classes.list}>
            {industries && industries.map((industry, i) => (
              <ListItem key={i} index={i} {...industry} onSelectIndex={onSelectIndex} selectedIndex={hoverIndex} />
            ))}
          </ul>
        </div>
      </Section>
    </Section>
  )
}

const useStyles = createUseStyles({
  container: {
    alignItems: 'start',
    '& > *': {
      opacity: 0
    }
  },
  title: {
    gridColumn: 'span 2',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      gridColumn: '1 / span 6',
      marginBottom: theme.spacing(4)
    }
  },
  copy: {
    gridColumn: 'span 2',
    maxWidth: 550,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      gridColumn: '7 / span 6',
      marginBottom: theme.spacing(4)
    }
  },
  lhs: {
    display: 'none',
    position: 'relative',
    height: '100%',
    [theme.breakpoints.up('xs')]: {
      gridRow: 2,
      display: 'flex',
      gridColumn: '1 / span 6'
    }
  },
  imageContainer: {
    height: '36vw',
    position: 'relative',
    width: span(4, 'md')
  },
  image: {
    opacity: 0,
    width: span(4, 'md'),
    display: 'block',
    gridColumn: 'span 6',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    // transition: 'opacity 0.25s ease-in-out',
    '& picture:before': {
      display: 'none'
    },
    '&.show': {
      opacity: 1
    }
  },
  industries: {
    gridColumn: 'span 2',
    [theme.breakpoints.up('xs')]: {
      gridColumn: 'span 6',
      gridRow: 2
    }
  },
  list: {
    width: '100%',
    listStyle: 'none',
    margin: 0,
    padding: 0
  },
  listItem: {
    position: 'relative',
    transition: 'opacity 0.25s ease-in-out',
    '&.fade': {
      opacity: 0.5
    },
    '&:hover': {
      borderColor: '#222'
    },
    '&:first-child': {
      borderTop: [1, 'solid', theme.colors.borderDark]
    }
  },
  listItemLink: {
    padding: [theme.spacing(1), 0],
    display: 'flex',
    alignItems: 'flex-start',
    textDecoration: 'none'
  },
  itemTitle: {
    fontSize: 14,
    display: 'block',
    flexShrink: 0,
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      width: span(2, 'md')
    },
    '&.noClient': {
      display: 'flex',
      [theme.breakpoints.up('xs')]: {
        width: '100%'
      },
      ...getFontScaling(14)
    }
  },
  count: {
    marginRight: theme.spacing(0.5),
    fontSize: '0.6em',
    verticalAlign: 'super',
    '&.noClient': {
      fontSize: '1em',
      verticalAlign: 'middle',
      marginRight: theme.spacing(1),
      [theme.breakpoints.up('xs')]: {
        marginRight: theme.spacing(6)
      }
    }
  },
  client: {
    display: 'none',
    fontSize: 14,
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('xs')]: {
      display: 'block'
    },
    ...getFontScaling(14)
  },
  line: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0
  }
}, { name: 'IndustriesSlice' })

export default IndustriesSlice
