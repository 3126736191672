import React, { useState, useCallback, useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'clsx'
import reduce from 'lodash/reduce'
import map from 'lodash/map'
import forEach from 'lodash/forEach'
import gsap from 'gsap'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'
import theme from '../../style/theme'
import { getTickerMessages } from '../../redux/slices/content'
import TickerMessage from './TickerMessage'
import { getFontScaling } from '../../style/textStyles'

const PageIndicator = ({ dark, index, selectedIndex, onPageClick }) => {
  const classes = usePageIndicatorStyles({ dark })
  return (
    <span className={classes.pageIndicator}>
      <span className={classes.progress} />
    </span>
  )
}

const Ticker = ({ className, dark = true }) => {
  const ticker = useSelector(getTickerMessages)
  const classes = useStyles({ ticker, dark })
  const [selectedIndex, setSelectedIndex] = useState(0)
  const pagerRef = useRef()
  const timelineRef = useRef()

  useEffect(() => {
    const tl = gsap.timeline({ repeat: -1 })
    const progressElements = map(pagerRef.current.children, x => x.children[0])
    const obj = { progress: 0, page: 0 }
    tl.to(obj, {
      progress: ticker.length,
      duration: ticker.length * 4,
      ease: 'none',
      onUpdate: () => {
        var nextPage = Math.floor(obj.progress)
        if (nextPage !== obj.page) {
          setSelectedIndex(nextPage)
          obj.page = nextPage
        }
        forEach(progressElements, (element, index) => {
          const x = Math.min(Math.max(0, obj.progress - index), 1) * 100
          element.style.transform = `translate3d(${-100 + x}%, 0, 0)`
        })
      }
    })
    timelineRef.current = tl
    return () => {
      tl.kill()
      timelineRef.current = null
    }
  }, [])

  const mouseEnter = useCallback(() => {
    if (timelineRef.current) timelineRef.current.pause()
  }, [])

  const mouseLeave = useCallback(() => {
    if (timelineRef.current) timelineRef.current.play()
  }, [])

  const hasTicker = !isEmpty(ticker)

  if (!hasTicker) return null

  return (
    <div className={cn(className, classes.ticker)} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
      <div className={classes.pager} ref={pagerRef}>
        {ticker.map((props, k) => (
          <PageIndicator key={k} dark={dark} className={classes.pageIndicator} selectedIndex={selectedIndex} index={k} />
        ))}
      </div>
      <div className={classes.wrapper}>
        {ticker.map((props, k) => (
          <TickerMessage key={k} {...props} className={classes.message} selected={selectedIndex === k} />
        ))}
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  ticker: {
    width: '100%',
    ...theme.textStyles.baseMonoStyles,
    textTransform: 'uppercase',
    lineHeight: 1.6,
    fontSize: 10,
    ...getFontScaling(10)
  },
  pager: {
    position: 'relative',
    margin: [0, -4],
    display: 'flex'
  },
  wrapper: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    whiteSpace: 'nowrap'
  },
  message: ({ ticker }) => ({
    flexShrink: 0,
    whiteSpace: 'normal',
    width: '100%',
    display: 'block',
    textDecoration: 'none',
    opacity: 0,
    pointerEvents: 'none',
    position: 'relative',
    transition: 'opacity 0.25s ease-in-out',
    '&.selected': {
      opacity: 1,
      pointerEvents: 'all'
    },
    ...(reduce(ticker, (result, value, i) => {
      result[`&:nth-child(${i + 1})`] = {
        position: 'relative',
        left: `${i * -100}%`
      }
      return result
    }, {}
    ))
  })
}, { name: 'Ticker' })

const usePageIndicatorStyles = createUseStyles({
  pageIndicator: {
    margin: [0, 4],
    width: theme.spacing(1),
    height: 1,
    display: 'block',
    marginBottom: theme.spacing(1),
    position: 'relative',
    overflow: 'hidden',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: 'currentColor',
      opacity: ({ dark }) => dark ? 0.2 : 0.4
    }
  },
  progress: {
    backgroundColor: 'currentColor',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'block',
    transform: 'translate(-100%, 0)'
  }
})

export default Ticker
