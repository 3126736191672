import { useEffect } from 'react'

export default function useEscKey (callback) {
  useEffect(() => {
    function handleKey (e) {
      if (e.keyCode === 27) {
        callback()
      }
    }

    document.addEventListener('keydown', handleKey, false)
    return () => [document.removeEventListener('keydown', handleKey, false)]
  }, [callback])
}
