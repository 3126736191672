import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'clsx'
import range from 'lodash/range'
import theme from '../style/theme'

const Placeholder = ({ className, fill, type, tag = 'div', lines = 1 }) => {
  const classes = useStyles()
  const Tag = tag
  const lineRange = range(lines)
  return (
    <div>
      {lineRange.map((_, i) => <Tag key={i} className={cn(classes.placeholder, className, { fill }, type)} />)}
    </div>
  )
}

const useStyles = createUseStyles({
  placeholder: {
    backgroundColor: theme.colors.borderDark,
    position: 'relative',
    overflow: 'hidden',
    '&.fill': {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0
    },
    '&.title': {
      height: '1em',
      width: '50%',
      borderRadius: 2
    },
    '&.copy': {
      height: '1em',
      width: '100%',
      borderRadius: 2,
      '&:nth-child(3n+1)': {
        width: '80%'
      },
      '&:nth-child(3n+2)': {
        width: '100%'
      },
      '&:nth-child(3n+3)': {
        width: '40%'
      }
    },
    '&.text': {
      height: '1em',
      borderRadius: 2
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '-45%',
      height: '100%',
      width: '45%',
      backgroundImage: 'linear-gradient(to left, rgba(251,251,251, .05), rgba(251,251,251, .15), rgba(251,251,251, .3), rgba(251,251,251, .15), rgba(251,251,251, .05))',
      animation: '$loading 1s infinite'
    }
  },
  '@keyframes loading': {
    '0%': {
      left: '-45%'
    },
    '100%': {
      left: '100%'
    }
  }
}, { name: 'Placeholder' })

export default Placeholder
