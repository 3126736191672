import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import ResponsiveImage from '../ResponsiveImage'
import theme from '../../style/theme'
import Section from '../Section'
import { span } from '../../style/span'
import BackgroundVideo from './BackgroundVideo'
import cn from 'clsx'
import get from 'lodash/get'

const IMAGE_SIZE_THREE_QUARTERS = 'image-three-quarters'

const getAspect = (a) => {
  const split = a.split(':')
  return split[0] / split[1]
}

const PostImageSlice = ({ className, slice, nextSlice }) => {
  const {
    image,
    image_size: imageSize,
    top_label: topLabel,
    bottom_label: bottomLabel,
    desktop_video: desktopVideo,
    mobile_video: mobileVideo,
    narrowMarginBottom
  } = slice

  const removePadding = narrowMarginBottom || nextSlice?.type === 'post_image_slice' || nextSlice?.type === 'post_image_gallery'

  const classes = useStyles({ imageSize })

  const aspects = useMemo(() => {
    // eslint-disable-next-line
    const desktopAspect = desktopVideo ? getAspect(desktopVideo?.data?.aspect_ratio) : image.aspect
    const mobileAspect = get(mobileVideo, ['data', 'aspect_ratio'])
    return {
      aspect: desktopAspect,
      // eslint-disable-next-line
      mobileAspect: mobileAspect ? getAspect(mobileAspect) : desktopAspect
    }
  }, [image, desktopVideo, mobileVideo])

  return (
    <Section className={cn(classes.section, removePadding && classes.sectionRemovePadding)} grid>
      <div className={classes.container}>
        {topLabel && (
          <div className={classes.topLabel}>
            <span>{topLabel}</span>
          </div>
        )}
        <div className={classes.imageWrapper}>
          <ResponsiveImage {...image} {...aspects} />
          {desktopVideo?.url && (
            <BackgroundVideo
              desktopVideo={desktopVideo}
              mobileVideo={mobileVideo}
            />
          )}
        </div>
        {bottomLabel && (
          <div className={classes.bottomLabel}>
            <span>{bottomLabel}</span>
          </div>
        )}
      </div>
    </Section>
  )
}

const useStyles = createUseStyles({
  imageWrapper: {
    position: 'relative'
  },
  section: {
    rowGap: 0,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(6)
    }
  },
  sectionRemovePadding: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(1)
    }
  },
  container: {
    gridColumn: 'span 2',
    [theme.breakpoints.up('xs')]: {
      gridColumn: ({ imageSize }) => imageSize === IMAGE_SIZE_THREE_QUARTERS ? '5 / span 8' : 'span 12'
    }
  },
  topLabel: {
    extend: theme.textStyles.caption,
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.up('xs')]: {
      maxWidth: span(3, 'md')
    }
  },
  bottomLabel: {
    extend: theme.textStyles.bodySmall,
    marginTop: theme.spacing(0.5)
  }
}, { name: 'PostImageSlice' })

export default PostImageSlice
