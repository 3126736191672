import React, { useCallback, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'clsx'
import RichText from '../RichText'
import Section from '../Section'
import theme from '../../style/theme'
import SplitWordsAnimation from '../SplitWordsAnimation'
import composeRefs from '../../helpers/composeRefs'
import toArray from 'lodash/toArray'
import useFadeInAnimation from '../../animations/useFadeInAnimation'
import Clock from '../Clock'
import { getFontScaling } from '../../style/textStyles'
import ProgressLine from '../ProgressLine'
import { isCurrentBreakpointMobile } from '../../redux/slices/layout'
import { useSelector } from 'react-redux'

const Contact = ({ slice }) => {
  const classes = useStyles()
  const { title, copy, contactInfo } = slice
  const ref = useRef()
  const gridRef = useRef()
  const subtitleRef = useRef()
  const isMobile = useSelector(isCurrentBreakpointMobile)

  const [inViewRef] = useFadeInAnimation(
    useCallback(() => [subtitleRef.current, ...toArray(gridRef.current.children)], []),
    { stagger: 0.075, delay: 0.3 }
  )

  return (
    <Section className={cn(classes.section, 'clearFix')} ref={composeRefs(inViewRef, ref)} grid>
      <SplitWordsAnimation>
        <h1 className={classes.title}>{title}</h1>
      </SplitWordsAnimation>
      {copy && <RichText content={copy.text} className={classes.copy} ref={subtitleRef} />}
      {contactInfo && (
        <div className={classes.contactInfo} ref={gridRef}>
          {contactInfo.map(({ title, copy, showCurrentTime }, i) => (
            <div key={i} className={classes.contact}>
              <RichText className={classes.contactInfoCopy} content={copy.text} />
              {!showCurrentTime && <h6 className={classes.contactTitle}>{title}</h6>}
              {showCurrentTime && <Clock className={classes.contactTitle} />}
              <ProgressLine className={classes.line} delay={(isMobile ? 0 : (1 + (i * 0.2)))} />
            </div>
          ))}
        </div>
      )}
    </Section>
  )
}

const useStyles = createUseStyles({
  section: {
    paddingTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('xs')]: {
      paddingTop: theme.spacing(8),
      marginBottom: theme.spacing(12)
    }
  },
  contactInfo: {
    gridColumn: '1 / span 2',
    [theme.breakpoints.up('xs')]: {
      gridColumn: '9 / span 4'
    },
    [theme.breakpoints.up('md')]: {
      gridColumn: '9 / span 3'
    },
    '& > *': {
      opacity: 0
    }
  },
  title: {
    gridColumn: '1 / span 2',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('xs')]: {
      gridColumn: '1 / span 12',
      marginBottom: theme.spacing(4)
    }
  },
  copy: {
    gridColumn: '1 / span 2',
    marginBottom: theme.spacing(3),
    opacity: 0,
    [theme.breakpoints.up('xs')]: {
      gridColumn: '1 / span 6',
      marginBottom: 0
    }
  },
  contactInfoCopy: {
    marginBottom: theme.spacing(1),
    '& a': {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    '& p': {
      fontSize: 16,
      [theme.breakpoints.up('xs')]: {
        fontSize: 20
      },
      ...getFontScaling(20)
    }
  },
  contact: {
    // borderBottom: [1, 'solid', theme.colors.borderDark],
    paddingBottom: theme.spacing(0.5),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('xs')]: {
      marginBottom: theme.spacing(4),
      paddingBottom: theme.spacing(0.5)
    },
    '&:last-child': {
      marginBottom: 0
    }
  },
  contactTitle: {
    ...theme.textStyles.caption,
    marginBottom: 0,
    [theme.breakpoints.up('md')]: {
      fontSize: 10
    },
    ...getFontScaling(10)
  },
  line: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0
  }
}, { name: 'Contact' })

export default Contact
