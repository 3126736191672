import React, { useRef, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import defer from 'lodash/defer'
import cn from 'clsx'

import Section from '../../Section'
import AwardsColumn from './AwardsColumn'
import theme, { span } from '../../../style/theme'
import SliceHeader from '../SliceHeader'
import Slider from '../../Slider'
import gsap from 'gsap'
import filter from 'lodash/filter'

export const defaultOptions = {
  cellAlign: 'left',
  pageDots: false,
  contain: true,
  freeScroll: true,
  prevNextButtons: false
}

const AwardsSlice = ({ className, slice }) => {
  const { years } = slice
  const classes = useStyles()
  const flickityRef = useRef()
  const timelineRef = useRef()
  const containerRef = useRef()

  const onLoadMore = useCallback(() => {
    if (flickityRef.current) {
      defer(() => {
        flickityRef.current.resize()
      })
    }
  }, [])

  const onAwardEnter = useCallback((element, award) => {
    if (timelineRef.current) timelineRef.current.kill()
    const tl = gsap.timeline()
    const awardElements = filter(containerRef.current.querySelectorAll('.award'), x => x !== element)
    tl.to(awardElements, { opacity: 0.5, duration: 0.25 })
    tl.to(element, { opacity: 1, duration: 0.25 }, 0)
    timelineRef.current = tl
  }, [])

  const onAwardLeave = useCallback(() => {
    if (timelineRef.current) timelineRef.current.kill()
    const tl = gsap.timeline()
    const awardElements = containerRef.current.querySelectorAll('.award')
    tl.to(awardElements, { opacity: 1, duration: 0.25 })
    timelineRef.current = tl
  }, [])

  return (
    <Section className={classes.section} bottomBorder ref={containerRef}>
      <SliceHeader slice={slice} />
      <Slider
        className={classes.container}
        reloadOnUpdate={false}
        flickityRef={flickityRef}
        options={defaultOptions}
      >
        <div className={cn(classes.column, classes.spacer)} />
        {years.map((year, i) => (
          <AwardsColumn
            key={year.id}
            {...year}
            className={classes.column}
            onLoadMore={onLoadMore}
            onMouseEnter={onAwardEnter}
            onMouseLeave={onAwardLeave}
          />
        ))}
      </Slider>
    </Section>
  )
}

const useStyles = createUseStyles(
  {
    section: {
      overflowX: 'hidden'
    },
    title: {
      extend: theme.textStyles.h1,
      margin: [0, theme.getMargin(), 52],
      [theme.breakpoints.up('xs')]: {
        margin: [0, 0, 140]
      }
    },
    contentBody: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      [theme.breakpoints.up('xs')]: {
        display: 'grid',
        columnGap: theme.grid.gutter,
        rowGap: '16px',
        gridTemplateColumns: 'repeat(12, 2fr)'
      }
    },
    aside: {
      margin: [0, theme.getMargin(), 105],
      [theme.breakpoints.up('xs')]: {
        gridColumn: '1 / span 3',
        margin: 0
      }
    },
    asideButton: {
      display: 'inline-flex'
    },
    awardColumns: {
      width: `calc(100vw - ${theme.grid.margin * 2}px)`,
      [theme.breakpoints.up('xs')]: {
        width: 'auto',
        gridColumn: '4 / span 9'
      }
    },
    columns: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      overflowX: 'scroll',
      overflowY: 'hidden',
      [theme.breakpoints.up('xs')]: {
        gridColumn: '1 / span 9',
        display: 'grid',
        columnGap: theme.grid.gutter,
        gridTemplateColumns: '1fr 1fr 1fr',
        padding: 0,
        overflow: 'visible'
      },
      '& > *': {
        width: span(1.4),
        flex: '0 0 auto',
        marginRight: theme.grid.gutter,
        [theme.breakpoints.up('xs')]: {
          width: 'auto',
          marginRight: 0
        }
      }
    },
    container: {
      margin: [0, theme.spacing(-0.5)]
    },
    column: {
      width: '75%',
      margin: [0, theme.spacing(0.5)],
      [theme.breakpoints.up('sm')]: {
        width: `calc(25% - ${theme.spacing(1)}px)`
      }
    },
    spacer: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block'
      }
    }
  },
  { name: 'AwardsSlice' }
)

export default AwardsSlice
