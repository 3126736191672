import gsap from 'gsap'
import { useCallback, useEffect, useRef } from 'react'
// import { useInView } from 'react-intersection-observer'
import CustomEase from 'gsap/CustomEase'
import useIntersectionObserverCallback from '../hooks/useIntersectionObserverCallback'

gsap.registerPlugin(CustomEase)

const EASE = CustomEase.create('inview', '.17, .17, 0, 1')

export default (getElements, animationProps = {}, active = true, threshold = 0.2, scale = false) => {
  const localsRef = useRef({
    inView: false,
    fadedIn: false
  })

  const animateCallback = useCallback((entries, inView) => {
    localsRef.current.inView = inView

    const elements = getElements()

    // seems to be a bug in chrome where the intersection observer doesn't trigger immediately
    // if the elements are above the fold
    const aboveFold = elements[0]?.getBoundingClientRect()?.top < window.innerHeight || false
    if (aboveFold) localsRef.current.inView = true
    if (localsRef.current.fadedIn) return

    if ((inView || aboveFold) && active) {
      if (elements) {
        gsap.fromTo(elements, {
          opacity: 0,
          scaleY: scale ? 1.1 : 1,
          y: 40
        }, {
          opacity: 1,
          scaleY: 1,
          y: 0,
          duration: 1,
          ease: EASE,
          stagger: 0.15,
          ...animationProps
        })

        localsRef.current.fadedIn = true
      }
    }
  }, [active])
  const inViewRef = useIntersectionObserverCallback({ threshold, triggerOnce: true }, animateCallback)
  useEffect(() => {
    animateCallback(null, localsRef.current.inView)
  }, [active])

  return [inViewRef]
}
