import React, { useCallback, useRef, useEffect, forwardRef, useContext, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'clsx'
import { resolveLink } from '../helpers/resolveLink'
import Link from './Link'
import ArrowRightIcon from '../images/ArrowRightIcon'
import theme from '../style/theme'
import gsap from 'gsap'
import forEach from 'lodash/forEach'
import map from 'lodash/map'
import get from 'lodash/get'
import ColorContext from './ColorContext'
import ProgressLine from './ProgressLine'
import useHovering from '../hooks/useHovering'

const SplitCharText = ({ text }) => {
  return map(text, (char, i) => <span key={i} style={{ display: 'inline-block', whiteSpace: 'pre' }}>{char}</span>)
}

const ROTATIONS = 2

const MIN_SPEED = 0.15
const MAX_SPEED = 0.55

const ButtonText = forwardRef(({ iconClassName, text }, ref) => {
  const classes = useStyles({})
  return (
    <>
      <div className={classes.textContainer} ref={ref}>
        <span className={classes.buttonText}><SplitCharText text={text} /></span>
      </div>
      <ArrowRightIcon className={cn(classes.icon, iconClassName)} />
    </>
  )
})

const StyledButton = ({
  className,
  link,
  type = 'button',
  ariaLabel = null,
  onClick = null,
  buttonLabel = '',
  iconClassName
}) => {
  const { borderColor } = useContext(ColorContext)
  const classes = useStyles({ borderColor })
  const ref = useRef()
  const lineRef = useRef()
  const timelineRef = useRef()
  const text = buttonLabel || get(link, ['text'])

  useEffect(() => {
    if (ref.current && ref.current.children.length === 1) {
      ref.current.appendChild(ref.current.children[0].cloneNode(true))
      ref.current.appendChild(ref.current.children[0].cloneNode(true))
    }
  })

  const onMouseEnter = useCallback(() => {
    if (ref.current) {
      if (!timelineRef.current) {
        timelineRef.current = gsap.timeline({ repeat: 0 })
        var previousDuration = 0
        forEach(ref.current.children[0].children, (element, i) => {
          const charColumn = map(ref.current.children, x => x.children[i])
          var duration = gsap.utils.random(MIN_SPEED, MAX_SPEED)
          if (duration === previousDuration) duration -= 0.05
          timelineRef.current.to(charColumn, {
            duration: duration,
            ease: 'sine.out',
            y: `${ROTATIONS * 100}%`,
            modifiers: {
              y: (y) => {
                y = parseInt(y)
                y = y - (100 * Math.floor(y / 100))
                return y + '%'
              }
            }
          }, 0)
        })
      }
      timelineRef.current.seek(0)
    }
  }, [])

  const { ref: hoverRef, hovering } = useHovering(true)

  const onClickHandler = useCallback((e) => {
    if (onClick) onClick(e)
    if (lineRef.current) lineRef.current.animateClick()
  }, [onClick])

  const resolvedLink = useMemo(() => link ? resolveLink(link) : null, [link])
  if (link) {
    return (
      <Link
        className={cn(className, classes.button)}
        link={resolvedLink}
        onClick={onClickHandler}
        nonLinkTag='span'
        onMouseEnter={onMouseEnter}
        ref={hoverRef}
      >
        <ButtonText text={text} iconClassName={iconClassName} ref={ref} />
        <ProgressLine backgroundColor={borderColor} className={classes.line} hovering={hovering} ref={lineRef} />
      </Link>
    )
  }

  return (
    <button
      className={cn(className, classes.button)}
      type={type}
      onClick={onClickHandler}
      onMouseEnter={onMouseEnter}
      ref={hoverRef}
    >
      <ButtonText text={text} iconClassName={iconClassName} ref={ref} />
      <ProgressLine backgroundColor={borderColor} className={classes.line} hovering={hovering} ref={lineRef} />
    </button>
  )
}

const useStyles = createUseStyles({
  button: {
    extend: theme.textStyles.button,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textDecoration: 'none',
    position: 'relative',
    overflow: 'hidden',
    padding: [0, 0, '0.4em']
  },
  line: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    right: 0
  },
  buttonText: {
    lineHeight: 1.5,
    '&:nth-child(2)': {
      position: 'absolute',
      bottom: '100%'
    },
    '&:nth-child(3)': {
      position: 'absolute',
      bottom: '200%'
    }
  },
  icon: {
    display: 'block',
    width: 8,
    marginLeft: 5
  },
  textContainer: {
    display: 'flex',
    position: 'relative'
  }
}, { name: 'StyledButton' })

export default StyledButton
