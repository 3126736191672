import { useEffect, useRef } from 'react'
import detectIt from 'detect-it'

const useHlsPlayer = (playerRef, source, onReady) => {
  const hlsModuleRef = useRef()
  const hlsRef = useRef()
  const localsRef = useRef({ loadedMetadata: false })

  useEffect(() => {
    if (source) {
      const video = playerRef.current
      import('hls.js').then(Hls => {
        if (Hls.isSupported()) {
          const HLS = Hls.default
          hlsRef.current = new HLS({
            capLevelToPlayerSize: true,
            startLevel: detectIt.primaryInput === 'touch' ? undefined : 5
          })
          hlsRef.current.loadSource(source)
          hlsRef.current.attachMedia(playerRef.current)
        } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
          video.src = source
        }
      })

      return () => {
        if (hlsRef.current) {
          hlsRef.current.detachMedia()
        }
      }
    }
  }, [source])

  useEffect(() => {
    const video = playerRef.current
    const handleMetadataLoaded = () => {
      localsRef.current.loadedMetadata = true
      if (onReady) {
        onReady(video, hlsRef.current)
      }
    }

    import('hls.js').then(Hls => {
      hlsModuleRef.current = Hls

      if (hlsRef.current) {
        hlsRef.current.on(
          Hls.default.Events.MANIFEST_PARSED,
          handleMetadataLoaded
        )
      } else {
        video.addEventListener('loadedmetadata', handleMetadataLoaded)
      }
      if (localsRef.current.loadedMetadata) {
        handleMetadataLoaded()
      }
    })

    return () => {
      if (hlsRef.current && hlsModuleRef.current) {
        hlsRef.current.off(
          hlsModuleRef.current.default.Events.MANIFEST_PARSED,
          handleMetadataLoaded
        )
      } else {
        video.removeEventListener('loadedmetadata', handleMetadataLoaded)
      }
    }
  }, [onReady])

  return [hlsRef]
}

export default useHlsPlayer
