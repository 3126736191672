import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'clsx'
import first from 'lodash/first'
import compact from 'lodash/compact'
import { resolveLink } from '../../helpers/resolveLink'
import Link from '../Link'
import theme from '../../style/theme'
import { useSelector } from 'react-redux'
import { getCurrentRoutePath } from '../../redux/slices/location'

const getTopLevelUrlPath = (url) => first(compact(url.split('/')))

const MenuItem = ({ className, item, ...rest }) => {
  const currentPath = useSelector(getCurrentRoutePath)
  const { link } = item
  const { text } = link
  const resolvedLink = useMemo(() => resolveLink(link), [link])

  const isActive = resolvedLink.url === currentPath || getTopLevelUrlPath(resolvedLink.url) === getTopLevelUrlPath(currentPath)
  const classes = useStyles()
  return (
    <li role='none'>
      <Link
        className={cn(className, classes.menuLink, { active: isActive })}
        link={resolvedLink}
        role='menuitem'
        {...rest}
      >
        <span className={classes.text}>{text}</span>
      </Link>
    </li>
  )
}

const useStyles = createUseStyles({
  menuLink: {
    display: 'block',
    textDecoration: 'none',
    color: 'var(--foreground)',
    '&:hover, &.active': {
      opacity: 0.5
    }
  },
  text: {
    display: 'block',
    extend: theme.textStyles.bodySmall
  }
}, { name: 'MenuItem' })

export default MenuItem
