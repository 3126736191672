import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'

const AnchorPoint = ({ className, slice }) => {
  const { title, anchor_id: anchorId } = slice
  const classes = useStyles()

  return (
    <section className={classes.section} id={anchorId} data-title={title} />
  )
}

const useStyles = createUseStyles({
  section: {
    height: 0,
    paddingTop: theme.spacing(4),
    marginTop: theme.spacing(-4),
    pointerEvents: 'none'
  }
}, { name: 'AnchorPoint' })

export default AnchorPoint
