import React, { forwardRef, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'clsx'
import ChevronDown from '../../../images/ChevronDown'
import theme from '../../../style/theme'
import { useDispatch, useSelector } from 'react-redux'
import { isCaseStudiesOpen, openCaseStudies, closeCaseStudies } from '../../../redux/slices/layout'
import useModalButtonZIndex from '../useModalButtonZIndex'
import composeRefs from '../../../helpers/composeRefs'
import { isPostPage } from '../../../redux/slices/content'

const BrowseButton = forwardRef(({ className }, ref) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const open = useSelector(isCaseStudiesOpen)
  const postPage = useSelector(isPostPage)

  const onClick = useCallback(() => {
    dispatch(!open ? openCaseStudies() : closeCaseStudies())
  }, [open])

  const buttonRef = useModalButtonZIndex(open)

  return (
    <button
      className={cn(classes.browseButton, className)}
      onClick={onClick}
      aria-label={`${open ? 'Hide' : 'Reveal'} the browse articles menu`}
      ref={composeRefs(ref, buttonRef)}
    >
      <span className={classes.buttonText}>{postPage ? 'Browse Article' : 'Browse Case Studies'}</span>
      <ChevronDown className={cn(classes.icon, { open })} />
    </button>
  )
})

const useStyles = createUseStyles({
  browseButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    pointerEvents: 'all',
    color: 'currentColor',
    extend: theme.textStyles.bodySmall,
    '&:hover, &.active': {
      opacity: 0.5
    }
  },
  icon: {
    position: 'absolute',
    top: '50%',
    right: -8,
    transform: 'translateY(-50%) translateX(100%) rotate(0deg)',
    transformOrigin: 'center center',
    display: 'block',
    width: 8,
    marginLeft: 8,
    transition: 'transform 0.15s ease-in-out',
    '&.open': {
      transform: 'translateY(-50%) translateX(100%) rotate(180deg)'
    }
  }
}, { name: 'BrowseButton' })

export default BrowseButton
