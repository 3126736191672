import { useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import gsap from 'gsap'
import CustomEase from 'gsap/CustomEase'

import { isPreloading } from '../redux/slices/preload'
import useIntersectionObserverCallback from '../hooks/useIntersectionObserverCallback'

gsap.registerPlugin(CustomEase)

export default (
  getElements,
  active = true,
  threshold = 0,
  waitForPreload = false
) => {
  const preloading = useSelector(isPreloading)
  const fadedInRef = useRef(false)
  const localsRef = useRef({ inView: false })

  const animateCallback = useCallback((elements, inView) => {
    localsRef.current.inView = inView
    if (preloading && waitForPreload) return
    if (fadedInRef.current) return
    if (inView && active) {
      fadedInRef.current = true
      const elements = getElements()

      if (!elements) return

      const timeline = gsap.timeline()

      timeline.fromTo(elements, {
        opacity: 0
      }, {
        opacity: 1,
        duration: 1,
        ease: CustomEase.create('imageIn', '.17, .17, 0, 1')
      }, 0)
    }
  }, [getElements, active, preloading, waitForPreload])

  const inViewRef = useIntersectionObserverCallback({ threshold, triggerOnce: true }, animateCallback)
  useEffect(() => {
    animateCallback(null, localsRef.current.inView)
  }, [active])

  return [inViewRef]
}
