import React, { useRef, useState } from 'react'
import cn from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'
import {
  getNewsletterBusy,
  getNewsletterError,
  getNewsletterIsSubscribed,
  requestSubscribeActionCreator
} from '../../../redux/slices/newsletter'
import theme from '../../../style/theme'
import { expo, quad } from '../../../style/eases'
import StyledButton from '../../StyledButton'

const emailIsValid = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)

const SignupModal = ({ active, onClose, mobile }) => {
  const classes = useStyles({ active })

  const copyRef = useRef()
  const fieldsRef = useRef()

  const [firstName, setFirstName] = useState('')
  const [email, setEmail] = useState('')
  const [validationError, setValidationError] = useState('')

  const dispatch = useDispatch()
  const busy = useSelector(getNewsletterBusy)
  const subscribed = useSelector(getNewsletterIsSubscribed)
  const error = useSelector(getNewsletterError)

  const hasError = error || validationError

  const validate = () => {
    if (firstName === '') {
      setValidationError('Please provide your name!')
      return false
    }
    if (!emailIsValid(email)) {
      setValidationError('Please provide a valid e-mail!')
      return false
    }
    setValidationError('')
    return true
  }

  const onSubmit = (e, email) => {
    e.preventDefault()
    if (validate()) {
      dispatch(requestSubscribeActionCreator({ firstName, email }))
    }
  }

  return (
    <div className={cn(classes.signupModal, { active }, { error: hasError })}>
      <div className={cn(classes.inner, { active })}>
        <p className={classes.copy} ref={copyRef}>
          Insightful perspectives on design, process and leadership, delivered every so often.
        </p>
        <form className={classes.form} onSubmit={(e) => onSubmit(e, email)}>
          <div className={classes.fields} ref={fieldsRef}>
            <div className={classes.field}>
              <label htmlFor='name'>
                <span className='srOnly'>First Name</span>
                <input
                  className={cn(classes.input, { populated: firstName })}
                  name='firstName'
                  type='text'
                  placeholder='Your first name'
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  disabled={busy || subscribed}
                />
              </label>
            </div>
            <div className={classes.field}>
              <label htmlFor='email'>
                <span className='srOnly'>Email</span>
                <input
                  className={cn(classes.input, { populated: email })}
                  name='email'
                  type='email'
                  placeholder='Your email address'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={busy || subscribed}
                />
              </label>
            </div>
            {subscribed ? (
              <div className={classes.successMessage}>
                <span>Nice work.</span>
              </div>
            ) : (
              <StyledButton
                type='submit'
                aria-label='Sign up to join the Nightjar newsletter'
                buttonLabel='Submit'
                disabled={busy || subscribed}
              />
            )}
          </div>
        </form>
      </div>
      {hasError && (
        <div className={cn(classes.errorMessage, { active })}>
          <span>{validationError || 'Oops - something went wrong :('}</span>
        </div>
      )}
    </div>
  )
}

const useStyles = createUseStyles({
  signupModal: {
    position: 'absolute',
    width: '100%',
    top: -32,
    left: -40,
    display: 'flex',
    flexDirection: 'column',
    color: theme.colors.text,
    backgroundColor: theme.colors.beige,
    transform: 'translate3d(0, -100%, 0)',
    transition: `transform 1s ${expo.out}, border-color 0.25s ${quad.inOut}`,
    zIndex: 1,
    pointerEvents: 'all',
    overflow: 'hidden',
    border: [1, 'solid', 'transparent'],
    borderTop: 'none',
    '&.error': {
      border: [1, 'solid', theme.colors.error],
      borderTop: 'none'
    },
    '&.active': {
      transform: 'translate3d(0, 0, 0)'
    },
    [theme.breakpoints.down('xs')]: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }
  },
  inner: {
    height: '100%',
    transition: `transform 1s ${expo.out}`,
    transform: 'translate3d(0, 100%, 0)',
    '&.active': {
      transform: 'translate3d(0, 0, 0)'
    },
    padding: [theme.spacing(8), theme.grid.margin, 0],
    [theme.breakpoints.up('xs')]: {
      padding: [theme.spacing(8), theme.grid.gutter, theme.spacing(2)]
    }
  },
  header: {
    display: 'flex',
    [theme.breakpoints.up('xs')]: {
      display: 'none'
    }
  },
  modalContainer: {

  },
  copy: {
    extend: theme.textStyles.body,
    maxWidth: 240,
    marginBottom: theme.spacing(4)
  },
  input: {
    extend: theme.textStyles.bodySmall,
    width: '100%',
    display: 'block',
    padding: [0, 0, 8],
    border: 'none',
    borderBottom: [1, 'solid', 'rgba(0, 0, 0, 0.2)'],
    backgroundImage: 'none',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: theme.colors.text,
    lineHeight: '1',
    marginBottom: 32,
    borderRadius: 0,
    transition: `border-color 0.25s ${quad.inOut}, color 0.25s ${quad.inOut}, background-color 0.25s ${quad.inOut}`,
    '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus &:-webkit-autofill, &:-webkit-autofill:hover &:-webkit-autofill:focus': {
      boxShadow: '0 0 0px 1000px transparent inset',
      transition: 'background-color 5000s ease-in-out 0s',
      caretColor: 'currentColor'
    },
    '&:focus, &:active': {
      outline: 'none'
    },
    '&.populated': {
      borderColor: theme.colors.text
    },
    '&:disabled': {
      opacity: 0.2
    }
  },
  successMessage: {
    display: 'inline-block',
    paddingBottom: 4,
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      bottom: 0,
      left: 0,
      height: 1,
      backgroundColor: theme.colors.success
    },
    '& span': {
      display: 'block',
      color: theme.colors.success,
      extend: theme.textStyles.caption
    }
  },
  errorMessage: {
    backgroundColor: theme.colors.white,
    borderTop: [1, 'solid', theme.colors.error],
    padding: [8, theme.grid.gutter],
    '& span': {
      display: 'block',
      color: theme.colors.error,
      extend: theme.textStyles.caption
    }
  }
}, { name: 'SignupModal' })

export default SignupModal

export function SignupForm ({ className, joinText = 'Submit', ...props }) {
  const classes = useStyles()

  const fieldsRef = useRef()

  const [firstName, setFirstName] = useState('')
  const [email, setEmail] = useState('')
  const [validationError, setValidationError] = useState('')

  const dispatch = useDispatch()
  const busy = useSelector(getNewsletterBusy)
  const subscribed = useSelector(getNewsletterIsSubscribed)
  const error = useSelector(getNewsletterError)

  const hasError = error || validationError

  const validate = () => {
    if (firstName === '') {
      setValidationError('Please provide your name!')
      return false
    }
    if (!emailIsValid(email)) {
      setValidationError('Please provide a valid e-mail!')
      return false
    }
    setValidationError('')
    return true
  }

  const onSubmit = (e, email) => {
    e.preventDefault()
    if (validate()) {
      dispatch(requestSubscribeActionCreator({ firstName, email }))
    }
  }

  return (
    <form className={cn(classes.form, className)} onSubmit={(e) => onSubmit(e, email)}>
      <div className={classes.fields} ref={fieldsRef}>
        <div className={classes.field}>
          <label htmlFor='name'>
            <span className='srOnly'>First Name</span>
            <input
              className={cn(classes.input, { populated: firstName })}
              name='firstName'
              type='text'
              placeholder='Your first name'
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              disabled={busy || subscribed}
            />
          </label>
        </div>
        <div className={classes.field}>
          <label htmlFor='email'>
            <span className='srOnly'>Email</span>
            <input
              className={cn(classes.input, { populated: email })}
              name='email'
              type='email'
              placeholder='Your email address'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={busy || subscribed}
            />
          </label>
        </div>
        {subscribed ? (
          <div className={classes.successMessage}>
            <span>Nice work.</span>
          </div>
        ) : (
          <StyledButton
            type='submit'
            aria-label='Sign up to join the Nightjar newsletter'
            buttonLabel={joinText}
            disabled={busy || subscribed}
          />
        )}
        {hasError && (
          <div className={cn(classes.errorMessage)}>
            <span>{validationError || 'Oops - something went wrong :('}</span>
          </div>
        )}
      </div>
    </form>
  )
}
