import { request, cancel } from 'dom-helpers/animationFrame'

// The `scroll-behavior` library still depends on dom-helpers v3, but we are using v5, so this is just to provide a
// compatible interface for use by `scroll-behavior` via a webpack resolve alias.

function compat () {
  return request.apply(this, arguments)
}
compat.cancel = cancel

export default compat
