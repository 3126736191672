import React, { useCallback, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'
import cn from 'clsx'
import Color from 'color'
import get from 'lodash/get'

// import {
//   openOverlay,
//   closeOverlay,
//   isOverlayOpen
// } from '../redux/slices/layout'
import {
  getAcknowledgement,
  getFooter,
  getPageBackgroundColor,
  getSocialLinks
} from '../redux/slices/content'
import BigAnimatedLogo from './BigAnimatedLogo'
import Link from './Link'
import Section from './Section'
// import CloseIcon from '../images/CloseIcon'
import theme from '../style/theme'
import usePageColorTransition from './usePageColorTransition'
import { resolveLink } from '../helpers/resolveLink'
import ProgressLine from './ProgressLine'
// import AcknowledgementOverlay from './AcknowledgementOverlay'
import { getFontScaling } from '../style/textStyles'
// import Ticker from './Ticker'
import RichText from './RichText'
import ScrollingFooterLogo from './ScrollingFooterLogo'
import Clock from './Clock'
import { closeSignUpDialog, isCurrentBreakpointMobile, isSignUpDialogOpen, openSignUpDialog, openMenu } from '../redux/slices/layout'
// import useEscKey from '../hooks/useEscKey'

function Footer ({ className }) {
  const classes = useStyles()
  const socialLinks = useSelector(getSocialLinks)
  // const overlayIsOpen = useSelector(isOverlayOpen)
  const footer = useSelector(getFooter)
  const ref = useRef()
  const pageBackgroundColor = useSelector(getPageBackgroundColor)
  const acknowledgementOfCountry = useSelector(getAcknowledgement)
  const footerText = get(footer, ['footer_text', 'text'])
  const { locationText, locationUrl } = footer
  const dispatch = useDispatch()

  usePageColorTransition(ref)

  // const dispatch = useDispatch()

  // const showOverlay = useCallback(() => {
  //   dispatch(openOverlay())
  // }, [])

  // const hideOverlay = useCallback(() => {
  //   dispatch(closeOverlay())
  // }, [])

  // const toggleOverlay = useCallback(() => {
  //   overlayIsOpen ? hideOverlay() : showOverlay()
  // }, [overlayIsOpen, hideOverlay, showOverlay])

  // useEscKey(useCallback(() => {
  //   if (!overlayIsOpen) return
  //   dispatch(closeOverlay())
  // }, [overlayIsOpen, dispatch]))

  const privacyPolicyLink = useMemo(() => {
    return {
      ...resolveLink(get(footer, ['privacy_policy', 'link'])),
      shortName: get(footer, ['privacy_policy', 'short_name'])
    }
  }, [])

  const contactLink = useMemo(() => {
    return resolveLink(get(footer, ['contact_link', 0]))
  }, [footer])

  const lineColor = useMemo(() => {
    return pageBackgroundColor && Color(pageBackgroundColor).isDark()
      ? theme.colors.borderLight
      : theme.colors.borderDark
  }, [pageBackgroundColor])

  const mobile = useSelector(isCurrentBreakpointMobile)
  const newsletterOpen = useSelector(isSignUpDialogOpen)[mobile ? 'mobile' : 'desktop']

  const openNewsletter = useCallback(() => {
    const cb = newsletterOpen ? closeSignUpDialog({ mobile }) : openSignUpDialog({ mobile })
    dispatch(cb)
    if (mobile && !newsletterOpen) {
      dispatch(openMenu())
    }
  }, [dispatch, newsletterOpen, mobile])

  return (
    <Section
      tag='footer'
      ref={ref}
      className={cn(className, classes.footer)}
      noBottomMargin
      fullWidth
    >
      {/* <AcknowledgementOverlay /> */}
      <ProgressLine backgroundColor={lineColor} className={classes.line} />
      <div className={classes.footerLinks}>
        <div className={classes.footerContact}>
          {footerText && <RichText content={footerText} className={classes.footerText} />}
          {contactLink && (
            <Link link={contactLink} className={classes.footerLink}>
              <span className={classes.text}>{contactLink.text}</span>
            </Link>
          )}
        </div>
        <div className={classes.footerTicker}>
          <Clock />
        </div>
        <div className={classes.footerSocial}>
          {(locationUrl || locationText) &&
            <div className={classes.footerLocationLink}>
              {locationUrl ? <a href={locationUrl} target='_blank' rel='noopener noreferrer'>{locationText}</a> : locationText}
            </div>}
          <div className={classes.socialLinks}>
            {socialLinks?.map(social => {
              const { title, url } = social
              return (
                <a
                  className={classes.footerLink}
                  key={url}
                  href={url}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <span className={classes.desktopText}>{title}</span>
                </a>
              )
            })}
            <button onClick={openNewsletter}>
              <span className={cn(classes.desktopText, 'newsletter-btn-footer')}>
                Newsletter
              </span>
            </button>
            <Link link={privacyPolicyLink} className={classes.footerLink}>
              <span className={classes.desktopText}>
                {privacyPolicyLink.text}
              </span>
            </Link>
          </div>
        </div>
        <div className={classes.footerAck}>
          {acknowledgementOfCountry}
          <ProgressLine backgroundColor={lineColor} className={classes.acknowledgementLine} />
        </div>
        <div className={classes.logoWrapper}>
          <BigAnimatedLogo className={classes.desktopLogo} />
          <ScrollingFooterLogo className={classes.mobileLogo} lineColor={lineColor} />
        </div>
      </div>
    </Section>
  )
}

const useStyles = createUseStyles(
  {
    footer: {
      padding: [0, 0, theme.spacing(1)]
    },
    line: {
      margin: [0, theme.spacing(1), theme.spacing(1)],
      [theme.breakpoints.up('xs')]: {
        margin: [0, theme.grid.margin, 20]
      }
    },
    logoWrapper: {
      gridRow: 4,
      gridColumn: '1 / -1',
      alignSelf: 'flex-end',
      height: '20vh',
      [theme.breakpoints.up('xs')]: {
        gridColumn: '1 / span 12',
        height: 'auto'
      }
    },
    footerText: {
      maxWidth: '20em',
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up('xs')]: {
        marginBottom: theme.grid.margin
      }
    },
    footerLocationLink: {
      marginBottom: theme.grid.margin,
      '& a, & button': {
        whiteSpace: 'pre',
        textDecoration: 'none',
        '&:hover': {
          opacity: 0.3
        }
      }
    },
    footerLinks: {
      padding: [0, theme.grid.margin],
      fontSize: 12,
      display: 'grid',
      rowGap: '10px',
      columnGap: '8px',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr 1fr auto 20vh',
      minHeight: 'calc(100vh - 84px)',
      overflow: 'hidden',
      [theme.breakpoints.up('xs')]: {
        columnGap: theme.grid.gutter,
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridTemplateRows: 'unset',
        overflow: 'visible'
      },
      ...getFontScaling(12)
    },
    text: {
      extend: theme.textStyles.bodySmall,
      color: 'currentColor'
    },
    desktopText: {
      composes: ['$text']
    },
    // mobileText: {
    //   composes: ['$text'],
    //   display: 'block',
    //   [theme.breakpoints.up('xs')]: {
    //     display: 'none'
    //   }
    // },
    footerContact: {
      // display: 'flex',
      // gridTemplateColumns: '1fr 1fr',
      // alignItems: 'flex-end',
      gridRow: 3,
      gridColumn: '1 / -1',
      paddingBottom: theme.spacing(3),
      '& > *:last-child': {
        textAlign: 'right',
        [theme.breakpoints.up('xs')]: {
          textAlign: 'left'
        }
      },
      [theme.breakpoints.up('xs')]: {
        paddingBottom: 0,
        display: 'block',
        gridRow: 1,
        gridColumn: '1 / span 3'
      }
    },
    footerAck: {
      gridColumn: '1 / -1',
      gridRow: 2,
      textAlign: 'left',
      fontSize: 12,
      alignSelf: 'flex-end',
      [theme.breakpoints.up('xs')]: {
        padding: 0,
        gridRow: 1,
        gridColumn: 'span 3',
        maxWidth: '24em',
        alignSelf: 'flex-start'
      }
    },
    acknowledgementLine: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('xs')]: {
        display: 'none'
      }
    },
    footerTicker: {
      gridColumn: 2,
      gridRow: 1,
      display: 'none',
      // justifySelf: 'center',
      fontSize: '10px',
      [theme.breakpoints.up('xs')]: {
        display: 'block',
        gridRow: 1,
        gridColumn: 'span 3',
        maxWidth: '20em'
      }
    },
    footerSocial: {
      gridColumn: '1 / -1',
      gridRow: '1',
      // width: 'auto',
      // justifySelf: 'end',
      overflow: 'hidden',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: theme.grid.gutter,
      [theme.breakpoints.up('xs')]: {
        display: 'block',
        justifySelf: 'start',
        width: 'auto',
        gridRow: '1',
        gridColumn: 'span 3'
      },
      '& button:hover': {
        opacity: 0.3
      }
    },
    socialLinks: {
      display: 'flex',
      flexDirection: 'column',
      margin: [0, -4],
      '& button': {
        appearance: 'none',
        color: 'inherit',
        textAlign: 'left'
      },
      textAlign: 'left',
      '& > *': {
        margin: [0, 4]
      },
      [theme.breakpoints.up(350)]: {
        margin: [0, -8],
        '& > *': {
          margin: [0, 8]
        }
      },
      [theme.breakpoints.up('xs')]: {
        margin: [0, -24],
        justifyContent: 'flex-start',
        '& > *': {
          margin: [0, 24]
        }
      }
    },
    footerLink: {
      // color: 'currentColor',
      color: 'var(--foreground)',
      textDecoration: 'none',
      // textAlign: 'left',
      display: 'inline-block',
      '&:hover': {
        opacity: 0.3
      }
    },
    overlayBtn: {
      display: 'block',
      position: 'relative',
      width: '100%',
      [theme.breakpoints.up('xs')]: {
        textAlign: 'left',
        marginLeft: 0
      },
      '&.open': {
        zIndex: 999999999,
        color: theme.colors.white,
        '&:hover': {
          opacity: 0.8
        }
      }
    },
    overlayButtonText: {
      extend: theme.textStyles.bodySmall,
      position: 'relative'
    },
    icon: {
      position: 'absolute',
      bottom: 4,
      transform: 'translateX(-100%)',
      display: 'block',
      width: 8,
      marginRight: 8,
      opacity: 0,
      left: -4,
      [theme.breakpoints.up('xs')]: {
        left: -8
      },
      '&.open': {
        opacity: 1
      }
    },
    desktopLogo: {
      display: 'none',
      [theme.breakpoints.up('xs')]: {
        display: 'block',
        // transform: 'translateY(10%)'
        marginBottom: -10
      }
    },
    mobileLogo: {
      display: 'block',
      [theme.breakpoints.up('xs')]: {
        display: 'none'
      }
    }
  },
  { name: 'Footer' }
)

export default Footer
