import React, {
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react'
import { createUseStyles } from 'react-jss'
import { push } from 'redux-first-router'

import Section from '../../Section'
import theme from '../../../style/theme'
import StyledButton from '../../StyledButton'
import composeRefs from '../../../helpers/composeRefs'
import { getVideoAspect } from '../../../helpers/video'
import { resolveLink } from '../../../helpers/resolveLink'
import { useCursorHover } from '../../Cursor'
import { getFontScaling } from '../../../style/textStyles'
import useImageInAnimation from '../../../animations/useImageInAnimation'
import VideoSource from './VideoSource'

const ProjectVideo = forwardRef(({ project }, ref) => {
  const video = project.mobile_video ?? project.desktop_video
  const mobileAspect = getVideoAspect(video)
  const desktopAspect = getVideoAspect(project.desktop_video)
  const [canPlay, setCanPlay] = useState(false)
  const containerRef = useRef()
  const videoContainerRef = useRef()

  const classes = useStyles({ mobileAspect, desktopAspect })

  const { title, client, link, industry } = project

  const [inViewRef] = useImageInAnimation(
    useCallback(() => videoContainerRef.current, []),
    canPlay,
    0,
    true
  )

  const resolvedLink = useMemo(() => resolveLink(link[0]), [link])
  const onClick = useCallback(() => push(resolvedLink.url), [resolvedLink])
  const onCanPlay = useCallback(() => setCanPlay(true), [])
  // eslint-disable-next-line
  const hoverLabel = useMemo(() => link[0]?.reference?.hover_label, [link])

  useCursorHover(videoContainerRef, hoverLabel || title)

  return (
    <div className={classes.slide} ref={composeRefs(ref, containerRef)}>
      <Section
        tag='div'
        noBottomMargin
        grid
        className={classes.slideGrid}
        ref={inViewRef}
      >
        <div className={classes.videoWrapper}>
          <div
            className={classes.videoContainer}
            ref={videoContainerRef}
            onClick={onClick}
          >
            <VideoSource
              className={classes.video}
              desktopVideo={project.desktop_video}
              mobileVideo={project.mobile_video}
              canPlay={canPlay}
              onCanPlay={onCanPlay}
            />
          </div>
        </div>
        <div className={classes.industry}>{industry}</div>
        <div className={classes.client}>{client}</div>
        <div className={classes.project}>{title}</div>
        <div className={classes.linkContainer}>
          <StyledButton link={link[0]} className={classes.link} />
        </div>
      </Section>
    </div>
  )
})

const useStyles = createUseStyles(
  {
    slide: {
      backgroundColor: theme.colors.black,
      color: theme.colors.white,
      fontFamily: theme.fonts.mono,
      textTransform: 'uppercase',
      fontSize: 12,
      ...getFontScaling(12)
    },
    slideGrid: {
      padding: [theme.spacing(4), 0, theme.spacing(1)],
      gridTemplateRows: '1fr min-content min-content min-content',
      [theme.breakpoints.up('xs')]: {
        gridTemplateRows: '1fr min-content'
      }
    },
    videoWrapper: {
      position: 'relative',
      display: 'block',
      width: '100%',
      gridColumn: '1 / span 2',
      gridRow: '1',
      marginBottom: theme.spacing(2),
      overflow: 'hidden',
      [theme.breakpoints.up('xs')]: {
        marginBottom: theme.spacing(4),
        gridColumn: '2 / span 10'
      }
    },
    videoContainer: {
      cursor: 'pointer',
      opacity: 0,
      position: 'relative',
      // transform: 'scale(1.3)',
      width: '100%',
      paddingTop: ({ mobileAspect }) => `${mobileAspect * 100}%`,
      [theme.breakpoints.up('xs')]: {
        paddingTop: ({ desktopAspect }) => `${desktopAspect * 100}%`
      }
    },
    video: {
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '100%'
    },
    client: {
      opacity: 0.5,
      [theme.breakpoints.up('xs')]: {
        gridColumn: 'span 3'
      }
    },
    project: {
      opacity: 0.5,
      gridRow: 3,
      gridColumn: 2,
      [theme.breakpoints.up('xs')]: {
        gridRow: 2,
        gridColumn: 'span 3'
      }
    },
    industry: {
      [theme.breakpoints.up('xs')]: {
        gridColumn: 'span 3'
      }
    },
    linkContainer: {
      gridColumn: '2',
      [theme.breakpoints.up('xs')]: {
        gridColumn: '12'
      }
    },
    link: {
      fontSize: 12,
      fontFamily: theme.fonts.mono,
      whiteSpace: 'nowrap',
      ...getFontScaling(12)
    },
    nextButton: {
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '20%'
    },
    previousButton: {
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      width: '20%'
    }
  },
  { name: 'ProjectVideo' }
)

export default ProjectVideo
