import React, { forwardRef, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import SliceHeader from './SliceHeader'
import Section from '../Section'
import theme, { span } from '../../style/theme'
import { resolveLink } from '../../helpers/resolveLink'
import Link from '../Link'
import ProgressLine, { useProgressLineClickAnimation } from '../ProgressLine'
import useHovering from '../../hooks/useHovering'
import get from 'lodash/get'
import composeRefs from '../../helpers/composeRefs'

const Partner = (props) => {
  const {
    testimonial,
    person,
    position,
    company,
    link
  } = props
  const classes = useStyles()
  const resolvedLink = useMemo(() => link && resolveLink(get(link, [0])), [link])
  const { ref, hovering } = useHovering(resolvedLink)
  const { lineRef, clickRef } = useProgressLineClickAnimation()
  return (
    <Link link={resolvedLink} nonLinkTag='div' className={classes.partner} ref={composeRefs(ref, clickRef)}>
      <div className={classes.top}>
        <p className={classes.testimonial}>{testimonial}</p>
      </div>
      <div className={classes.middle}>
        <span className={classes.person}>{person},</span>
        <span className={classes.position}>{position}</span>
      </div>
      <div className={classes.bottom}>
        <span className={classes.company}>{company}</span>
      </div>
      <ProgressLine hovering={hovering} ref={lineRef} />
    </Link>
  )
}

const PartnersGrid = forwardRef(({ slice, tag = 'div', serializers, last }, ref) => {
  const {
    partners
  } = slice
  const classes = useStyles()

  return (
    <Section bottomBorder={!last}>
      <SliceHeader slice={slice} noBottomMargin>
        <div className={classes.partners}>
          {partners && partners.map(partner => <Partner key={partner.id} {...partner} />)}
        </div>
      </SliceHeader>
    </Section>
  )
})

const useStyles = createUseStyles({
  partners: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    overflowX: 'auto',
    margin: [theme.spacing(3), theme.spacing(-1), 0],
    gridColumn: '1 / span 2',
    [theme.breakpoints.up('xs')]: {
      margin: [theme.spacing(5), 0, theme.spacing(-6)],
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridGap: theme.grid.gutter,
      gridColumn: '7 / span 6'
    }
  },
  partner: {
    textDecoration: 'none',
    flex: '0 0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '80%',
    margin: [0, 0, 0, theme.spacing(1)],
    paddingRight: theme.spacing(1),
    position: 'relative',
    [theme.breakpoints.up('xs')]: {
      width: 'auto',
      paddingRight: theme.spacing(4),
      margin: [0, 0, theme.spacing(6)]
    }
  },
  middle: {
    margin: [theme.spacing(2), 0],
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  testimonial: {
    extend: theme.textStyles.body,
    marginBottom: 0
  },
  person: {
    display: 'block',
    extend: theme.textStyles.bodySmall,
    opacity: 0.5
  },
  position: {
    display: 'block',
    extend: theme.textStyles.bodySmall,
    opacity: 0.5
  },
  bottom: {
    ...theme.textStyles.caption,
    marginBottom: theme.spacing(0.5)
  }
}, { name: 'PartnersGrid' })

export default PartnersGrid
