import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'clsx'
import theme from '../style/theme'
import { useSliceColor } from './useSliceColor'

export default ({ backgroundColor, className, ...props }) => {
  const classes = useStyles()
  var sliceRef = useSliceColor('background', backgroundColor, false, !!backgroundColor)
  return (
    <div ref={sliceRef} className={cn(className, backgroundColor && classes.withBackgroundColor)} style={{ backgroundColor }} {...props} />
  )
}

const useStyles = createUseStyles({
  withBackgroundColor: {
    padding: [theme.spacing(3), 0],
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      padding: [theme.spacing(4), 0],
      marginBottom: theme.spacing(6)
    }
  }
})
