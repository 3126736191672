import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { closeShowreelDialog, isShowreelDialogOpen, openShowreelDialog } from '../../../redux/slices/layout'
import DialogToggleButton from '../DialogToggleButton'
import useModalButtonZIndex from '../useModalButtonZIndex'

const ShowreelButton = ({ className, text, mobile }) => {
  const open = useSelector(isShowreelDialogOpen)
  const dispatch = useDispatch()

  const onClose = useCallback(() => {
    dispatch(closeShowreelDialog())
  }, [])

  const onOpen = useCallback(() => {
    dispatch(openShowreelDialog())
  }, [])

  const ref = useModalButtonZIndex(open)

  return (
    <DialogToggleButton
      className={className}
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      ariaLabel={`${open ? 'Show' : 'Hide'} the showreel dialog`}
      text={text || 'Showreel'}
      overlayIsDark
      closeOnOutsideClick={false}
      back={mobile}
      ref={ref}
    />
  )
}

export default ShowreelButton
