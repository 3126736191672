import React, { forwardRef, useState, useCallback, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'clsx'
import ResponsiveImage from '../ResponsiveImage'
import Flickity from 'react-flickity-component'
import get from 'lodash/get'
import Section from '../Section'
import theme from '../../style/theme'
import { getFontScaling } from '../../style/textStyles'

const flickityOptions = {
  prevNextButtons: false,
  pageDots: false,
  freeScroll: true,
  contain: false,
  cellAlign: 'left'
}

const getNumberedAffix = index => `${index <= 9 ? `0${index}` : index}`

const ImageSlider = forwardRef(({ className, slice, tag = 'div', serializers }, ref) => {
  const { slides, backgroundColor } = slice
  const bgColor = get(backgroundColor, ['hex'], theme.colors.background)
  const foregroundColor = theme.getForegroundColor(bgColor)
  const classes = useStyles({ foregroundColor, backgroundColor: bgColor })
  const flickityRef = useRef()
  const [currentSlide, setCurrentSlide] = useState(0)

  const setFlickityRef = useCallback((flickity) => {
    flickityRef.current = flickity
    if (flickity) {
      flickity.on('change', (index) => setCurrentSlide(index))
      flickity.on('dragMove', (event, pointer, moveVector) => {
        const dragMoveEvent = new window.CustomEvent('flickity-dragMove', { detail: event })
        window.dispatchEvent(dragMoveEvent)
      })
    }
  }, [])

  return (
    <Section className={cn(classes.section, className)} fullWidth>
      <div className={classes.container}>
        <div className={classes.slideCount}>
          <span>{`${getNumberedAffix(currentSlide + 1)} / ${getNumberedAffix(slides.length)}`}</span>
        </div>
        <div className={classes.sliderWrapper}>
          <Flickity
            flickityRef={setFlickityRef}
            className={classes.slider}
            options={flickityOptions}
            static
          >
            {slides && slides.map(slide => (
              <div key={slide.id} className={classes.sliderSlide}>
                <ResponsiveImage
                  {...slide.image}
                />
              </div>
            ))}
          </Flickity>
        </div>
        <div className={classes.slideSubtitle}>
          <span>{slides[currentSlide].subtitle}</span>
        </div>
      </div>
    </Section>
  )
})

const useStyles = createUseStyles({
  container: {
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    color: ({ foregroundColor }) => foregroundColor,
    padding: [theme.spacing(2), theme.grid.margin],
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4)
    }
  },
  slideCount: {
    ...theme.textStyles.caption,
    marginBottom: 8,
    [theme.breakpoints.down('md')]: {
      fontSize: 12
    },
    ...getFontScaling(12)
  },
  slideSubtitle: {
    fontFamily: theme.fonts.mono,
    fontWeight: theme.fonts.monoFontWeight,
    textTransform: 'uppercase',
    lineHeight: 1.6,
    marginTop: 8,
    fontSize: 10,
    [theme.breakpoints.up('md')]: {
      marginTop: 16,
      fontSize: 12
    }
  },
  sliderSlide: {
    '& >div': {
      width: '85vw',
      height: '48vw' // Creates a 16:9 aspect ratio
    }
  },
  slider: {
    display: 'flex',
    outline: 'none',
    alignItems: 'flex-end',
    '&.flickity-enabled': {
      position: 'relative'
    },
    '& .flickity-enabled:focus': {
      outline: 'none'
    },
    '& .flickity-viewport': {
      position: 'relative',
      width: '100%',
      height: '100%',
      outline: 'none'
    },
    '& .flickity-slider': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      outline: 'none',
      display: 'flex',
      alignItems: 'flex-end',
      '& >div': {
        margin: [0, 8]
      }
    },
    '& .flickity-enabled.is-draggable': {
      'user-select': 'none'
    },
    '& .flickity-enabled.is-draggable .flickity-viewport': {
      cursor: 'grab'
    },
    '& .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down': {
      cursor: 'grabbing'
    }
  }
}, { name: 'ImageSlider' })

export default ImageSlider
