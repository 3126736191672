import React, { useRef, useEffect, useCallback, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { getSocialLinks, getMenuItems, getMobileMenuCopy } from '../redux/slices/content'
import theme from '../style/theme'
import cn from 'clsx'
import { isMenuOpen, closeMenu, isSignUpDialogOpen, isCurrentBreakpointMobile } from '../redux/slices/layout'
import { expo } from '../style/eases'
import { resolveLink } from '../helpers/resolveLink'
import Link from './Link'
import useBodyScrollLock from '../hooks/useBodyScrollLock'
import RichText from './RichText'
import gsap from 'gsap'
import SignupButton from './Header/NewsletterSignup/SignupButton'
import ShowreelButton from './Header/Showreel/ShowreelButton'
import Ticker from './Ticker'
import ProgressLine from './ProgressLine'
import useHovering from '../hooks/useHovering'
import useOnClickOutside from '../hooks/useOnClickOutside'
import { getFontScaling } from '../style/textStyles'
import useEscKey from '../hooks/useEscKey'

const MenuItem = ({ classes, menuItem }) => {
  const { ref: hoveringRef, hovering } = useHovering(true)
  const link = useMemo(() => resolveLink(menuItem.link), [menuItem.link])

  return (
    <div className={classes.menuLinkContainer}>
      <Link
        className={classes.menuLink}
        link={link}
        ref={hoveringRef}
      >
        <span>{menuItem.link.text}</span>
        <ProgressLine backgroundColor={theme.colors.black} color={theme.colors.white} height={1} hovering={hovering} className={classes.line} />
      </Link>
    </div>
  )
}

function MobileMenu ({ showPartners }) {
  const classes = useStyles()
  const open = useSelector(isMenuOpen)
  const mobileMenuCopy = useSelector(getMobileMenuCopy)
  const menu = useSelector(getMenuItems)
  const isMobile = useSelector(isCurrentBreakpointMobile)
  const socialLinks = useSelector(getSocialLinks)
  const ref = useBodyScrollLock(open)
  const menuNavRef = useRef()
  const menuFooterRef = useRef()
  const menuOtherRef = useRef()
  const tickerRef = useRef()
  const isMobileSignUpOpen = useSelector(isSignUpDialogOpen).mobile
  const dispatch = useDispatch()

  const outsideRef = useRef()
  useOnClickOutside(outsideRef, useCallback((e) => {
    if (e.srcElement && e.srcElement.id !== 'menu-button') {
      dispatch(closeMenu())
    }
  }), open && !isMobile)

  useEscKey(useCallback(() => {
    if (!open) return
    dispatch(closeMenu())
  }, [open, dispatch]))

  useEffect(() => {
    if (open) {
      const timeline = gsap.timeline()
        .set(menuNavRef.current.children, { opacity: 0 })
        .set([menuOtherRef.current, menuFooterRef.current, tickerRef.current], { opacity: 0 })
        .to(menuNavRef.current.children, { ease: 'sine.out', stagger: 0.08, duration: 1.2, opacity: 1, visibility: 'visible', delay: 0.1 })
        .to(tickerRef.current, { ease: 'sine.out', duration: 1.2, opacity: 1, delay: 0.6 }, 0)
        .to([menuOtherRef.current, menuFooterRef.current], { ease: 'sine.out', stagger: 0.08, duration: 1.2, opacity: 1, delay: 0.6 }, 0)
      return () => {
        timeline.kill()
      }
    }
  }, [open])

  return (
    <div className={cn(classes.container, { open })} ref={outsideRef}>
      <div className={classes.innerContainer} ref={ref}>
        <div className={classes.menuBody}>
          <div className={classes.menuNav} ref={menuNavRef}>
            {menu && menu.map(menuItem => (
              <MenuItem key={menuItem.id} menuItem={menuItem} classes={classes} />
            ))}
          </div>
          <nav className={classes.otherMenu} ref={menuOtherRef}>
            <ul role='menu' className={classes.otherMenuList}>
              <li role='menuitem'><SignupButton className={cn(classes.otherMenuItem, { open: isMobileSignUpOpen })} mobile /></li>
              <li role='menuitem'><ShowreelButton className={classes.otherMenuItem} /></li>
            </ul>
          </nav>
          <div className={classes.ticker} ref={tickerRef}>
            <Ticker dark={false} />
          </div>
          <footer className={classes.footer} ref={menuFooterRef}>
            {mobileMenuCopy && <RichText content={mobileMenuCopy.text} className={classes.copy} />}
            <div className={classes.socialLinks}>
              {socialLinks && socialLinks.map(social => {
                const { short_name: shortName, url, title } = social
                return (
                  <a
                    className={classes.footerLink}
                    key={url}
                    href={url}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <span className={classes.socialShortName}>{shortName}</span>
                    <span className={classes.socialFullName}>{title}</span>
                  </a>
                )
              })}
            </div>
          </footer>
        </div>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    height: '100vh',
    backgroundColor: theme.colors.black,
    color: theme.colors.white,
    transition: `transform 1.2s ${expo.out}`,
    transform: 'translateY(-100%)',
    overflow: 'hidden',
    '&.open': {
      transform: 'translateY(0)'
    },
    '&.open $innerContainer': {
      transform: 'translateY(0)'
    },
    [theme.breakpoints.up('xs')]: {
      left: 'calc(66.66666% + 5px)'
    }
  },
  innerContainer: {
    overflow: 'auto',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'flex-start',
    transition: `transform 1.2s ${expo.out}`,
    transform: 'translateY(100%)',
    padding: [theme.spacing(8), theme.spacing(1), theme.spacing(6)],
    [theme.breakpoints.up('xs')]: {
      padding: [theme.spacing(4), theme.spacing(1), theme.spacing(1)]
    },
    [theme.breakpoints.up('lg')]: {
      padding: [theme.spacing(6), theme.spacing(1), theme.spacing(1)]
    }
  },
  menuNav: {
    display: 'flex',
    flexDirection: 'column'
  },
  menuLinkContainer: {
    visibility: 'hidden',
    overflow: 'hidden',
    transform: 'translateY(25%)',
    position: 'relative',
    opacity: 0
  },
  menuLink: {
    ...theme.textStyles.baseHeadingsStyles,
    marginBottom: theme.spacing(2),
    textDecoration: 'none',
    display: 'inline-block',
    position: 'relative',
    fontSize: 32,
    lineHeight: 0.75,
    [theme.breakpoints.up('lg')]: {
      lineHeight: 1.2,
      fontSize: 40
    }
  },
  line: {
    pointerEvents: 'none',
    position: 'absolute',
    bottom: -4,
    left: 0,
    right: 0,
    display: 'none',
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      bottom: -8
    },
    [theme.breakpoints.up('lg')]: {
      display: 'block',
      bottom: -4
    }
  },
  menuBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    minHeight: '100%',
    justifyContent: 'space-between'
  },
  copy: {
    extend: theme.textStyles.bodySmall,
    '& a': {
      textDecoration: 'none',
      '&:hover': {
        opacity: 0.5
      }
    },
    display: 'block',
    [theme.breakpoints.up('xs')]: {
      display: 'none'
    }
  },
  footer: {
    display: 'grid',
    width: '100%',
    marginBottom: 32,
    opacity: 0,
    gridTemplateColumns: '1fr 1fr',
    columnGap: theme.grid.gutter,
    [theme.breakpoints.up('xs')]: {
      marginBottom: 0
    }
  },
  socialLinks: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    '& > *:not(:last-child)': {
      marginRight: 24
    },
    [theme.breakpoints.up('xs')]: {
      marginTop: theme.spacing(2),
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  socialShortName: {
    display: 'inline',
    [theme.breakpoints.up('xs')]: {
      display: 'none'
    }
  },
  socialFullName: {
    display: 'none',
    [theme.breakpoints.up('xs')]: {
      display: 'inline'
    }
  },
  footerLink: {
    extend: theme.textStyles.bodySmall,
    textDecoration: 'none',
    color: 'currentColor',
    [theme.breakpoints.up('xs')]: {
      fontSize: 14,
      marginRight: 0
    },
    '&:hover': {
      opacity: 0.5
    },
    [theme.breakpoints.up('xs')]: {
      '&:not(:last-child)': {
        marginBottom: theme.spacing(0.5)
      }
    },
    ...getFontScaling(14)
  },
  otherMenu: {
    width: '100%',
    margin: [theme.spacing(5), 0, theme.spacing(2)],
    opacity: 0,
    flexGrow: 1,
    [theme.breakpoints.up('xs')]: {
      display: 'none'
    }
  },
  otherMenuList: {
    display: 'grid',
    margin: 0,
    padding: 0,
    listStyle: 'none',
    gridTemplateColumns: '1fr 1fr',
    columnGap: theme.grid.gutter
  },
  otherMenuItem: {
    textDecoration: 'none',
    extend: theme.textStyles.body,
    '&.open': {
      color: theme.colors.black,
      marginLeft: theme.spacing(1)
    }
  },
  ticker: {
    display: 'none',
    width: 230,
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      display: 'block'
    }
  }
}, { name: 'MobileMenu' })

export default MobileMenu
