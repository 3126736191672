import { createAction } from '@reduxjs/toolkit'
import makeFetchSlice from '../makeFetchSlice'

export const postsContentActionCreator = createAction('posts/content', (payload) => ({
  payload,
  meta: { scope: 'posts' }
}))

export const postsChangeFilterActionCreator = createAction('posts/change_filter', (payload) => ({
  payload,
  meta: { scope: 'posts' }
}))

export const serverErrorActionCreator = createAction('posts/serverError')

const postsSlice = makeFetchSlice('posts', postsContentActionCreator, serverErrorActionCreator)

export default postsSlice.reducer
