import React from 'react'
import { createUseStyles } from 'react-jss'
import ResponsiveImage from '../ResponsiveImage'
import cn from 'clsx'
import Section from '../Section'
import theme from '../../style/theme'

const FullWidthImage = ({ className, slice }) => {
  const { image, hasPadding, adjacentImage } = slice
  const classes = useStyles()
  return (
    <Section className={cn(classes.content, className, { adjacentImage })} fullWidth={!hasPadding} noBottomMargin={adjacentImage}>
      <div className={classes.imageWrapper}>
        <ResponsiveImage {...image} />
      </div>
    </Section>
  )
}

const useStyles = createUseStyles({
  content: {
    '&.adjacentImage': {
      marginBottom: theme.spacing(1)
    }
  }
}, { name: 'FullWidthImage' })

export default FullWidthImage
