import React, { useRef } from 'react'
import { createUseStyles } from 'react-jss'
import BlockImage from './BlockImage'
import theme from '../../../style/theme'

const BlockImages = (props) => {
  const { images, isFullWidth } = props
  const classes = useStyles({ isFullWidth })
  const containerRef = useRef()

  return (
    <div className={classes.images} ref={containerRef}>
      {images && images.map((image, i) => {
        return (
          <BlockImage
            key={image.id}
            parentRef={containerRef}
            isFullWidth={isFullWidth}
            {...image}
          />
        )
      })}
    </div>
  )
}

const useStyles = createUseStyles({
  images: {
    width: '100%',
    position: 'relative',
    paddingBottom: ({ isFullWidth }) => isFullWidth ? '85vh' : '40vh',
    [theme.breakpoints.up('xs')]: {
      paddingBottom: ({ isFullWidth }) => isFullWidth ? '60%' : '60%'
    }
  }
}, { name: 'BlockImages' })

export default BlockImages
