import React, { useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'clsx'
import theme from '../style/theme'
import gsap from 'gsap'
import lerp from 'lerp'
import { useInView } from 'react-intersection-observer'
import composeRefs from '../helpers/composeRefs'

export const useProgressLineClickAnimation = (enabled = true) => {
  const lineRef = useRef()
  const clickRef = useRef()
  useEffect(() => {
    if (enabled && clickRef.current && lineRef.current) {
      const onClick = () => {
        lineRef.current.animateClick()
      }
      clickRef.current.addEventListener('click', onClick)
      return () => {
        if (clickRef.current) {
          clickRef.current.removeEventListener('click', onClick)
        }
      }
    }
  }, [enabled])
  return { lineRef, clickRef }
}

const useAnimationIn = (delay, animateIn = true) => {
  const backgroundRef = useRef()
  const [inViewRef, inView] = useInView({ threshold: 1, triggerOnce: true })
  useEffect(() => {
    if (inView && animateIn) {
      gsap.to(backgroundRef.current, { x: 0, duration: 2.4, ease: 'power4.out', delay })
    }
  }, [inView])
  return { inViewRef, backgroundRef }
}

const ProgressLine = forwardRef(({ className, animateIn = true, backgroundColor = theme.colors.borderDark, color = 'currentColor', loading = false, hovering, height = 1, duration = 0.25, delay = 0 }, ref) => {
  const { inViewRef, backgroundRef } = useAnimationIn(delay, animateIn)
  const localsRef = useRef({
    timeline: null,
    loading: false,
    percent: -100
  })
  const classes = useStyles({ color, backgroundColor, height })
  const progressLineRef = useRef()

  useImperativeHandle(ref, () => ({
    animateClick: () => {
      if (!localsRef.current.animatingClick) {
        localsRef.current.animatingClick = true
        var timeline = gsap.timeline()
        timeline.fromTo(progressLineRef.current, { x: '0%' }, { x: '101%', duration, ease: 'sine.inOut' })
        timeline.fromTo(progressLineRef.current, { x: '-101%' }, { x: '0%', duration, ease: 'sine.inOut', onComplete: () => { localsRef.current.animatingClick = false } })
      }
    }
  }), [])

  useEffect(() => {
    const animate = () => {
      if (progressLineRef.current) {
        localsRef.current.percent = lerp(localsRef.current.percent, 0, 0.005)
        gsap.set(progressLineRef.current, { x: `${localsRef.current.percent}%` })
      }
    }

    if (!localsRef.current.animatingClick) {
      if (loading) {
        localsRef.current.loading = true
        gsap.set(progressLineRef.current, { x: '-100%', opacity: 1 })
        gsap.ticker.add(animate)
        return () => {
          gsap.ticker.remove(animate)
        }
      } else {
        if (localsRef.current.loading) {
          const tl = gsap.timeline()
          localsRef.current.timeline = tl

          localsRef.current.loading = false
          tl.to(progressLineRef.current, { x: '0%', duration: 0.25, ease: 'expo.out' })
          tl.to(progressLineRef.current, { opacity: 0, duration: 1, ease: 'none' })
          tl.set(progressLineRef.current, { x: '-100%', opacity: 1 })
          tl.set(localsRef.current, { percent: -100 })

          return () => {
            if (localsRef.current.timeline) {
              localsRef.current.timeline.kill()
            }
          }
        }
      }
    }
  }, [loading])

  useEffect(() => {
    if (!localsRef.animatingClick) {
      if (hovering) {
        gsap.fromTo(progressLineRef.current, { x: '-101%' }, { x: '0%', duration, ease: 'sine.inOut' })
      } else {
        gsap.to(progressLineRef.current, { x: '101%', duration, ease: 'sine.inOut' })
      }
    }
  }, [hovering])

  return (
    <div className={cn(className, classes.container)} ref={composeRefs(inViewRef, ref)}>
      <span className={classes.background} ref={backgroundRef} />
      <span className={classes.progressLine} ref={progressLineRef} />
    </div>
  )
})

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    height: ({ height = 1 }) => height,
    overflow: 'hidden'
  },
  background: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    transform: 'translateX(-100%)'
  },
  progressLine: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    backgroundColor: ({ color }) => color,
    transform: 'translateX(-101%)'
  }
})

export default ProgressLine
