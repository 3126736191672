import React, { useCallback, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import Link from '../../Link'
import theme, { span } from '../../../style/theme'
import { resolveLink } from '../../../helpers/resolveLink'
import cn from 'clsx'
import detectIt from 'detect-it'
import { useCursorHover } from '../../Cursor'

const Award = ({ award, onMouseEnter, onMouseLeave }) => {
  const { category, award: awardTitle, project, link } = award
  const classes = useStyles()
  const ref = useRef()
  const linkRef = useRef()

  const handleMouseEnter = useCallback((e) => {
    if (detectIt.primaryInput !== 'touch') {
      if (onMouseEnter) onMouseEnter(ref.current, award)
    }
  }, [award])

  const hasLink = link && link.length
  useCursorHover(linkRef, award.tooltip || 'View Case Study', false, null, null, hasLink)

  return (
    <div
      ref={ref}
      className={cn(classes.award, 'award')}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Link
        ref={linkRef}
        className={classes.awardContainer}
        link={hasLink ? resolveLink(link[0]) : null}
        nonLinkTag='div'
      >
        <div className={classes.awardDetails}>
          <span className={classes.awardOrigin}>{awardTitle}</span>
          <span className={classes.awardCategory}>{category}</span>
          <span className={classes.awardProject}>{project}</span>
        </div>
      </Link>
    </div>
  )
}

const useStyles = createUseStyles({
  award: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(2)
    }
  },
  awardContainer: {
    display: 'inline-flex',
    textDecoration: 'none',
    position: 'relative',
    zIndex: 1,
    whiteSpace: 'normal',
    transition: 'opacity 0.25s ease-in-out',
    '&.fade': {
      opacity: 0.5
    }
  },
  awardDetails: {
    display: 'inline-flex',
    flexDirection: 'column',
    '& span': {
      display: 'block',
      extend: theme.textStyles.body
    }
  },
  awardOrigin: {
    paddingLeft: 24
  },
  revealImage: {
    position: 'absolute',
    visibility: 'hidden',
    zIndex: -1
  }
}, { name: 'Award' })

export default Award
