import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'clsx'
import SplitWordsAnimation from '../../SplitWordsAnimation'
import Block from './Block'
import theme from '../../../style/theme'
import { span } from '../../../style/span'
import BlockRichText from './BlockRichText'
import Section from '../../Section'

const components = {
  chapter: Block,
  rich_text: BlockRichText
}

const StudioPageSlice = forwardRef(({ className, slice }, ref) => {
  const {
    title,
    chapters
  } = slice
  const classes = useStyles()
  const titleWords = title.split(/\r\n|\n|\r/)

  return (
    <section className={cn(classes.section, className)}>
      <Section tag='div' className={classes.content}>
        <div className={classes.header}>
          <SplitWordsAnimation>
            <h1 className={classes.title}>
              {titleWords.map((word, i) => <span key={i}>{word}</span>)}
            </h1>
          </SplitWordsAnimation>
        </div>
        <div className={classes.chapters}>
          {chapters.map((item, i) => {
            const Component = components[item.type]
            return <Component key={item.id} {...item} />
          })}
        </div>
      </Section>
    </section>
  )
})

const useStyles = createUseStyles({
  section: {
    overflow: 'hidden'
  },
  content: {
    marginTop: 100,
    marginBottom: 64,
    [theme.breakpoints.up('md')]: {
      marginTop: 134,
      marginBottom: 120
    },
    [theme.breakpoints.up('xxxl')]: {
      marginTop: 192
    }
  },
  header: {},
  title: {
    extend: theme.textStyles.h1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 0
  },
  chapters: {
    '& > *': {
      '&:first-child': {
        paddingTop: 0
      }
    }
  }
}, { name: 'StudioPageSlice' })

export default StudioPageSlice
