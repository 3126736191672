import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
  forwardRef
} from 'react'
import { useSelector } from 'react-redux'
import { useInView } from 'react-intersection-observer'
import { createUseStyles } from 'react-jss'
import cn from 'clsx'
import get from 'lodash/get'
import gsap from 'gsap'

import { isCurrentBreakpointMobile } from '../../redux/slices/layout'
import useHlsPlayer from '../../hooks/useHlsPlayer'
import composeRefs from '../../helpers/composeRefs'
import useWindowResize from '../../hooks/useWindowResize'
import useDebouncedCallback from '../../hooks/useDebouncedCallback'

const getVideoAspect = (aspect = '16:9') => {
  const data = aspect.split(':')
  return data[0] / data[1]
}

export default forwardRef(({ className, desktopVideo, mobileVideo, cover = false }, ref) => {
  const [canPlay, setCanPlay] = useState()
  const playerRef = useRef()
  const containerRef = useRef()
  const isMobile = useSelector(isCurrentBreakpointMobile)
  const url = isMobile
    ? get(mobileVideo, ['url'], desktopVideo.url)
    : desktopVideo.url
  const aspect = getVideoAspect(
    (isMobile && get(mobileVideo, ['data', 'aspect'])) ||
      get(desktopVideo, ['data', 'aspect'])
  )
  const classes = useStyles({ aspect })
  const playPromise = useRef()

  const togglePlay = useCallback(play => {
    if (!playerRef.current) return

    if (play) {
      playPromise.current = playerRef.current.play()
      return
    }

    if (playPromise.current) {
      playPromise.current = playPromise.current.then(() => {
        playerRef.current.pause()
      })
    }
  }, [])

  const onCanPlay = useCallback(() => {
    gsap.to(playerRef.current, { opacity: 1, duration: 0.25, ease: 'sine.out' })
    setCanPlay(true)
  }, [])

  const [inViewRef, inView] = useInView({ threshold: 0 })

  useHlsPlayer(playerRef, url)

  useEffect(() => {
    if (canPlay) {
      togglePlay(inView)
    }
  }, [togglePlay, inView, canPlay])

  // Resize handlers
  const resize = useCallback(() => {
    if (cover) return
    const { width, height } = containerRef.current.getBoundingClientRect()
    const containerAspect = width / height

    if (containerAspect > aspect) {
      playerRef.current.style.width = 'calc(100% + 1px)' // we give it a bit extra so that lines don't appear around it
      playerRef.current.style.height = 'auto'
    } else {
      playerRef.current.style.width = 'auto'
      playerRef.current.style.height = 'calc(100% + 1px)'
    }
  }, [aspect, cover])
  useEffect(resize, [])
  useWindowResize(useDebouncedCallback(resize, 250, [resize]))

  return (
    <div className={classes.videoContainer} ref={containerRef}>
      <video
        ref={composeRefs(inViewRef, playerRef, ref)}
        src={url}
        className={cn(className, classes.video)}
        controls={false}
        autoPlay
        muted
        loop
        playsInline
        crossOrigin='anonymous'
        onCanPlay={onCanPlay}
      />
    </div>
  )
})

const useStyles = createUseStyles({
  videoContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden'
  },
  video: {
    opacity: 0,
    top: '50%',
    left: '50%',
    width: 'auto',
    height: 'auto',
    display: 'block',
    outline: 'none',
    position: 'absolute',
    minWidth: '100%',
    transform: 'translateX(-50%) translateY(-50%)',
    minHeight: '100%'
  }
})
