import React, { useMemo, useCallback } from 'react'
import FilterDropdown from './FilterDropdown'
import { useSelector, useDispatch } from 'react-redux'
import { postsChangeFilterActionCreator } from '../../../redux/slices/content/posts'
import { queryStringChangeCreator } from '../../../redux/actions/routes'
import {
  getState,
  getPostListSlice,
  getWorkListSlice,
  getActiveFilterTag
} from '../../../redux/slices/content'
import {
  closeFilterDropdown,
  openFilterDropdown,
  isFilterDropdownOpen
} from '../../../redux/slices/layout'
import DialogToggleButton from '../DialogToggleButton'

const titleize = (slug) => {
  var words = slug.split('-')
  return words.map((word) => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase()).join(' ')
}

export const POST_LIST_MODE = 'POST_LIST_MODE'
export const WORK_LIST_MODE = 'WORK_LIST_MODE'

const FilterButton = ({ className }) => {
  const dropdownOpen = useSelector(isFilterDropdownOpen)
  const filter = useSelector(getActiveFilterTag)
  const state = useSelector(getState)
  const dispatch = useDispatch()

  const workListSlice = useSelector(getWorkListSlice)
  const postListSlice = useSelector(getPostListSlice)

  const mode = useMemo(() => {
    if (postListSlice) {
      return POST_LIST_MODE
    } else if (workListSlice) {
      return WORK_LIST_MODE
    } else {
      return null
    }
  }, [postListSlice, workListSlice])

  const buttonText = useMemo(() => {
    if (filter) {
      return `Filter: ${titleize(filter)}`
    } else if (mode === WORK_LIST_MODE) {
      return 'Filter: All Industries'
    } else {
      return 'Filter: All Articles'
    }
  }, [filter, mode])

  const onClose = useCallback(() => {
    dispatch(closeFilterDropdown())
  }, [])

  const onOpen = useCallback(() => {
    dispatch(openFilterDropdown())
  }, [])

  const onOptionSelected = (e, slug) => {
    e.preventDefault()
    const newQuery = {
      ...(slug ? {
        filter: slug
      } : {})
    }
    dispatch(queryStringChangeCreator(state, newQuery))
    if (mode === POST_LIST_MODE) {
      dispatch(postsChangeFilterActionCreator())
    }
  }

  return (
    <DialogToggleButton
      className={className}
      open={dropdownOpen}
      onClose={onClose}
      onOpen={onOpen}
      ariaLabel={`${dropdownOpen ? 'Show' : 'Hide'} the filter menu`}
      text={buttonText}
      chevron
    >
      <FilterDropdown
        active={dropdownOpen}
        mode={mode}
        activeFilter={filter}
        onClose={onClose}
        onOptionSelected={onOptionSelected}
      />
    </DialogToggleButton>
  )
}

export default FilterButton
