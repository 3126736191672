import React, { forwardRef, useCallback, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import ResponsiveImage from '../ResponsiveImage'
import isEmpty from 'lodash/isEmpty'
import Section from '../Section'
import Slider, { defaultOptions } from '../Slider'
import { useSelector } from 'react-redux'
import { isCurrentBreakpointMobile } from '../../redux/slices/layout'
import map from 'lodash/map'
import cn from 'clsx'
import SliceHeader from './SliceHeader'
import useFadeInAnimation from '../../animations/useFadeInAnimation'
import { resolveLink } from '../../helpers/resolveLink'
import Link from '../Link'
import ProgressLine, { useProgressLineClickAnimation } from '../ProgressLine'
import detectIt from 'detect-it'
import { getFontScaling } from '../../style/textStyles'
import useHovering from '../../hooks/useHovering'
import composeRefs from '../../helpers/composeRefs'

const Profile = ({ name, position, image, largeBio, link }) => {
  const classes = useStyles({})
  const resolvedLink = useMemo(() => link && resolveLink(link[0]), [link])
  const { ref, hovering } = useHovering(true)
  const { lineRef, clickRef } = useProgressLineClickAnimation()
  return (
    <Link link={resolvedLink} className={classes.profile} nonLinkTag='div' ref={composeRefs(ref, clickRef)}>
      <ResponsiveImage {...image} className={classes.image} aspect={450 / 640} />
      <h3 className={classes.name}>{name}</h3>
      <p className={classes.bio}>{largeBio}</p>
      <span className={classes.position}>{position}</span>
      <ProgressLine hovering={hovering} ref={lineRef} />
    </Link>
  )
}

const ProfileGroup = ({ profiles, selected }) => {
  const isMobile = useSelector(isCurrentBreakpointMobile)
  const classes = useStyles({})

  const [inViewRef] = useFadeInAnimation(
    useCallback(() => document.querySelectorAll(`.${classes.profileContainer}`), [classes.profileContainer]),
    { stagger: 0.05 }
  )

  const options = useMemo(() => ({
    ...defaultOptions,
    draggable: isMobile
  }), [isMobile])

  if (isEmpty(profiles)) return null

  return (
    <div className={cn(classes.section, { selected })} ref={inViewRef}>
      <Slider className={classes.container} options={options}>
        {profiles.map(profile => (
          <div key={profile.id} className={classes.profileContainer}>
            <Profile className={classes.article} {...profile} />
          </div>
        ))}
      </Slider>
    </div>
  )
}

const Tab = ({ title, selected, tabIndex, setTabIndex }) => {
  const classes = useStyles({})
  const onClick = useCallback(() => {
    if (setTabIndex) setTabIndex(tabIndex)
  }, [tabIndex, setTabIndex])
  const { ref, hovering } = useHovering(true)
  return (
    <li className={cn(classes.tab, { selected })} ref={ref}>
      <button onClick={onClick} className={classes.tabButton}>{title}</button>
      <ProgressLine className={classes.line} hovering={hovering || selected} />
    </li>
  )
}

const Tabs = ({ tabs, tabIndex, setTabIndex }) => {
  const classes = useStyles({ tabs })
  return (
    <Section tag='ul' className={classes.tabs} noBottomMargin>
      {tabs.map((x, i) => (
        <Tab key={x} title={x} selected={tabIndex === i} tabIndex={i} setTabIndex={setTabIndex} />
      ))}
    </Section>
  )
}

const Profiles = forwardRef(({ className, slice }, ref) => {
  const { profileGroups } = slice
  const [tabIndex, setTabIndex] = useState(0)
  const tabs = useMemo(() => map(profileGroups, g => g.title), [profileGroups])

  return (
    <>
      <Section noBottomMargin>
        <SliceHeader slice={slice} />
      </Section>
      <Section fullWidth>
        <Tabs tabIndex={tabIndex} setTabIndex={setTabIndex} tabs={tabs} />
        {profileGroups.map(({ profiles }, i) => (
          <ProfileGroup key={i} profiles={profiles} selected={tabIndex === i} />
        ))}
      </Section>
    </>
  )
})

const useStyles = createUseStyles({
  section: {
    display: 'none',
    overflow: 'hidden',
    '&.selected': {
      display: 'block'
    }
  },
  container: {
    padding: [0, theme.grid.margin, theme.grid.margin],
    margin: [theme.spacing(1), -theme.grid.gutter / 2, 0]
  },
  profileContainer: {
    minHeight: '100%',
    display: 'flex',
    width: '90%',
    opacity: 0,
    [theme.breakpoints.up('xs')]: {
      width: '40%'
    },
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% / 3)'
    }
  },
  profile: {
    width: '100%',
    padding: [0, theme.grid.gutter / 2],
    whiteSpace: 'normal',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    textDecoration: 'none',
    '&:hover $bio': {
      opacity: 1
    }
  },
  image: {
    marginBottom: theme.spacing(1)
  },
  name: {
    ...theme.textStyles.bodyPlus,
    marginBottom: theme.spacing(0.5),
    opacity: 1
  },
  bio: {
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.up('xs')]: {
      width: '75%'
    },
    flexGrow: 1,
    marginBottom: theme.spacing(4),
    opacity: 0.5,
    transition: 'opacity 0.25s ease-in-out'
  },
  position: {
    ...theme.textStyles.caption,
    marginBottom: theme.spacing(0.5)
  },
  tabs: {
    marginTop: 0,
    marginBottom: 0,
    listStyle: 'none',
    padding: 0,
    display: 'grid',
    gridTemplateColumns: ({ tabs = [] }) => `repeat(${tabs.length}, 1fr)`,
    gridGap: `${theme.spacing(1)}px`
  },
  tab: {
    ...(detectIt.primaryInput === 'touch' ? {} : {
      '&:hover $tabButton': {
        opacity: 1
      }
    }),
    '&.selected $tabButton': {
      opacity: 1
    }
  },
  line: {},
  tabButton: {
    transition: 'opacity 0.25s ease-in-out',
    padding: [theme.spacing(0.5), 0],
    fontFamily: theme.fonts.mono,
    fontSize: 12,
    color: theme.colors.black,
    textTransform: 'uppercase',
    width: '100%',
    opacity: 0.3,
    textAlign: 'left',
    ...getFontScaling(12)
  }
}, { name: 'Profiles' })

export default Profiles
