/* global window:false */
import inDOM from 'dom-helpers/canUseDOM'
import { current } from '../../style/breakpoints'
import { rehydrated } from '../actions'
import { breakpointChanged, getCurrentBreakpoint } from '../slices/layout'
// import FontFaceObserver from 'fontfaceobserver'
// import { fontPreloaded, getFontsLoading } from '../slices/preload'
// import forEach from 'lodash/forEach'

export default (store) => {
  // const preloadFonts = (store) => {
  //   const fonts = getFontsLoading(store.getState())
  //   forEach(fonts, ({ fontFamily, fontWeight }) => {
  //     const loader = new FontFaceObserver(fontFamily, { fontWeight })
  //     const onLoaded = () => {
  //       store.dispatch(fontPreloaded({ fontFamily, fontWeight }))
  //     }
  //     loader.load().then(onLoaded).catch(onLoaded)
  //   })
  // }

  const check = () => {
    const bp = current()
    const state = store.getState()
    const prev = getCurrentBreakpoint(state)
    if (bp !== prev) {
      store.dispatch(breakpointChanged(bp))
    }
  }
  if (inDOM) {
    window.addEventListener('resize', check)
  }

  return next => (action) => {
    const ret = next(action)
    if (action.type === rehydrated.toString()) {
      // preloadFonts(store)
      check()
    }
    return ret
  }
}
