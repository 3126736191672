import React, { useCallback, useRef, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'clsx'
import gsap from 'gsap'
import range from 'lodash/range'
import useResizeObserver from '../../hooks/useResizeObserver'

const TextTicker = ({ className, text, fill }) => {
  const classes = useStyles()
  const containerRef = useRef()
  const [elementCount, setElementCount] = useState(1)
  const [containerWidth, setContainerWidth] = useState()
  const timeline = useRef()

  const resize = useCallback(() => {
    if (containerRef.current) {
      const { width } = containerRef.current.getBoundingClientRect()
      const textElement = containerRef.current.children[0]
      const textWidth = textElement.getBoundingClientRect().width
      setContainerWidth({ width, textWidth })
      if (fill || textWidth > width) {
        setElementCount(Math.ceil(width / textWidth) + 1)
      } else {
        setElementCount(1)
      }
    }
  }, [text, fill])

  useResizeObserver(containerRef, resize)

  useEffect(() => { resize() }, [resize])

  useEffect(() => {
    if (containerRef.current && elementCount > 1) {
      const { textWidth } = containerWidth
      timeline.current = gsap.timeline({ repeat: -1 })
      timeline.current.fromTo(containerRef.current.children, { x: 0 }, { x: -textWidth, duration: textWidth * 0.05, ease: 'none', repeat: -1 })
      return () => {
        timeline.current.kill()
      }
    }
  }, [elementCount, containerWidth])

  return (
    <span className={cn(className, classes.text)} ref={containerRef}>
      {range(elementCount).map((_, i) => <span key={i}>{text}</span>)}
    </span>
  )
}

const useStyles = createUseStyles({
  text: {
    overflow: 'hidden',
    flexGrow: 1,
    '& > span': {
      display: 'inline-block',
      paddingRight: 32
    },
    '& > span:not(:first-child)': {
      position: 'relative',
      '&:before': {
        content: '""',
        position: 'absolute',
        left: -19,
        backgroundColor: 'currentColor',
        top: '50%',
        height: 6,
        width: 6,
        borderRadius: '50%',
        transform: 'translate(0, -50%)'
      }
    }
  }
}, { name: 'TextTicker' })

export default TextTicker
