import React, { useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import Flickity from 'react-flickity-component'
import ResponsiveImage from '../ResponsiveImage'
import SliceHeader from './SliceHeader'
import gsap from 'gsap'
import Link from '../Link'
import get from 'lodash/get'
import { resolveLink } from '../../helpers/resolveLink'
import TextTicker from './TextTicker'
import { getFontScaling } from '../../style/textStyles'

const preventVerticalScrolling = (flickity) => {
  // This will prevent the vertical scrolling when scrolling in flickity
  flickity.on('dragStart', () => { document.ontouchmove = e => e.preventDefault() })
  flickity.on('dragEnd', () => { document.ontouchmove = () => true })
}

const flickityOptions = {
  cellAlign: 'left',
  pageDots: false,
  contain: false,
  freeScroll: false
}

const Tile = ({ link, title, tagRef, image }) => {
  const classes = useTileStyles({ aspect: image.aspect })
  return (
    <Link className={classes.tile} link={resolveLink(get(link, [0]))} nonLinkTag='div'>
      <ResponsiveImage {...image} className={classes.image} />
      <div className={classes.content}>
        {tagRef && <span className={classes.tag}>{tagRef.title}</span>}
        <TextTicker className={classes.title} text={title} />
      </div>
    </Link>
  )
}

const ArticleSlider = ({ className, slice }) => {
  const {
    articles
  } = slice
  const classes = useStyles()

  const setFlickityRef = useCallback((flickity) => {
    if (flickity) {
      gsap.set(flickity.element, { display: 'block', overflow: 'visible' })
      flickity.on('dragMove', (event, pointer, moveVector) => {
        const dragMoveEvent = new window.CustomEvent('flickity-dragMove', { detail: event })
        window.dispatchEvent(dragMoveEvent)
      })
      preventVerticalScrolling(flickity)
    }
  }, [])

  return (
    <section className={classes.section}>
      <div className={classes.wrapper}>
        <SliceHeader slice={slice} />
        <Flickity
          options={flickityOptions}
          className={classes.flickity}
          flickityRef={setFlickityRef}
        >
          {articles.map((article, i) => <Tile key={i} {...article} />)}
        </Flickity>
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  flickity: {
    outline: 'none',
    whiteSpace: 'nowrap',
    display: 'flex',
    '& > *': {
      flexShrink: 0
    }
  },
  section: {
    extend: [theme.section],
    overflow: 'hidden'
  },
  wrapper: {
    ...theme.gutter('margin', x => [0, x])
  }
}, { name: 'ArticleSlider' })

const useTileStyles = createUseStyles({
  tileGutter: {
    ...theme.gutter('margin', x => [0, x / 2])
  },
  tile: {
    composes: ['$tileGutter'],
    width: ({ aspect }) => `${aspect * 88}vw`,
    textDecoration: 'none',
    [theme.breakpoints.up('md')]: {
      width: ({ aspect }) => `${aspect * 36}vw`
    }
  },
  image: {
    width: '100%'
  },
  content: {
    position: 'relative',
    display: 'flex',
    marginTop: 8
  },
  text: {
    display: 'block',
    textTransform: 'uppercase',
    fontSize: 12,
    fontFamily: theme.fonts.body,
    whiteSpace: 'nowrap',
    ...getFontScaling(15)
  },
  tag: {
    composes: ['$text'],
    marginRight: 64
  },
  title: {
    composes: ['$text']
  }
}, { name: 'ArticleSliderTile' })

export default ArticleSlider
